import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage as IntlFormattedMsg } from "react-intl";

const FormattedMessage = ({ values = {}, ...props }) => (
  <IntlFormattedMsg
    {...props}
    values={{
      ...values,
      customer     : window.orgConfig.name,
      token        : window.orgConfig.tokenSymbolDisplay,
      contractName : window.orgConfig.contracts.name
    }}
  />
);

FormattedMessage.propTypes = {
  id             : PropTypes.string,
  description    : PropTypes.string,
  defaultMessage : PropTypes.string,
  values         : PropTypes.object
};

export default FormattedMessage;
