import { createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  searchInvestments       : ["payload"],
  searchWallets           : ["payload"],
  searchCompensations     : ["payload"],
  getEvents               : ["payload"],
  getInvestmentIntentions : []
}, { prefix: "admin_dashboard_" });

export { Types, Creators };
