/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Menu } from "semantic-ui-react";
import { NavLink, useLocation } from "react-router-dom";

import { GiftIcon, UpArrowIcon } from "src/assets/icons";
import { ROUTE_PATH, USER_PERMISSION } from "src/constants";

import styles from "./style.module.less";

const EquityGrantMenu = ({ orgConfig, permissions }) => {
  const location = useLocation();

  const menuItems = [

    {
      title    : "Equity Grants",
      to       : ROUTE_PATH.ADMIN_EQUITY_GRANT_COMPENSATION,
      disabled : !(orgConfig.chargebee?.compensation) || !permissions?.includes(USER_PERMISSION.STAKEHOLDERS_COMPENSATION)
    },
    {
      title    : "Pools",
      to       : ROUTE_PATH.ADMIN_EQUITY_GRANT_POOLS,
      disabled : !(orgConfig.chargebee?.compensation) || !permissions?.includes(USER_PERMISSION.STAKEHOLDERS_COMPENSATION),
    },
    {
      title    : "Legal",
      to       : ROUTE_PATH.ADMIN_EQUITY_GRANT_LEGAL,
      disabled : !(orgConfig.chargebee?.compensation) || !permissions?.includes(USER_PERMISSION.STAKEHOLDERS_COMPENSATION),
    },
  ];

  const open = menuItems.map(item => item.to).includes(location.pathname);
  const [expand, setExpand] = useState(open);

  useEffect(() => {
    if (!expand) {
      setExpand(open);
    }
  }, [open]);

  return (
    <div className={styles.equityGrantMenu}>
      <Menu.Item className={styles.menuHeader} onClick={() => setExpand(!expand)}>
        <div className={styles.flexWrapper}>
          <GiftIcon />
          Grants
        </div>
        <div style={{ transform: !expand && "rotateX(180deg)" }}>
          <UpArrowIcon className={styles.dropdownIcon} />
        </div>
      </Menu.Item>
      {expand && menuItems.map(menu => (
        <Menu.Item key={menu.to} as={NavLink} className={styles.menuItem} to={menu.to} disabled={menu.disabled}>
          {menu.title}
        </Menu.Item>

      ))}
    </div>
  );
};

EquityGrantMenu.propTypes = {
  orgConfig   : PropTypes.object.isRequired,
  permissions : PropTypes.array,
};

export default EquityGrantMenu;
