import { callLambda, SERVICE_ADMIN, uploadFileToPrivateS3 } from "src/utils/lambda";
import { handleDownload, downloadFromNewTab } from "src/utility";
import { Types } from "../actions/admin-stakeholder";

const apis = {};

apis[Types.GET_ACCREDITATION_LOGS] = action => callLambda({
  method  : "post",
  service : SERVICE_ADMIN,
  url     : `getAccreditationLogs`,
  body    : { email: action.email }
});

apis[Types.GET_EVENTS] = action => callLambda({
  method  : "post",
  service : SERVICE_ADMIN,
  url     : `getAdminEvents`,
  body    : action.payload
});

apis[Types.GET_KYC_DATA] = action => callLambda({
  method  : "post",
  service : SERVICE_ADMIN,
  url     : `getKYCData`,
  body    : { email: action.email }
});

apis[Types.GET_USER_INVESTMENT_DOC_URL] = async ({ investItemSK, fileName }) => {
  const { documentUrl, investItem } = await callLambda({
    method  : "post",
    service : SERVICE_ADMIN,
    url     : "getUserInvestmentDocUrl",
    body    : { investItemSK }
  });
  handleDownload(documentUrl, fileName);
  return { investItem };
};

apis[Types.GET_USER_COMP_DOC_URL] = async ({ item, fileName }) => {
  const pdfURL = await callLambda({
    method  : "post",
    service : SERVICE_ADMIN,
    url     : "getUserCompDocUrl",
    body    : { id: item.id, type: item.itemType }
  });

  downloadFromNewTab(pdfURL, fileName);
  return item;
};

apis[Types.GET_TAX_DOC_URL] = async ({ email }) => {
  const pdfURL = await callLambda({
    method  : "post",
    service : SERVICE_ADMIN,
    url     : "getTaxDocUrl",
    body    : { email }
  });
  downloadFromNewTab(pdfURL);
  return email;
};

apis[Types.SEND_INVESTMENT_PDF] = ({ investItemSK }) => callLambda({
  method  : "post",
  service : SERVICE_ADMIN,
  url     : "sendInvestmentPDF",
  body    : { investItemSK }
});

apis[Types.SEARCH_WALLETS] = search => callLambda({
  method  : "post",
  service : SERVICE_ADMIN,
  url     : "searchWallets",
  body    : search
});

apis[Types.UPDATE_WALLET] = action => callLambda({
  method  : "post",
  service : SERVICE_ADMIN,
  url     : `updateWallet`,
  body    : { email: action.email, ...action.payload }
});

apis[Types.UPDATE_WALLET_FUNDING] = async (action) => {
  if (action.payload.userInfo?.accreditationStatus?.certificateDoc instanceof Blob) {
    const { certificateDoc } = action.payload.userInfo.accreditationStatus;
    action.payload.userInfo.accreditationStatus.certificateDoc = await uploadFileToPrivateS3(
      certificateDoc,
      certificateDoc.name,
      certificateDoc.type
    );
  }

  return callLambda({
    method  : "post",
    service : SERVICE_ADMIN,
    url     : `updateWalletFunding`,
    body    : { email: action.email, ...action.payload }
  });
};

apis[Types.GET_PROCESSED_INVESTMENTS] = action => callLambda({
  method  : "post",
  service : SERVICE_ADMIN,
  url     : "searchInvestments",
  body    : {
    pageLimit : 1000,
    type      : "all",
    status    : "all",
    email     : action.email
  }
});

apis[Types.GET_USER_DOC_SIGNED_URL] = ({ payload }) => callLambda({
  method  : "post",
  service : SERVICE_ADMIN,
  url     : "getUserDocSignedUrl",
  body    : {
    email   : payload.email,
    itemKey : payload.itemKey
  }
});

apis[Types.INVITE_STAKEHOLDERS] = ({ payload }) => callLambda({
  method  : "post",
  service : SERVICE_ADMIN,
  url     : "inviteStakeholders",
  body    : { invitations: payload }
});

apis[Types.RESEND_INVITE] = ({ payload }) => callLambda({
  method  : "post",
  service : SERVICE_ADMIN,
  url     : "resendInvite",
  body    : payload
});

apis[Types.GET_USER_LEGAL_ITEMS] = ({ email }) => callLambda({
  method  : "post",
  service : SERVICE_ADMIN,
  url     : "getUserLegalItems",
  body    : { email }
});

export default apis;
