import React, { useMemo, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { Box, Typography, LinearProgress } from "@mui/material";

import { Button } from "src/mui-components";
import { isPendingSetup } from "src/utility";
import { onboardingCompletionPercent } from "src/utils/onboarding";
import Messenger from "src/utils/messenger";
import GoLiveConfirmModal from "src/pages/Admin/GoLive/GoLiveConfirmModal";
import { ONBOARDING_STEPS, SECTION_ID, STEP_ID } from "src/pages/Admin/Home/data";

const ProgressBar = ({ orgConfig, adminContent, goPortalLive }) => {
  const percent = useMemo(() => onboardingCompletionPercent(adminContent.onboarding || {}), [adminContent.onboarding]);

  const checkCompleted = useCallback((skipSectionIds, skipIds = []) => {
    const mandatorySteps = ONBOARDING_STEPS
      .filter(s => !skipSectionIds.includes(s.sectionId) && !skipIds.includes(s.id) && !s.optional)
      .map(ms => ms.id);
    return mandatorySteps.every((ms) => {
      if (!adminContent.onboarding?.[ms]) {
        return false;
      }
      return true;
    });
  }, [adminContent.onboarding]);

  const text = useMemo(() => {
    if (!percent) {
      return "Let's get started";
    }
    if (percent > 0 && percent <= 30) {
      return "You are doing great";
    }
    if (percent > 30) {
      return "You are almost there";
    }
  }, [percent]);

  const [open, setOpen] = useState(false);

  const handleGoLive = useCallback((invitationOnlyMode) => {
    goPortalLive({ disableInvitationOnlyMode: !invitationOnlyMode });
  }, [goPortalLive]);

  const renderNotComplete = () => (
    <>
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", mb: "8px" }}>
        <Typography
          variant="h5"
          sx={{ fontSize: 14, fontWeight: 600 }}
          color="#202020"
        >
          {text}
        </Typography>
        <Typography
          variant="h5"
          sx={{ fontSize: 14, fontWeight: 600 }}
          color="#6739FD"
        >
          {percent}%
        </Typography>
      </Box>
      <LinearProgress
        sx={{
          "&.MuiLinearProgress-root": {
            height          : "12px",
            borderRadius    : "24px",
            backgroundColor : "#eee"
          },
          ".MuiLinearProgress-bar": {
            borderRadius    : "24px",
            backgroundColor : "#825BFF",
          }
        }}
        variant="determinate"
        value={percent}
      />
      <Typography
        variant="h5"
        component="div"
        sx={{ fontSize: 14, fontWeight: 400, margin: "12px auto 20px", textAlign: "center" }}
        color="#000"
      >
        Go live with Fairmint 🚀
      </Typography>
    </>
  );

  const renderComplete = () => (
    <>
      <Typography
        variant="h5"
        sx={{ fontSize: 14, fontWeight: 600, mb: "8px" }}
        color="#202020"
      >
        You are ready! 🚀
      </Typography>
      <Button
        sx={{
          "&.MuiButton-containedPrimary": {
            backgroundColor: "#6739FD"
          },
          mb: "20px"
        }}
        fullWidth
        onClick={() => setOpen(true)}
      >
        Go Live Now
      </Button>
      {handleGoLive && open && (
        <GoLiveConfirmModal
          open={open}
          orgConfig={orgConfig}
          adminContent={adminContent}
          onGoLive={handleGoLive}
          onClose={() => setOpen(false)}
        />
      )}
    </>
  );

  const renderInReview = () => (
    <>
      <Typography
        variant="h5"
        sx={{ fontSize: 14, fontWeight: 600, mb: "8px" }}
        color="#2020220"
      >
        Your portal will be live soon.
      </Typography>
      <Button
        variant="text"
        sx={{
          "&.MuiButton-textPrimary": {
            color      : "#6739FD",
            fontSize   : "14px",
            fontWeight : 600
          },
          mb: "20px"
        }}
        fullWidth
        onClick={() => Messenger.showMessenger()}
      >
        Contact us
      </Button>
    </>
  );

  const render = () => {
    if (isPendingSetup(orgConfig.status)) {
      const fundraising = orgConfig.chargebee?.fundraising;
      const compensation = orgConfig.chargebee?.compensation;
      const completed = (fundraising && checkCompleted(
        [SECTION_ID.DESIGN_EQUITY_GRANT],
        orgConfig.enableDigitalFunding ? [] : [STEP_ID.FUNDRAISING_WALLET]
      ))
        || (compensation && checkCompleted([SECTION_ID.PREPARE_FUNDRAISING]));
      const digitalFunding = !orgConfig.enableDigitalFunding || adminContent.initialization?.recipient;
      return completed && digitalFunding ? renderComplete() : renderNotComplete();
    }

    return renderInReview();
  };

  return (
    <Box sx={{ pt: "16px", borderTop: "1px solid #d7d7d7;" }}>
      {render()}
    </Box>
  );
};

ProgressBar.propTypes = {
  orgConfig    : PropTypes.object.isRequired,
  adminContent : PropTypes.object,
  goPortalLive : PropTypes.func.isRequired,
};

export default ProgressBar;
