import { createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  checkToken          : ["code"],
  checkEmail          : ["email"],
  signIn              : ["email", "code", "signUp"],
  signOut             : [],
  check2FAToken       : ["walletAddress"],
  check2FACode        : ["data"],
  checkInvitationCode : ["code"],
}, { prefix: "auth_" });

export { Types, Creators };
