import { createReducer } from "reduxsauce";
import { Types } from "../actions/offering-summary";
import { success } from "../utils/action";

// Initial State
const initialState = {
  tokenPriceHistory : null,
  riskFactors       : null,
};

const getTokenPriceHistory = (state, action) => ({
  ...state,
  tokenPriceHistory: action.payload.tokenPriceHistory,
});

const getRiskFactors = (state, action) => ({
  ...state,
  riskFactors: action.payload
});

// map action types to reducer functions
export const handlers = {
  [success(Types.GET_TOKEN_PRICE_HISTORY)] : getTokenPriceHistory,
  [success(Types.GET_RISK_FACTORS)]        : getRiskFactors,
};

// Export Reducer
export default createReducer(initialState, handlers);
