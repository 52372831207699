export default {
  // global styles for the app
  root: () => ({
    position    : `absolute !important`,
    left        : `50%`,
    top         : `50%`,
    transform   : `translate(-50%, -50%)`,
    padding     : `36px !important`,
    width       : "528px !important",
    "& .header" : {
      textAlign: "center"
    },
    "& .content .description": {
      fontSize      : "19px",
      lineHeight    : "28px",
      textAlign     : "center",
      letterSpacing : "0.5px",
      fontWeight    : "600",
    },
    "& .content .description .ui.image": {
      backgroundSize    : "contain",
      display           : "block",
      "-moz-box-sizing" : "border-box",
      boxSizing         : "border-box",
      width             : "126px",
      height            : "126px",
      margin            : "0 auto",
      marginBottom      : "60px",
    },
    "& .content .description .ui.button": {
      height    : "52px",
      marginTop : "24px"
    }
  }),
  fairmint: () => ({
  }),
  erxes: () => ({
  }),
  beem: () => ({
  }),
  endowl: () => ({
  }),
  flux: () => ({
  }),
  gilded: () => ({
  }),
  greenruhm: () => ({
  }),
  hickies: () => ({
  }),
  opengrants: () => ({
  }),
};
