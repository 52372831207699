export const ACTION_STATUS = {
  REQUEST : "request",
  SUCCESS : "success",
  FAILURE : "failure"
};

export const INCOMING = txHash => `${txHash}-incoming`;

export const OUTGOING = txHash => `${txHash}-outgoing`;

export const USER_PERMISSION = {
  ADMIN                     : "admin",
  READ_WALLET               : "read_wallet",
  UPDATE_WALLET             : "update_wallet",
  UPDATE_PERMISSION         : "update_permission",
  READ_KYC                  : "read_kyc",
  UPDATE_KYC                : "update_kyc",
  BENEFICIARY               : "beneficiary",
  TRANSACTION               : "transaction",
  CONTENT                   : "content",
  INVESTMENTS               : "investments",
  STAKEHOLDERS_COMPENSATION : "stakeholders_compensation",
  OFFERING                  : "offering",
  EXPORT_DATA               : "export_data",
};

export const TX_STATUS = {
  QUEUED             : "queued",
  PENDING            : "pending",
  PENDING_SIMULATION : "pending-simulation",
  STUCK              : "stuck",
  SPEED_UP           : "speedup",
  CANCEL             : "cancel",
  CONFIRMED          : "confirmed",
  FAILED             : "failed",
  DROPPED            : "dropped",
};

export const TX_TYPE = {
  APPROVE_DAT             : "approve_dat",
  APPROVE_OPERATOR        : "approve_operator",
  BUY                     : "buy",
  SELL                    : "sell",
  WITHDRAW                : "withdraw",
  WITHDRAW_FEE            : "withdraw_fee",
  FUND                    : "fund",
  MIGRATION_FUND          : "migration_fund",
  MIGRATION_FAIR          : "migration_fair",
  MIGRATION_ETH           : "migration_eth",
  SWAP                    : "swap",
  RECHARGE                : "recharge",
  FUND_ETH                : "fund_eth",
  ACTION_RECHARGE         : "action_recharge",
  WHITELIST               : "whitelist",
  MIGRATION_RECHARGE      : "migration_recharge",
  UPDATE_JURISDICTION     : "update_jurisdiction",
  CONVERT_TO_CAFE         : "convert_to_cafe",
  INCREASE_COMMITMENT     : "increase_commitment",
  MANUAL_BUY              : "manual_buy",
  MINT                    : "mint",
  TRANSFER_TOKEN          : "transfer_token",
  USER_TRANSFER_TOKEN     : "user_transfer_token",
  INCOMING_TRANSFER_TOKEN : "incoming_transfer_token",
};

export const NOTIFICATION_TOPIC = {
  WALLET     : "walletNotification",
  TX         : "txNotification",
  ALLOCATION : "allocationNotification"
};

export const AML_MATCH_STATUS = {
  NO_MATCH              : "no_match",
  FALSE_POSITIVE        : "false_positive",
  POTENTIAL_MATCH       : "potential_match",
  TRUE_POSITIVE         : "true_positive",
  UNKNOWN               : "unknown",
  TRUE_POSITIVE_APPROVE : "true_positive_approve",
  TRUE_POSITIVE_REJECT  : "true_postive_reject",
};

export const VERIFICATION_STATUS = {
  ERROR                                      : "ERROR",
  NEED_KYC                                   : "NEED_KYC",
  REJECTED_KYC                               : "REJECTED_KYC",
  PENDING_KYC                                : "PENDING_KYC",
  REJECTED_AML                               : "REJECTED_AML",
  PENDING_TAX                                : "PENDING_TAX",
  NEED_KYB                                   : "NEED_KYB",
  PENDING_KYB                                : "PENDING_KYB",
  COMPANY_VERIFIED                           : "COMPANY_VERIFIED",
  COMPANY_REJECTED                           : "COMPANY_REJECTED",
  INDIVIDUAL_ROW_VERIFIED                    : "INDIVIDUAL_ROW_VERIFIED",
  INDIVIDUAL_NON_ACCREDITED_VERIFIED         : "INDIVIDUAL_NON_ACCREDITED_VERIFIED",
  INDIVIDUAL_NON_ACCREDITED_INVITED_VERIFIED : "INDIVIDUAL_NON_ACCREDITED_INVITED_VERIFIED",
  NON_ACCREDITED_INCOME_VERIFIED             : "NON_ACCREDITED_INCOME_VERIFIED",
  INDIVIDUAL_ACCREDITED_NOT_VERIFIED         : "INDIVIDUAL_ACCREDITED_NOT_VERIFIED",
  INDIVIDUAL_ACCREDITED_IN_REVIEW            : "INDIVIDUAL_ACCREDITED_IN_REVIEW",
  INDIVIDUAL_ACCREDITED_VERIFIED             : "INDIVIDUAL_ACCREDITED_VERIFIED",
  INDIVIDUAL_ACCREDITED_REJECTED             : "INDIVIDUAL_ACCREDITED_REJECTED",
};

export const ROUTE_PATH = {
  ROOT                              : "/",
  DASHBOARD                         : "/home",
  OUR_VISION                        : "/our-vision",
  CROWDFUNDING                      : "/public",
  SIGN_IN                           : "/signin",
  SIGN_IN_DISCOURSE                 : "/signin/discourse",
  SUBMIT_DOCUMENT                   : "/submit-document/:id",
  TWO_FACTOR                        : "/2fa",
  TRANSACTION_HISTORY               : "/transaction-history",
  OFFERING                          : "/offering-summary",
  ADMIN                             : "/admin",
  ADMIN_HOME                        : "/admin-home",
  ADMIN_ALLOCATIONS                 : "/admin-allocations",
  ADMIN_ALLOCATIONS_1               : "/admin-allocations-1",
  ADMIN_CRAFT_COMPENSATION_PLAN_DOC : "/admin-craft-compensation-plan-doc",
  ADMIN_CRAFT_ECA_DOC               : "/admin-craft-eca-doc",
  ADMIN_CRAFT_SPC                   : "/admin-craft-spc",
  ADMIN_CONTENT_LOGIN               : "/admin-content-login",
  ADMIN_CONTENT_WELCOME             : "/admin-content-welcome",
  ADMIN_CONTENT_DASHBOARD           : "/admin-content-dashboard",
  ADMIN_CONTENT_WHYPAGE             : "/admin-content-whypage",
  ADMIN_CONTENT_OFFERING            : "/admin-content-offering",
  ADMIN_CONTENT_GENERAL             : "/admin-content-general",
  ADMIN_CONTENT_PRESENTATION        : "/admin-content-presentation",
  ADMIN_CONTENT_CAFE_OFFERING       : "/admin-content-cafe-offering",
  ADMIN_CUSTOMERS                   : "/admin-customers",
  ADMIN_FAIRTABLE                   : "/admin-fairtable",
  ADMIN_TRANSFER_MONEY              : "/admin-transfer-money",
  ADMIN_ANALYTICS                   : "/admin-analytics",
  ADMIN_NOTIFICATION                : "/admin-notifications",
  ADMIN_COMMUNITY                   : "/admin-community",
  ADMIN_COMMUNITY_EQUITY            : "/admin-community-equity",
  ADMIN_SETTINGS_GENERAL            : "/admin-settings-general",
  ADMIN_SETTINGS_PORTAL_ACCESS      : "/admin-settings-portal-access",
  ADMIN_SETTINGS_WIDGET             : "/admin-settings-widget",
  ADMIN_SETTINGS_INTEGRATIONS       : "/admin-settings-integrations",
  ADMIN_FUNDRAISING_INVESTMENTS     : "/admin-fundraising-investments",
  ADMIN_FUNDRAISING_COMPLIANCE      : "/admin-fundraising-compliance",
  ADMIN_FUNDRAISING_RISK_FACTORS    : "/admin-fundraising-risk-factors",
  ADMIN_FUNDRAISING_FUNDING_OPTIONS : "/admin-fundraising-funding-options",
  ADMIN_EQUITY_GRANT_COMPENSATION   : "/admin-equity-grant-compensation",
  ADMIN_EQUITY_GRANT_POOLS          : "/admin-grants-pools",
  ADMIN_EQUITY_GRANT_LEGAL          : "/admin-grants-legal",
  RISKS                             : "/risks",
  COOKIE_POLICY                     : "/cookie-policy",
  DISCLAIMER                        : "/disclaimer",
  PRIVACY_POLICY                    : "/privacy-policy",
  TERMS_OF_SERVICE                  : "/terms-of-service",
  UPDATE_EMAIL                      : "/update-email",
  PRESENTATION                      : "/presentation",
  KYB                               : "/kyb",
  TOKEN_TERMS                       : "/token-terms",
  COMPENSATION_PLAN                 : "/compensation-plan",
  ECA                               : "/eca"
};

export const AUTH_LEVEL = {
  FREE       : "FREE",
  SIGN_IN    : "SIGN_IN",
  TWO_FACTOR : "TWO_FACTOR",
  DASHBOARD  : "DASHBOARD",
  ADMIN      : "ADMIN",
};

export const CUSTOMER_SUPPORT = {
  INTERCOM : "intercom",
  ERXES    : "erxes"
};

export const S_CONTRACT_NAME = {
  CAFE             : "CAFE",
  RSAFE            : "Rolling SAFE",
  COMMUNITY_SAFE   : "Community SAFE",
  COMMUNITY_SHARES : "Community Shares",
};

export const S_CONTRACT_TYPE = {
  CAFE               : "cafe",
  RSAFE              : "rsafe",
  RSAFE_FIXED_SHARES : "rsafe-fixed-shares",
  COMMUNITY_SAFE     : "safe",
  COMMUNITY_SHARES   : "shares"
};

export const S_REWARD_TYPE = {
  OPTIONS : "options",
  RSU     : "rsu"
};

export const S_CONTRACT_LEGAL = {
  CAFE               : "cafe",
  RSAFE              : "rolling safe",
  RSAFE_FIXED_SHARES : "rsafe-fixed-shares",
  COMMUNITY_SAFE     : "community safe",
};

export const S_CONTRACT_NETWORK = {
  ETHEREUM : "ethereum",
  OPTIMISM : "optimism",
};

export const CAFE_PARAM = {
  EQUITY       : "equityCommitment",
  STAKEHOLDERS : "stakeholders",
  INVESTORS    : "investors",
  MININVEST    : "minInvest",
  INIT_RESERVE : "initReserve",
};

export const ORG_STATUS = {
  NEW                  : "new",
  SETUP                : "setup",
  IN_REVIEW            : "in_review",
  LIVE                 : "live",
  PAUSE                : "pause",
  LIQUIDATED           : "liquidated",
  MAINTENANCE          : "maintenance",
  SUBSCRIPTION_EXPIRED : "subscription_expired",
};

export const APP_STAGE = {
  DEV        : "dev",
  STAGE      : "stage",
  PRODUCTION : "production",
};

export const ORGANIZATION = {
  FAIRMINT : "fairmint",
  QUARTZ   : "quartz",
};

export const INVESTMENT_STATUS = {
  FUNDING_NEEDED    : "FUNDING_NEEDED",
  FUNDING_STARTED   : "FUNDING_STARTED",
  FUNDING_NEED_MORE : "FUNDING_NEED_MORE",
  FUNDING_DONE      : "FUNDING_DONE",
  PROCESSED         : "PROCESSED",
  CANCELED          : "CANCELED",
};

export const LEGALDOC_STATUS = {
  REQUESTED : "REQUESTED",
  SENT      : "SENT"
};

export const INVESTMENT_TYPE = {
  UNDETERMINED : "UNDETERMINED",
  ON_CHAIN     : "ON_CHAIN",
  OFF_CHAIN    : "OFF_CHAIN"
};

export const ADMIN_INVESTMENT_ACTION = {
  ISSUE          : "issue",
  DELETE         : "delete",
  CANCEL         : "cancel",
  MAKE_OFF_CHAIN : "make_off_chain"
};

export const ADMIN_COMPENSATION_ACTION = {
  DELETE: "delete",
};

export const DIALOG_NAME = {
  Fund                      : "Fund",
  ContactUsKYB              : "ContactUsKYB",
  Accreditation             : "Accreditation",
  VerifyIdentity            : "VerifyIdentity",
  Activate2FA               : "Activate2FA",
  Deactivate2FA             : "Deactivate2FA",
  Invest                    : "Invest",
  FinalizeInvest            : "FinalizeInvest",
  Diagnostics               : "Diagnostics",
  Withdrawal                : "Withdrawal",
  SendUSDC                  : "SendUSDC",
  UserTransferToken         : "UserTransferToken",
  TransferToken             : "TransferToken",
  AddRecipient              : "AddRecipient",
  EditRecipient             : "EditRecipient",
  DeleteRecipient           : "DeleteRecipient",
  AddPool                   : "AddPool",
  DeletePool                : "DeletePool",
  SafeToCafe                : "SafeToCafe",
  BuySuccess                : "BuySuccess",
  Documents                 : "Documents",
  PendingInvestment         : "PendingInvestment",
  NotReadyInvestment        : "NotReadyInvestment",
  PendingSetup              : "PendingSetup",
  InvestmentSimulator       : "InvestmentSimulator",
  AccreditedInvestor        : "AccreditedInvestor",
  CompensateStakeholder     : "CompensateStakeholder",
  CloseTab                  : "CloseTab",
  AdminDocumentSent         : "AdminDocumentSent",
  NewOrganization           : "NewOrganization",
  PublicInvest              : "PublicInvest",
  InviteStakeholder         : "InviteStakeholder",
  InviteStakeholderResult   : "InviteStakeholderResult",
  ResendInvite              : "ResendInvite",
  GrantEquitySuccess        : "GrantEquitySuccess",
  UnlockCommunity           : "UnlockCommunity",
  CancelInvestment          : "CancelInvestment",
  EditCompanyInfo           : "EditCompanyInfo",
  EditCompanyEmails         : "EditCompanyEmails",
  EditCompanySigner         : "EditCompanySigner",
  EditCompanySocialAccounts : "EditCompanySocialAccounts",
  DomainSetup               : "DomainSetup",
  FinishDomainSetup         : "FinishDomainSetup",
  BankInfo                  : "BankInfo",
  GeneratingAllocation      : "GeneratingAllocation",
  GeneratingCompensation    : "GeneratingCompensation",
  GeneratingECADoc          : "GeneratingECADoc",
  CompanyWalletInfo         : "CompanyWalletInfo",
  ProcessingPortalLive      : "ProcessingPortalLive",
  CreatingAllocation        : "CreatingAllocation",
  Payment                   : "Payment",
  EntriDone                 : "EntriDone",
  KYBSubmitReview           : "KYBSubmitReview",
  KYBWaitInvestment         : "KYBWaitInvestment",
};

export const WIDGET_TYPE = {
  BUTTON : "button",
  ICON   : "icon",
  EMBED  : "embed",
};

export const TECH_SUPPORT_CODE = "TECH_SUPPORT_CODE";

export const EXCEPTIONAL_JURISDICTION = {
  RESET        : "RESET",
  CROWDFUNDING : "CROWDFUNDING"
};

export const CONTRACT_TYPE = {
  SERVICE_CONTRACT : "service_contract",
  EQUITY_AGREEMENT : "equity_agreement",
  INVEST_AGREEMENT : "invest_agreement"
};

export const ENROLLMENT_STATUS = {
  INVITED    : "INVITED",
  DECLINED   : "DECLINED",
  ERROR      : "ERROR",
  VERIFY_TAX : "VERIFY_TAX",
  SIGN_SPC   : "SIGN_SPC",
  ENROLLED   : "ENROLLED",
};

export const COMPENSATION_STATUS = {
  PENDING  : "PENDING",
  DECLINED : "DECLINED",
  SIGN_ECA : "SIGN_ECA",
  DONE     : "DONE"
};

export const ITEM_TYPE = {
  COMPENSATION : "compensation",
  ENROLLMENT   : "enrollment",
  INVESTMENT   : "investment",
  WALLET       : "wallet",
};

export const DB_ITEM_TYPE = {
  TRANSACTION : "transaction",
  EVENT       : "event"
};

export const NOTIFICATION_TYPE = {
  ADMIN : "admin",
  USER  : "user",
};

export const EVENT_TYPE = {
  SPC_STARTED                 : "spc_started",
  ECA_STARTED                 : "eca_started",
  ECA_SIGNED                  : "eca_signed",
  SPC_SIGNED                  : "spc_signed",
  TAX_SIGNED                  : "tax_signed",
  ECA_CANCELLED               : "eca_cancelled",
  SPC_CANCELLED               : "spc_cancelled",
  INVITATION_SENT             : "invitation_sent",
  INVITATION_ACCEPTED         : "invitation_accepted",
  INVITATION_DECLINED         : "invitation_declined",
  JURISDICTION_MISMATCH       : "jurisdiction_mismatch",
  ACCESS_KYC_DATA             : "access_KYC_data",
  ACCREDITATION_INFO_ADDED    : "accreditation_info_added",
  SELF_ACCREDITATION          : "self_accreditation",
  ACCEPT_ACCREDITATION        : "accept_accreditation",
  REJECT_ACCREDITATION        : "reject_accreditation",
  ADD_RECIPIENT               : "add_recipient",
  UPDATE_RECIPIENT            : "update_recipient",
  DELETE_RECIPIENT            : "delete_recipient",
  ALLOW_INVESTING             : "allow_investing",
  DISALLOW_INVESTING          : "disallow_investing",
  ENABLE_USER_TRANSFER_TOKEN  : "enable_user_transfer_token",
  DISABLE_USER_TRANSFER_TOKEN : "disable_user_transfer_token",
  ENABLE_2FA                  : "enable_2FA",
  DISABLE_2FA                 : "disable_2FA",
  BLOCK_USER                  : "block_user",
  UNBLOCK_USER                : "unblock_user",
  SET_TEMPORARY_MIN_INVEST    : "set_temporary_min_invest",
  SET_TEMPORARY_MAX_INVEST    : "set_temporary_max_invest",
  SAVE_USER_PERMISSIONS       : "save_user_permissions",
  MAKE_INDIVIDUAL             : "make_individual",
  RESET_COUNTRY               : "reset_country",
  ACCEPT_KYB                  : "accept_kyb",
  REJECT_KYB                  : "reject_kyb",
  ACCEPT_KYC                  : "accept_kyc",
  RESET_KYC                   : "reset_kyc",
  REJECT_KYC                  : "reject_kyc",
  PENDING_KYC                 : "pending_kyc",
  PENDING_KYB                 : "pending_kyb",
  ACCEPT_AML                  : "accept_aml",
  REJECTED_AML                : "rejected_aml",
  EXPORT_DATA                 : "export_data",
  PORTAL_IN_REVIEW            : "portal_in_review", // We use portal_submitted for this event. This one is deprecated.
  PORTAL_SUBMITTED            : "portal_submitted",
  CONFIRMATION_CODE_SENT      : "confirmation_code_sent",
  NEW_PORTAL                  : "new_portal",
  GENERATE_DNS_ERROR          : "generate_dns_error",
  VERIFY_DNS_ERROR            : "verify_dns_error",
  USER_SIGNUP                 : "user_signup",
  ETH_BALANCE_ALERT           : "eth_balance_alert",
  CALCULATE_TOKEN_ERROR       : "calculate_token_error",
  SEND_EMAIL_FAILED           : "send_email_failed",
  KYC_ERROR                   : "kyc_error",
  BLOCKNATIVE_WALLET_ERROR    : "blocknative_wallet_error",
  CHARGEBEE_CHARGE_PROCESSED  : "chargebee_charge_processed",
  CHARGEBEE_CHARGE_FAILED     : "chargebee_charge_failed",
  FIRST_INVESTMENT            : "first_investment",
  INVESTMENT_SIGNED           : "investment_signed",
  INVESTMENT_PROCESSED        : "investment_processed",
  INVESTMENT_CANCELED         : "investment_canceled",
  INSUFFICIENT_FUNDS          : "insufficient_funds",
  UPDATE_JURISDICTION_TX      : "update_jurisdiction_tx",
  PERMIT_USDC_TX              : "permit_usdc_tx",
  FUND_TX                     : "fund_tx",
  FUNDING_COMPLETED           : "funding_completed",
  OFF_CHAIN_INVESTMENT_SENT   : "off_chain_investment_sent",
  BUY_TX                      : "buy_tx",
  MANUAL_BUY_TX               : "manual_buy_tx",
  WITHDRAW_TX                 : "withdraw_tx",
  WITHDRAW_FEE_TX             : "withdraw_fee_tx",
  USER_TRANSFER_TOKEN         : "user_transfer_token",
  INCOMING_TRANSFER_TOKEN     : "incoming_transfer_token",
  DOCUMENT_REQUESTED          : "document_requested",
  DOCUMENT_SENT               : "document_sent",
  MESSAGE_TO_FOUNDER          : "message_to_founder",
  RISK_FACTORS_CHANGED        : "risk_factors_changed",
  AGREEMENT_GENERATED         : "agreement_generated",
  AGREEMENT_VERIFIED          : "agreement_verified",
  FIRST_SIGNUP                : "first_signup",
  ALLOCATION_PAUSED           : "allocation_paused",
  ALLOCATION_UNPAUSED         : "allocation_unpaused",
  ALLOCATION_STOPPED          : "allocation_stopped",
  ALLOCATION_CREATED          : "allocation_created",
  ALLOCATION_CHANGED          : "allocation_changed",
  ALLOCATION_RESET            : "allocation_reset",
  RECIPIENT_SAVED             : "recipient_saved",
  PORTAL_SET_LIVE_STARTED     : "portal_set_live_started",
  PORTAL_SET_LIVE_SUCCESS     : "portal_set_live_success",
  PORTAL_REJECTED             : "portal_rejected",
  PORTAL_DELETED              : "portal_deleted",
  MARKED_AS_PAID              : "marked_as_paid",
  COMPENSATION_PLAN_CRAFTED   : "compensation_plan_crafted",
  COMPENSATION_PLAN_RESET     : "compensation_plan_reset",
  ECA_CRAFTED                 : "eca_crafted",
  SPC_CRAFTED                 : "spc_crafted",
  ECA_RESET                   : "eca_reset",
  POOL_CREATED                : "pool_created",
  POOL_UPDATED                : "pool_updated",
  POOL_DELETED                : "pool_deleted",
  POOL_LIVE                   : "pool_live",
};

export const EVENT_INVITATION_SENT_TYPE = {
  JOIN   : "join",
  ENROLL : "enroll",
  REWARD : "reward"
};

export const EVENT_COMPENSATION_METHOD = {
  API    : "api",
  MANUAL : "manual"
};

export const WALLET_UPDATE_TYPE = {
  TWO_FACTOR_ENABLED   : "two_factor_enabled",
  BLOCKED              : "blocked",
  TRANSFER_TOKEN       : "transfer_token",
  ACTION_REQUIRED      : "action_required",
  MAX_INVEST           : "max_invest",
  MIN_INVEST           : "min_invest",
  PERMISSIONS          : "permissions",
  MAKE_INDIVIDUAL      : "make_individual",
  RESET_COUNTRY        : "reset_country",
  REFRESH_BALANCE      : "refresh_balance",
  KYC                  : "KYC",
  AML                  : "AML",
  ACCREDITATION_ACTION : "accreditation_action",
  KYB                  : "KYB",
};

export const CAFE_CONSTANTS = {
  MAX_UINT:
    "115792089237316195423570985008687907853269984665640564039457584007913129639935",
  MAX_GAS      : 6700000,
  ZERO_ADDRESS : "0x0000000000000000000000000000000000000000",
  STATE        : {
    INIT   : 0,
    RUN    : 1,
    CLOSE  : 2,
    CANCEL : 3,
  },
  STATES           : ["INIT", "RUN", "CLOSE", "CANCEL"],
  BASIS_POINTS_DEN : 10000,
};

export const DOMESTIC_TRANSFER_FIELDS = [
  "Type of account",
  "ABA Routing number",
  "Account number",
  "Bank name",
  "Bank address",
  "Beneficiary name",
  "Beneficiary address",
];

export const INTL_TRANSFER_FIELDS = [
  "Type of account",
  "SWIFT/BIC code",
  "Account number",
  "Bank name",
  "Bank address",
  "Beneficiary name",
  "Beneficiary address",
  "Special instructions",
];

export const SEPA_TRANSFER_FIELDS = [
  "Bank name",
  "Bank address",
  "Beneficiary IBAN",
  "Beneficiary name",
  "Beneficiary address",
];

export const DNS_STATUS = {
  VERIFIED : "verified",
  PENDING  : "pending",
  FAILED   : "failed",
};

export const MENU_COLOR = {
  enabled  : "#5f5f5f",
  disabled : "#ccc"
};

export const LIVE_DOMAIN_STATUS = {
  GENERATED       : "generated",
  GENERATE_FAILED : "generate_failed",
  VERIFIED        : "verified",
  VERIFY_FAILED   : "verify_failed"
};

export const ALLOCATION_STATUS = {
  PENDING : "pending",
  ACTIVE  : "active",
  PAUSED  : "paused",
  STOPPED : "stopped"
};

export const TRANSFER_METHOD = {
  WALLET: "wallet"
};

export const ALLOCATION_STOP_REASON = {
  GOAL_RAISED    : "goal raised",
  NEW_ALLOCATION : "new allocation created"
};

export const PAYMENT_TYPE = {
  FUNDRAISING  : "fundraising",
  COMPENSATION : "compensation"
};

export const POOL_STATUS = {
  SETUP : "setup",
  LIVE  : "live"
};

export const COMPANY_ACCREDITATION = {
  LETTER         : "letter",
  LICENSE        : "license",
  NON_ACCREDITED : "non_accredited",
};

export const COMPANY_TYPE = {
  LLC    : "Limited Liability Company",
  LP     : "Limited Partnership",
  C_COPR : "C Corporation",
  S_COPR : "S Corporation",
  GP     : "General Partnership",
  FE     : "Foreign Entity",
  TRUST  : "Trust",
  FUND   : "Investment company or fund",
};

export const BERBIX_ACTION = {
  ACCEPT : "accept",
  REJECT : "reject",
  REVIEW : "review",
};

export const AML_STATUS = {
  ACCEPT : "accept",
  ERROR  : "error",
  REJECT : "reject",
};
