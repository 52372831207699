import { createReducer } from "reduxsauce";
import { Types } from "../actions/admin";
import { Types as adminCompensationTypes } from "../actions/admin-compensation";
import { success } from "../utils/action";

// Initial State
const initialState = {
  investors      : {},
  pools          : [],
  contracts      : [],
  poolStatistics : null,
  navLeftStatus  : null
};

const getInvestors = (state, action) => ({
  ...state,
  investors: action.payload,
});

const addPool = (state, action) => {
  const { pools } = state;
  pools.unshift(action.payload.pool);
  return { ...state };
};

const updatePool = (state, action) => {
  const { pools } = state;
  const itemIndex = pools.findIndex(p => p.id === action.payload.pool.id);
  if (itemIndex === -1) {
    pools.unshift(action.payload.pool);
  }
  else {
    pools[itemIndex] = action.payload.pool;
  }

  return { ...state };
};

const deletePool = (state, action) => ({ ...state, pools: state.pools.filter(p => p.id !== action.payload.id) });

const listPools = (state, { payload }) => ({
  ...state,
  pools: payload.pools.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)),
});

const getOrgContracts = (state, { payload }) => ({
  ...state,
  contracts: payload.contracts,
});

const addContract = (state, { payload }) => ({
  ...state,
  contracts : payload.contract ? [...state.contracts, payload.contract] : state.contracts,
  pools     : state.pools.map(pool => (pool.id === payload.pool.id ? payload.pool : pool)),
});

const updateContract = (state, { payload }) => ({
  ...state,
  contracts : state.contracts.map(contract => (contract.id === payload.contract.id ? payload.contract : contract)),
  pools     : payload.pools,
});

const removeContract = (state, { payload }) => ({
  ...state,
  contracts: state.contracts.filter(contract => (contract.id !== payload.contract.id)),
});

const getCompensationByPool = (state, action) => ({ ...state, poolStatistics: action.payload });

const getNavLeftStatus = (state, action) => ({
  ...state,
  navLeftStatus: action.payload,
});

// map action types to reducer functions
export const handlers = {
  [success(Types.GET_INVESTORS)]                         : getInvestors,
  [success(Types.ADD_POOL)]                              : addPool,
  [success(Types.UPDATE_POOL)]                           : updatePool,
  [success(Types.DELETE_POOL)]                           : deletePool,
  [success(Types.LIST_POOLS)]                            : listPools,
  [success(Types.GET_ORG_CONTRACTS)]                     : getOrgContracts,
  [success(Types.GET_COMPENSATION_BY_POOL)]              : getCompensationByPool,
  [success(Types.GET_NAV_LEFT_STATUS)]                   : getNavLeftStatus,
  [success(adminCompensationTypes.GENERATE_ECA)]         : addContract,
  [success(adminCompensationTypes.GENERATE_SPC)]         : addContract,
  [success(adminCompensationTypes.RESET_ECA)]            : removeContract,
  [success(adminCompensationTypes.UPDATE_POOL_CONTRACT)] : updateContract,
};

// Export Reducer
export default createReducer(initialState, handlers);
