import TagManager from "react-gtm-module";

import { put, call, select, takeEvery, takeLatest, all } from "redux-saga/effects";
import { NotificationManager } from "react-notifications";
import { captureMessage }  from "@sentry/browser";

import Messenger from "src/utils/messenger";
import Investment from "src/utils/investment";
import { isPending } from "src/utils/transaction";
import { isSetupMode } from "src/utility";
import initNetwork from "src/utils/network";
import {
  VERIFICATION_STATUS, ORG_STATUS, INVESTMENT_STATUS, INVESTMENT_TYPE, TX_STATUS, DIALOG_NAME
} from "src/constants";
import config from "src/config";

import { Types, Creators } from "../actions/global";
import { Creators as authCreators } from "../actions/auth";
import { Creators as userCreators } from "../actions/user";
import { origin, successCreator, requestCreator, failureCreator } from "../utils/action";
import API from "../api/global";

const {
  REACT_APP_DEVELOPMENT,
  REACT_APP_STAGE,
  REACT_APP_ORG
} = process.env;
const REACT_APP_E2E = (REACT_APP_STAGE === "dev" && REACT_APP_ORG === "beem");

function* listenAction(action) {
  try {
    const { status } = yield select(state => state.global);
    if (action.type.endsWith("/request")) {
      const newStatus = {
        ...status,
        [origin(action.type)]: "request",
      };
      yield put(Creators.updateState({ status: newStatus }));
    }
    else if (action.type.endsWith("/success")) {
      const newStatus = {
        ...status,
        [origin(action.type)]: "success",
      };
      yield put(Creators.updateState({ status: newStatus }));
    }
    else if (action.type.endsWith("/failure")) {
      const newStatus = {
        ...status,
        [origin(action.type)]: "failure",
      };
      yield put(Creators.updateState({ status: newStatus }));

      const { err, showAlert, logError } = action.payload;
      let message;
      let statusCode;
      if (err?.response?.data?.err) {
        statusCode = err.response.status;
        message = err.response.data.err;
      }
      else if (err.message) {
        message = err.message;
      }
      else {
        message = err;
      }
      if (statusCode === 410 && err?.response?.data?.liveDomain) {
        window.location.href = "https://" + err?.response?.data?.liveDomain;
        return;
      }
      if (statusCode === 401) {
        yield put(authCreators.signOut());
      }
      if (statusCode !== 401 && message && showAlert !== false) {
        NotificationManager.error(message.toString(), "", config.notificationDuration);

        if (logError !== false && (
          REACT_APP_DEVELOPMENT !== "true" || REACT_APP_E2E
        )) {
          captureMessage(message.toString());
        }
      }
    }
  }
  catch (err) {
    // if (app.alertUncaughted) {
    //   if (err) {
    //     NotificationManager.error(
    //       errorMessage(err.errorMessage || err.message),
    //       'Error...'
    //     )
    //   }
    // }
  }
}

function* setVerifyIdentityDialog(action) {
  const { walletItem } = yield select(state => state.user);
  if (action.flag && [VERIFICATION_STATUS.NEED_KYC, VERIFICATION_STATUS.NEED_KYB].includes(walletItem.verification_status)) {
    yield put(userCreators.getBerbixStatus());
  }
  if (action.flag) {
    yield put(Creators.setCurrentDialog(DIALOG_NAME.VerifyIdentity));
  }
  else {
    yield put(Creators.setCurrentDialog(null));
  }
}

function* setInvestDialog(action) {
  // If closing
  if (!action.flag) {
    yield put(Creators.setCurrentDialog(null));
    return;
  }

  // Get investItem
  const { walletItem, investItem } = yield select(state => state.user);
  const orgConfig = yield select(state => state.global.orgConfig);

  // If app is sill not live
  if (isSetupMode(orgConfig.status)) {
    yield put(Creators.setCurrentDialog(DIALOG_NAME.PendingSetup));
    return;
  }

  if (!orgConfig.chargebee.fundraising || orgConfig.status !== ORG_STATUS.LIVE) {
    yield put(Creators.setCurrentDialog(DIALOG_NAME.NotReadyInvestment));
    return;
  }

  if ([VERIFICATION_STATUS.PENDING_KYB, VERIFICATION_STATUS.NEED_KYB].includes(walletItem.verification_status)) {
    yield put(Creators.setCurrentDialog(DIALOG_NAME.KYBWaitInvestment));
    return;
  }

  // Check geo fencing
  yield put(userCreators.checkGeoFence());

  if (walletItem.blocked) {
    yield put(Creators.setCurrentDialog(DIALOG_NAME.Invest));
    return;
  }

  // If no in-progress investItem
  if (!investItem || !Investment.isInProgress(investItem)) {
    // Show Invest dialog
    yield put(Creators.setCurrentDialog(DIALOG_NAME.Invest));
    return;
  }

  // Show PendingInvestment Dialog
  yield put(Creators.setCurrentDialog(DIALOG_NAME.PendingInvestment));
}

function* setFundDialog(action) {
  // If closing
  if (!action.flag) {
    yield put(Creators.setCurrentDialog(null));
    return;
  }

  // Get investItem
  const { investItem, transactions } = yield select(state => state.user);

  // If on-chain funding-done
  if (investItem
    && investItem.type === INVESTMENT_TYPE.ON_CHAIN
    && investItem.status === INVESTMENT_STATUS.FUNDING_DONE) {
    // Find corresponding tx item
    const txItem = transactions.find(tx => tx.investItemSK === investItem.SK);
    if (txItem) {
      if (isPending(txItem.status)) {
      // Show pending investment
        yield put(Creators.setCurrentDialog(DIALOG_NAME.PendingInvestment));
        return;
      }
      if (txItem.status === TX_STATUS.CONFIRMED) {
      // Show Fund dialog
        yield put(Creators.setCurrentDialog(DIALOG_NAME.Fund));
        return;
      }
      // Tx Failed: Show finalize investment
      yield put(Creators.setCurrentDialog(DIALOG_NAME.FinalizeInvest));
      return;
    }

    // Show finalize investment
    yield put(Creators.setCurrentDialog(DIALOG_NAME.FinalizeInvest));
    return;
  }

  // Default
  yield put(Creators.setCurrentDialog(DIALOG_NAME.Fund));
}

function* getConfiguration(action) {
  const type = action.type;
  try {
    yield put(requestCreator(type, {}));
    const payload = yield call(API[type], action);
    yield put(successCreator(type, payload));

    // If not live, then return
    if (![
      ORG_STATUS.NEW,
      ORG_STATUS.SETUP,
      ORG_STATUS.IN_REVIEW,
      ORG_STATUS.LIVE,
      ORG_STATUS.LIQUIDATED,
    ].includes(window.orgConfig.status)) {
      return;
    }

    initNetwork(window.orgConfig);
    yield put(authCreators.checkToken());

    // erxes
    if (window.orgConfig.gtmId) {
      TagManager.initialize({ gtmId: window.orgConfig.gtmId });
    }
    // otherwise
    else if (config.gtmId) {
      TagManager.initialize({ gtmId: config.gtmId });
    }

    // Load messenger
    Messenger.loadMessenger();
  }
  catch (err) {
    console.error(err);
    yield put(failureCreator(type, { err }));
  }
}

export function* globalSaga() {
  yield all([
    takeEvery("*", listenAction),
    takeLatest(Types.GET_CONFIGURATION,          getConfiguration),
    takeLatest(Types.SET_INVEST_DIALOG,          setInvestDialog),
    takeLatest(Types.SET_FUND_DIALOG,            setFundDialog),
    takeLatest(Types.SET_VERIFY_IDENTITY_DIALOG, setVerifyIdentityDialog),
  ]);
}
