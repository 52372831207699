import { createReducer } from "reduxsauce";
import { Types } from "../actions/admin-allocation";
import { success } from "../utils/action";

// Initial State
const initialState = {
  allocations: []
};

const getAllocations = (state, { payload: allocations }) => ({ allocations });

const addAllocation = (state, { payload }) => ({
  allocations: payload.allocations,
});

const updateAllocation = (state, { payload }) => {
  const { allocations } = state;
  const index = allocations.findIndex(({ id }) => id === payload.id);
  allocations[index] = payload;

  return { allocations: [...allocations] };
};

const resetAllocation = () => ({ allocations: [] });

// map action types to reducer functions
export const handlers = {
  [success(Types.GET_ALLOCATIONS)]          : getAllocations,
  [success(Types.ADD_ALLOCATION)]           : addAllocation,
  [success(Types.UPDATE_ALLOCATION)]        : updateAllocation,
  [success(Types.RESET_ALLOCATION)]         : resetAllocation,
  [success(Types.UPDATE_ALLOCATION_STATUS)] : updateAllocation,
};

// Export Reducer
export default createReducer(initialState, handlers);
