/* eslint-disable no-underscore-dangle */
import BigNumber from "bignumber.js";
import Contracts from "src/utils/contracts";
import {
  callLambda,
  SERVICE_1,
  SERVICE_2,
  uploadFileToPrivateS3
} from "src/utils/lambda";
import { handleDownload, downloadFromNewTab, populateOrgConfig } from "src/utility";
import { Types } from "../actions/user";
import {
  CAFE_CONSTANTS,
  TX_TYPE
} from "../../constants";
import config from "../../config";

const apis = {};

apis[Types.SET_TRANSACTION_READ] = action => callLambda({
  method  : "post",
  service : SERVICE_1,
  url     : "transaction/setTransactionRead",
  body    : { txId: action.txId }
});

apis[Types.SET_INVEST_ITEM_READ] = action => callLambda({
  method  : "post",
  service : SERVICE_1,
  url     : "setInvestItemRead",
  body    : { investItemSK: action.investItemSK, msgData: action.msgData }
});

apis[Types.GET_BERBIX_STATUS] = () => callLambda({
  method  : "get",
  service : SERVICE_1,
  url     : "berbix/getStatus",
});

apis[Types.GET_MESSENGER_TOKEN] = () => callLambda({
  method  : "post",
  service : SERVICE_1,
  url     : "getMessengerToken",
});

apis[Types.VERIFICATION_FINISHED] = () => callLambda({
  method  : "post",
  service : SERVICE_1,
  url     : "berbix/verificationFinished",
});

apis[Types.ACTIVATE2_FA] = action => callLambda({
  method  : "post",
  service : SERVICE_1,
  url     : "user/activate2FA",
  body    : {
    pinCode           : action.pinCode,
    two_factor_secret : action.two_factor_secret
  }
});

apis[Types.DEACTIVATE2_FA] = action => callLambda({
  method  : "post",
  service : SERVICE_1,
  url     : "user/deactivate2FA",
  body    : { pinCode: action.pinCode }
});

apis[Types.GET_USER_AND_WALLET] = async (action) => {
  const { orgItem, userItem, walletItem, investItem } = await callLambda({
    method  : "post",
    service : SERVICE_1,
    url     : "getUserAndWallet",
    body    : { code: action.code }
  });

  return {
    orgConfig: await populateOrgConfig(orgItem),
    userItem,
    walletItem,
    investItem
  };
};

apis[Types.REFRESH_USER_AND_WALLET] = () => callLambda({
  method  : "post",
  service : SERVICE_1,
  url     : "getUserAndWallet",
});

apis[Types.GET_TRANSACTIONS] = () => callLambda({
  method  : "get",
  service : SERVICE_1,
  url     : "transaction/getTransactions",
});

apis[Types.INITIALIZE_CORG] = async () => {
  window.contracts = await Contracts.init(window.provider, window.orgConfig);
};

apis[Types.REFRESH_ORG_INFO] = async () => {
  if (window.contracts) {
    await window.contracts.init();
  }
};

apis[Types.REFRESH_ACCOUNT_INFO] = async ({ walletAddress }) => {
  if (window.contracts) {
    await window.contracts.refreshAccountInfo(walletAddress);
  }
};

apis[Types.SET_WELCOMED] = action => callLambda({
  method  : "post",
  service : SERVICE_1,
  url     : "user/setWelcomed",
  body    : action.payload,
});

apis[Types.UPDATE_USER] = async (action) => {
  const { payload: body } = action;

  if (body.accreditationStatus?.certificateDoc instanceof Blob) {
    const { certificateDoc } = body.accreditationStatus;
    body.accreditationStatus.certificateDoc = await uploadFileToPrivateS3(
      certificateDoc,
      certificateDoc.name,
      certificateDoc.type
    );
  }

  if (body.companyInfo?.documents) {
    const { documents } = body.companyInfo;
    body.companyInfo.documents = await Promise.all(
      documents.map(async (document) => {
        if (document instanceof Blob) {
          return uploadFileToPrivateS3(document, document.name, document.type);
        }
        return document;
      })
    );
  }

  if (body.companyInfo?.accreditationLetter instanceof Blob) {
    const { accreditationLetter } = body.companyInfo;
    body.companyInfo.accreditationLetter = await uploadFileToPrivateS3(
      accreditationLetter,
      accreditationLetter.name,
      accreditationLetter.type
    );
  }

  return callLambda({
    method  : "post",
    service : SERVICE_1,
    url     : "user/updateUser",
    body,
  });
};

apis[Types.UPDATE_WALLET] =  action => callLambda({
  method  : "post",
  service : SERVICE_1,
  url     : "user/updateWallet",
  body    : action.payload,
});

apis[Types.SET_ELECTRONIC_SIGNATURE] = action => callLambda({
  method  : "post",
  service : SERVICE_2,
  url     : "user/setElectronicSignature",
  body    : action.payload,
});

apis[Types.SEND_CONFIRMATION_CODE] = action => callLambda({
  method  : "post",
  service : SERVICE_2,
  url     : "user/sendConfirmationCode",
  body    : action.payload,
});

apis[Types.USER_TRANSFER_TOKEN] = async ({ payload: { toEmail, amount, pinCode } }, { confirmationId }) => {
  const { success } = await callLambda({
    method  : "post",
    service : SERVICE_2,
    url     : "user/checkConfirmationCode",
    body    : {
      id   : confirmationId,
      code : pinCode
    },
  });
  if (!success) {
    throw new Error("Wrong code");
  }

  const { contracts } = window;
  const amountValue = new BigNumber(amount)
    .shiftedBy(contracts.data.decimals)
    .dp(0)
    .toFixed();

  const approveSignature = await contracts.signPermit(
    config.operatorAddress,
    amountValue,
    CAFE_CONSTANTS.MAX_UINT
  );

  const { txItem } = await callLambda({
    method  : "post",
    service : SERVICE_2,
    url     : "sendPermits",
    body    : {
      type: TX_TYPE.USER_TRANSFER_TOKEN,
      toEmail,
      amount,
      amountValue,
      approveSignature,
    },
  });
  return txItem;
};

apis[Types.WITHDRAW_CURRENCY] = async ({ toAddress, amount, pinCode }, { confirmationId }) => {
  const { success } = await callLambda({
    method  : "post",
    service : SERVICE_2,
    url     : "user/checkConfirmationCode",
    body    : {
      id   : confirmationId,
      code : pinCode
    },
  });
  if (!success) {
    throw new Error("Wrong code");
  }

  const { contracts } = window;
  const amountValue = new BigNumber(amount)
    .shiftedBy(contracts.data.currency.decimals)
    .dp(0)
    .toFixed();

  const approveSignature = await contracts.signCurrencyPermit(
    config.operatorAddress,
    amountValue,
    CAFE_CONSTANTS.MAX_UINT
  );

  const { txItem } = await callLambda({
    method  : "post",
    service : SERVICE_2,
    url     : "sendPermits",
    body    : {
      type: TX_TYPE.WITHDRAW,
      toAddress,
      amount,
      amountValue,
      approveSignature,
    },
  });
  return txItem;
};

apis[Types.SIGN_AND_BUY] = async ({ amount, tokenEstimate, embedded, priceProtection }) => {
  const investmentBody = {
    amount,
    tokenEstimate   : tokenEstimate.toNumber(),
    tokenPrice      : Number(amount) / tokenEstimate.toNumber(),
    currencyBalance : window.contracts.data.account.currencyBalance.toNumber(),
    hellosign       : {
      clientId           : embedded.clientId,
      signatureRequestId : embedded.signatureRequestId,
      testMode           : embedded.testMode,
    },
    priceProtection
  };
  const { investItem } = await callLambda({
    method  : "post",
    service : SERVICE_1,
    url     : "addInvestment",
    body    : investmentBody,
  });

  return investItem;
};

apis[Types.START_BANK_TRANSFER] = async ({ method }) => {
  const { investItem } = await callLambda({
    method  : "post",
    service : SERVICE_1,
    url     : "startBankTransfer",
    body    : {
      transferMethod: method
    }
  });
  return investItem;
};

apis[Types.CANCEL_INVESTMENT] = async () => {
  const { investItem } = await callLambda({
    method  : "post",
    service : SERVICE_1,
    url     : "cancelInvestment",
  });
  return investItem;
};

apis[Types.REMOVE_PRICE_PROTECTION] = async () => {
  const { investItem } = await callLambda({
    method  : "post",
    service : SERVICE_1,
    url     : "removePriceProtection",
  });
  return investItem;
};

apis[Types.UPDATE_INVEST_ITEM_STATUS] = async () => {
  const { investItem } = await callLambda({
    method  : "post",
    service : SERVICE_2,
    url     : "updateInvestItemStatus",
  });
  return investItem;
};

apis[Types.GET_OWN_INVESTMENT_DOC_URL] = async ({ investItemSK, fileName }) => {
  const { documentUrl, investItem } = await callLambda({
    method  : "post",
    service : SERVICE_2,
    url     : "getOwnInvestmentDocUrl",
    body    : { investItemSK }
  });
  handleDownload(documentUrl, fileName);
  return { investItem };
};

apis[Types.GET_OWN_COMP_DOC_URL] = async ({ item }) => {
  const pdfURL = await callLambda({
    method  : "post",
    service : SERVICE_2,
    url     : "getOwnCompDocUrl",
    body    : { id: item.id, type: item.itemType }
  });
  downloadFromNewTab(pdfURL);
  return item;
};

apis[Types.BUY_TOKENS] = async ({ investItem, amount }) => {
  const { signed, SK: investItemSK } = investItem;
  const { contracts, orgConfig } = window;
  const { account } = contracts.data;

  const investAmount = amount || signed.amount;
  // Get token estimate
  const tokenEstimate = await contracts.estimateBuyValue(investAmount);
  if (!tokenEstimate || tokenEstimate.eq(0)) {
    throw new Error("0 expected value");
  }

  // Check price protection
  const tokenPrice = Number(investAmount) / tokenEstimate.toNumber();
  if (
    investItem.priceProtection
    && !investItem.priceProtection.removedByInvestor
    && tokenPrice > investItem.priceProtection.price
  ) {
    throw new Error("Price Protection Activated");
  }

  // Check amount
  if (account.currencyBalance.toNumber() < investAmount) {
    return null;
  }

  // Get signatures
  let approveSignature = null;
  if (account.allowance.eq(0)) {
    approveSignature = await contracts.signCurrencyPermit(
      contracts.dat.address,
      CAFE_CONSTANTS.MAX_UINT,
      CAFE_CONSTANTS.MAX_UINT
    );
  }
  const buySignature = await contracts.signPermitBuy(
    account.address,
    investAmount,
    1,
    CAFE_CONSTANTS.MAX_UINT
  );

  // Buy tx item
  const buyTxItem = {
    input: {
      value    : investAmount,
      currency : orgConfig.currencySymbol
    },
    output: {
      value    : tokenEstimate.toNumber(),
      currency : orgConfig.tokenSymbol
    },
  };

  // Send permits
  const body = {
    type   : TX_TYPE.BUY,
    amount : investAmount,
    buyTxItem,
    investItemSK,
    approveSignature,
    buySignature
  };
  const { txItem } = await callLambda({
    method  : "post",
    service : SERVICE_2,
    url     : "sendPermits",
    body,
  });
  return txItem;
};

apis[Types.GET_OWN_LEGAL_ITEMS] = async () => {
  const result = await callLambda({
    method  : "post",
    service : SERVICE_1,
    url     : "getOwnLegalItems",
  });

  return result;
};

apis[Types.REQUEST_INVESTMENT_PDF] = ({ investItemSK }) => callLambda({
  method  : "post",
  service : SERVICE_1,
  url     : "requestInvestmentPDF",
  body    : { investItemSK }
});

apis[Types.GET_COMPENSATIONS] = () => callLambda({
  method  : "post",
  service : SERVICE_2,
  url     : "getCompensations",
});

apis[Types.SET_ENROLLMENT_READ] = action => callLambda({
  method  : "post",
  service : SERVICE_2,
  url     : "setEnrollmentRead",
  body    : { id: action.id }
});

apis[Types.SET_COMPENSATION_READ] = action => callLambda({
  method  : "post",
  service : SERVICE_2,
  url     : "setCompensationRead",
  body    : { id: action.id }
});

apis[Types.SET_INVITED_ENROLL_ACTION] = ({ id, action }) => callLambda({
  method  : "post",
  service : SERVICE_2,
  url     : "invitedEnrollmentAction",
  body    : { id, action }
});

apis[Types.SET_COMPENSATION_SIGNED] = ({ id, hellosign }) => callLambda({
  method  : "post",
  service : SERVICE_2,
  url     : "compensationSigned",
  body    : { id, hellosign }
});

apis[Types.SET_ENROLLMENT_SIGNED] = ({ id, hellosign }) => callLambda({
  method  : "post",
  service : SERVICE_2,
  url     : "enrollmentSigned",
  body    : { id, hellosign }
});

apis[Types.GET_BANK_INFO] = () => callLambda({
  method  : "post",
  service : SERVICE_1,
  url     : "getBankInfo",
});

apis[Types.GET_CURRENT_ALLOCATION] = () => callLambda({
  method  : "post",
  service : SERVICE_2,
  url     : "getCurrentAllocation",
});

apis[Types.GET_ORG_BENEFICIARY] = async () => {
  const orgBeneficiary = await window.contracts.getAccountInfo(window.contracts.data.beneficiary);
  return orgBeneficiary;
};

apis[Types.SEND_KYC_EMAIL_TO_BO] = async ({ email }) => callLambda({
  method  : "post",
  service : SERVICE_2,
  url     : "sendKYCEmailToBO",
  body    : { email }
});

apis[Types.CHECK_GEO_FENCE] = () => callLambda({
  method  : "post",
  service : SERVICE_2,
  url     : "geo/checkGeoFence",
});

apis[Types.CHECK_IP_COUNTRY] = async () => callLambda({
  method  : "post",
  service : SERVICE_2,
  url     : "geo/checkIPCountry",
});

export default apis;
