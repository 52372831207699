import axios from "axios";
import dayjs from "dayjs";
import fileDownload from "js-file-download";
import merge from "lodash/merge";
import compact from "lodash/compact";
import pick from "lodash/pick";
import config from "src/config";

import { AUTH_LEVEL, USER_PERMISSION, ORG_STATUS, LIVE_DOMAIN_STATUS, S_CONTRACT_NETWORK } from "./constants";

export function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function roundDown(value, digits) {
  const base = 10 ** digits;
  return Math.floor(value * base) / base;
}

export const injectVariables = (data, variables) => {
  if (!data) return data;
  if (typeof data === `object`) {
    Object.keys(data).forEach((key) => {
      data[key] = injectVariables(data[key], variables);
    });
    return data;
  }
  if (typeof data !== `string`) {
    return data;
  }

  let finalStr = data;
  Object.keys(variables).forEach((key) => {
    finalStr = finalStr.replace(new RegExp(`{${key}}`, "g"), variables[key]);
  });
  return finalStr;
};

export const readPageConfig = async (orgConfig, language, fileName) => {
  const pageName = fileName.split(".")[0];
  const userContent = orgConfig.pages;

  // Load default data from source code
  let defaultData = require(`src/organizations/default/locale/${language}/${fileName}`);
  defaultData = injectVariables(defaultData, { companyWebsite: orgConfig.emails.website });

  if (fileName.endsWith(".md"))  {
    const repoStr = (await axios(defaultData)).data;
    orgConfig.pages[pageName] = repoStr;
  }
  else {
    orgConfig.pages[pageName] = Object.assign(defaultData, userContent[pageName]);
  }
};

export const populatePageContent = async (orgConfig) => {
  // locale
  if (!orgConfig.pages) orgConfig.pages = {};
  await Promise.all([
    readPageConfig(orgConfig, "en", "signup.json"),
    readPageConfig(orgConfig, "en", "dashboard.json"),
    readPageConfig(orgConfig, "en", "welcome.json"),
    readPageConfig(orgConfig, "en", "disclaimer.md"),
    readPageConfig(orgConfig, "en", "why.json"),
    readPageConfig(orgConfig, "en", "maintainance.json"),
    readPageConfig(orgConfig, "en", "legal-agreements.json"),
  ]);

  return orgConfig;
};

export const populateOrgAttributes = (orgConfig) => {
  if (orgConfig.tokenSymbol?.slice(0, 1) !== "$") {
    orgConfig.tokenSymbolDisplay = `$${orgConfig.tokenSymbol}`;
  }
  else {
    orgConfig.tokenSymbolDisplay = orgConfig.tokenSymbol;
  }
  orgConfig.explorer = config.explorer[orgConfig.contracts.network || S_CONTRACT_NETWORK.ETHEREUM];
  return orgConfig;
};

export const populateOrgConfig = async (orgConfig) => {
  await populatePageContent(orgConfig);
  populateOrgAttributes(orgConfig);

  // Set window.orgConfig
  window.orgConfig = orgConfig;
  return orgConfig;
};

export const getJurisdiction = (orgConfig, countryCode, email) => {
  if (
    Array.isArray(orgConfig.nonAccreditedInvitations)
    && orgConfig.nonAccreditedInvitations.includes(email)
    && orgConfig.jurisdictions.INVITED
  ) {
    return orgConfig.jurisdictions.INVITED;
  }
  return orgConfig.jurisdictions[countryCode] || orgConfig.jurisdictions.DEFAULT;
};

export const getAuthLevel = (auth) => {
  if (typeof auth.two_factor_address !== "string") {
    return null;
  }
  if (!auth.user) {
    return AUTH_LEVEL.SIGN_IN;
  }
  if (typeof auth.two_factor_enabled !== "boolean") {
    return null;
  }

  if (auth.two_factor_enabled && auth.user.walletAddress !== auth.two_factor_address) {
    return AUTH_LEVEL.TWO_FACTOR;
  }

  if (auth.permissions?.includes(USER_PERMISSION.ADMIN)) {
    return AUTH_LEVEL.ADMIN;
  }
  return AUTH_LEVEL.DASHBOARD;
};

export const getDiffDays = (start, end) => dayjs(end).diff(start, "day");

export const urlify = (text) => {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  return text.replace(urlRegex, url => '<a href="' + url + '" target="_blank" rel="noopener noreferrer">' + url + "</a>");
};

export const generateInvitationCodes = (count) => {
  const codes = [];
  const pattern = "abcdefghijklmnopqrstuvwxyz0123456789";
  while (count--) {
    let length = 5;
    let res = "";
    while (length--) {
      res += pattern.charAt(parseInt(Math.random() * pattern.length, 10));
    }
    codes.push(res);
  }
  return codes;
};

export const handleDownload = (url, filename) => {
  axios.get(url, {
    responseType: "blob",
  }).then((res) => {
    fileDownload(res.data, filename);
  });
};

export const downloadFromNewTab = (url, fileName) => {
  const link = document.createElement("a");
  link.href = url;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const fileNameAndExt = filename => ({
  name : filename.substr(0, filename.lastIndexOf(".")),
  ext  : filename.substr(filename.lastIndexOf("."), filename.length)
});

export const opacityToHex = opacity => Math.floor((255 * opacity) / 100).toString(16).padStart(2, "0");
export const hexToOpacity = hex => Math.ceil((parseInt(hex, 16) / 255) * 100);
export const getOpacityFromHexColor = (color) => {
  if (color.length === 7) return 0;
  return hexToOpacity(color.slice(-2));
};

export const populateStaticFields = (fields, baseInfo) => pick(
  merge(
    fields.reduce((memo, field) => ({
      ...memo,
      [field]: ""
    }), {}),
    baseInfo
  ), fields
);

export const shortenCurrency = amount => new Intl.NumberFormat(`en-US`, {
  style                    : `currency`,
  currency                 : `USD`,
  notation                 : "compact",
  compactDisplay           : "short",
  maximumSignificantDigits : 2
}).format(amount);

export const startsWithSearch = (options, query) => options.filter(opt => opt.text.toLowerCase().startsWith(query.toLowerCase()));

export const trimmedValue = (value) => {
  if (value && typeof value === "object") {
    const newItem = Array.isArray(value) ? [] : {};
    for (const itemKey of Object.keys(value)) {
      newItem[itemKey] = trimmedValue(value[itemKey]);
    }
    return newItem;
  }

  if (typeof value === `string`) {
    return value.trim();
  }
  return value;
};

export const numberWithCommas = x => Intl.NumberFormat("en-US", { maximumFractionDigits: 2 }).format(x || 0);

export const numberWithFixedDecimal = (x, n) => Intl.NumberFormat("en-US", { maximumFractionDigits: n || 2, minimumFractionDigits: n || 2 }).format(x || 0);

export const shortenNumber = value => new Intl.NumberFormat("en-US", {
  notation                 : "compact",
  compactDisplay           : "short",
  maximumSignificantDigits : 3,
}).format(value);

export const getUsername = (user) => {
  if (user.companyInfo?.companyName) {
    return user.companyInfo.companyName;
  }

  if (user.firstName && user.lastName) {
    return `${user.firstName} ${user.lastName}`;
  }

  return user.email;
};

export const getFullName = user => (user.firstName || "") + " " + (user.lastName || "");

export const isValid = value => value !== null && value !== undefined;

export const pickFirst = (...params) => {
  for (let index = 0; index < params.length; index += 1) {
    if (isValid(params[index])) {
      return params[index];
    }
  }
  return null;
};

export const isSetupMode = status => [ORG_STATUS.NEW, ORG_STATUS.SETUP, ORG_STATUS.IN_REVIEW].includes(status);

export const isPendingSetup = status => [ORG_STATUS.NEW, ORG_STATUS.SETUP].includes(status);

export const isDNSGenerated = liveDomainStatus => liveDomainStatus && liveDomainStatus !== LIVE_DOMAIN_STATUS.GENERATE_FAILED;

export const isDemoAccount = (email, orgConfig) => compact([
  config.techSupportEmail,
  orgConfig.contracts.beneficiaryEmail,
  orgConfig.team?.creator,
  orgConfig.team?.firstUser,
])
  .includes(email);

export const shadeColor = (color, percent) => {
  let R = parseInt(color.substring(1, 3), 16);
  let G = parseInt(color.substring(3, 5), 16);
  let B = parseInt(color.substring(5, 7), 16);

  R = parseInt((R * (100 + percent)) / 100, 10);
  G = parseInt((G * (100 + percent)) / 100, 10);
  B = parseInt((B * (100 + percent)) / 100, 10);

  R = (R < 255) ? R : 255;
  G = (G < 255) ? G : 255;
  B = (B < 255) ? B : 255;

  const RR = ((R.toString(16).length === 1) ? "0" + R.toString(16) : R.toString(16));
  const GG = ((G.toString(16).length === 1) ? "0" + G.toString(16) : G.toString(16));
  const BB = ((B.toString(16).length === 1) ? "0" + B.toString(16) : B.toString(16));

  return "#" + RR + GG + BB;
};

export const titleCase = (str) => {
  if (!str) return null;
  const splitStr = str.toLowerCase().split(" ");
  for (let i = 0; i < splitStr.length; i++) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  // Directly return the joined string
  return splitStr.join(" ");
};

export const getValidTokenName = (originTokenName) => {
  let newTokenName = (originTokenName || "").trim();

  while ((newTokenName !== "") && (newTokenName.slice(0, 1) === "$")) {
    newTokenName = newTokenName.slice(1);
  }

  return newTokenName;
};

export const getCompanyFullAddress = (companyInfo) => {
  if (!companyInfo) return "";

  const {
    companyZipCode,
    companyCity,
    companyState,
    companyCountry,
    companyAddress,
  } = companyInfo;

  return `${companyAddress}, ${companyCity}, ${companyState} ${companyZipCode}, ${companyCountry}`;
};
