import React from "react";
import PropTypes from "prop-types";
import { Image } from "semantic-ui-react";

const InitialAvatar = ({
  name,
  color = "fff",
  background = "random",
  format = "svg",
  uppercase = true,
  bold = true,
  rounded = true,
  fontSize = 0.5,
  length = 2,
  size = 40,
}) => {
  const splittedNames = name.split(" ");
  const nameInitials = splittedNames.length > 1 ? splittedNames[0] + "+" + splittedNames[1] : splittedNames[0].slice(0, 1);
  const defaultIcon = `https://ui-avatars.com/api/?name=${nameInitials}&rounded=${rounded}&background=${background}&color=${color}&format=${format}&font-size=${fontSize}&size=${size}&bold=${bold}&length=${length}&uppercase=${uppercase}`;

  return <Image width="40px" height="40px" src={defaultIcon} />;
};

InitialAvatar.propTypes = {
  name       : PropTypes.string.isRequired,
  color      : PropTypes.string,
  background : PropTypes.string,
  format     : PropTypes.string,
  uppercase  : PropTypes.bool,
  bold       : PropTypes.bool,
  rounded    : PropTypes.bool,
  fontSize   : PropTypes.number,
  length     : PropTypes.number,
  size       : PropTypes.number,
};

export default InitialAvatar;
