import { createReducer } from "reduxsauce";
import { Types } from "../actions/admin-nav";

// Initial State
const initialState = {
  pageName : null,
  step     : 0,
  openInfo : false
};

const updatePageName = (state, action) => ({
  ...state,
  pageName: action.pageName,
});

const updateNavStep = (state, action) => ({
  ...state,
  step: action.step || 0
});

const resetNavState = () => ({
  pageName : null,
  step     : 0
});

const setOpenInfo = (state, action) => ({
  ...state,
  openInfo: action.flag
});

// map action types to reducer functions
export const handlers = {
  [Types.UPDATE_PAGE_NAME] : updatePageName,
  [Types.UPDATE_NAV_STEP]  : updateNavStep,
  [Types.RESET_NAV_STATE]  : resetNavState,
  [Types.SET_OPEN_INFO]    : setOpenInfo,
};

// Export Reducer
export default createReducer(initialState, handlers);
