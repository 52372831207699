import { put, call, takeLatest, all } from "redux-saga/effects";

import { requestCreator, successCreator, failureCreator } from "../utils/action";
import { Types } from "../actions/accreditation-link";
import API from "../api/accreditation-link";

function* getUserByLinkId(action) {
  const type = action.type;
  try {
    yield put(requestCreator(type, {}));
    const payload = yield call(API[type], action);
    yield put(successCreator(type, payload));
  }
  catch (err) {
    console.error(err);
    yield put(failureCreator(type, { err, showAlert: false }));
  }
}

function* sagaAction(action) {
  const type = action.type;
  try {
    yield put(requestCreator(type, {}));
    const payload = yield call(API[type], action);
    yield put(successCreator(type, payload));
  }
  catch (err) {
    console.error(err);
    yield put(failureCreator(type, { err }));
  }
}

export function* accreditationLinkSaga() {
  yield all([
    takeLatest(Types.GET_USER_BY_LINK_ID,         getUserByLinkId),
    takeLatest(Types.UPLOAD_DOCUMENT_BY_LINK_ID,  sagaAction),
  ]);
}
