import React, { Component, Suspense, lazy } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@mui/styles";
import { ThemeProvider } from "@mui/material";

import IntlProvider from "src/components/IntlProvider";
import RichLoadingSpinner from "src/components/RichLoadingSpinner";
import { Creators as userCreators } from "src/redux/actions/user";
import { Creators as globalCreators } from "src/redux/actions/global";
import { Creators as adminAllocationCreators } from "src/redux/actions/admin-allocation";

import loadIntercom from "src/utils/intercom";
import Content from "./content";
import themeStyles from "./theme";
import muiTheme from "./mui-theme";

const Pubsub = lazy(() => import("./pubsub"));

class View extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    console.log(error);
    return { hasError: true };
  }

  componentDidMount() {
    const { getConfiguration } = this.props;
    getConfiguration();
    loadIntercom();
  }

  render() {
    const { global, classes } = this.props;
    const { hasError } = this.state;

    // Show loading spinner if orgConfig is not ready
    if (!global.orgConfig) {
      return <RichLoadingSpinner />;
    }

    // Render
    return (
      <>
        <Suspense fallback={<RichLoadingSpinner />}>
          <IntlProvider>
            <ThemeProvider theme={muiTheme}>
              <div className={classes.root + " " + classes[global.orgConfig.id]}>
                <Content
                  global={global}
                  hasError={hasError}
                />
              </div>
            </ThemeProvider>
          </IntlProvider>
        </Suspense>
        <Suspense fallback={null}>
          <Pubsub />
        </Suspense>
      </>
    );
  }
}

const mapStateToProps = store => ({
  global : store.global,
  user   : store.user,
});

const mapDispatchToProps = {
  ...globalCreators,
  ...userCreators,
  ...adminAllocationCreators
};

View.propTypes = {
  global           : PropTypes.object,
  classes          : PropTypes.object,
  getConfiguration : PropTypes.func,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(
    (withStyles(themeStyles)(View))
  )
);
