import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Box, Typography } from "@mui/material";
import { withStyles } from "@mui/styles";
import classNames from "classnames";

import FormattedMessage from "src/components/FormattedMessage";
import Button from "src/mui-components/Button";
import { ROUTE_PATH } from "src/constants";

import { messages } from "./messages";
import "./style.less";
import themeStyles from "./theme";

const UserTopBanner = ({ orgConfig, classes }) => (
  <div className={classNames("user-top-banner", classes.root, classes[orgConfig.id])}>
    <Box className="content-box">
      <Typography><FormattedMessage {...messages.bannerText} /></Typography>
      <Button
        className="admin-view-btn"
        variant="outlined"
        onClick={() => window.open(ROUTE_PATH.ADMIN, "_blank")}
      >
        <FormattedMessage {...messages.goToAdminView} />
      </Button>
    </Box>
  </div>
);

const mapStateToProps = store => ({
  orgConfig: store.global.orgConfig,
});

UserTopBanner.propTypes = {
  orgConfig : PropTypes.object,
  classes   : PropTypes.object,
};

export default connect(mapStateToProps)(withStyles(themeStyles)(UserTopBanner));
