import BigNumber from "bignumber.js";
import { authenticator } from "@otplib/preset-default";
import { createReducer } from "reduxsauce";

import { ORG_STATUS } from "src/constants";
import { Types } from "../actions/user";
import { Types as authTypes } from "../actions/auth";
import { Types as adminRecipientTypes } from "../actions/admin-recipient";
import { Types as adminInvestmentTypes } from "../actions/admin-investment";
import { success } from "../utils/action";

// Initial State
const initialState = Object.freeze({
  data           : null,
  account        : null,
  check          : null,
  sdkToken       : null,
  berbix         : {},
  transactions   : [],
  accreditedData : null,
  legalItems     : [],
  compensations  : [],
  enrollments    : [],
  pools          : [],
  bankInfo       : null,
  kybStep        : 0,
  ipCountry      : null,
});

/* Handlers */
const signOut = () => ({ ...initialState });

const getUserWalletInvestItem = (state, action) => ({
  ...state,
  userItem   : action.payload.userItem,
  walletItem : action.payload.walletItem,
  investItem : action.payload.investItem,
});

const generate2FASecret = state => ({
  ...state,
  twoFactorSecret: authenticator.generateSecret()
});

const getUserWalletItem = (state, action) => ({
  ...state,
  userItem   : action.payload.userItem,
  walletItem : action.payload.walletItem,
});

const setWelcomed = (state, action) => ({
  ...state,
  userItem   : action.payload.userItem,
  walletItem : action.payload.walletItem,
  data       : {
    ...action.payload.userItem,
    ...action.payload.walletItem
  }
});

const activate2FA = state => ({
  ...state,
  walletItem: {
    ...state.walletItem,
    two_factor_enabled: true
  },
  data: {
    ...state.data,
    two_factor_enabled: true
  },
});

const deactivate2FA = state => ({
  ...state,
  walletItem: {
    ...state.walletItem,
    two_factor_enabled: false
  },
  data: {
    ...state.data,
    two_factor_enabled: false
  }
});

const refreshOrgInfo = (state) => {
  if (window.orgConfig.status === ORG_STATUS.LIQUIDATED) {
    window.contracts.data.lastTokenPrice = new BigNumber(window.orgConfig.liquidation_data.token_price);
  }

  return ({
    ...state,
    contractsData: { ...window.contracts.data }
  });
};

const refreshAccountInfo = state => ({
  ...state,
  account: window.contracts?.data?.account
});

const getTransactions = (state, action) => {
  if (state.transactions) {
    action.payload.transactions.forEach((newTx) => {
      const oldTx = state.transactions.find(t => t.txId === newTx.txId);
      if (oldTx) {
        newTx.read = newTx.read || oldTx.read;
      }
    });
  }
  return {
    ...state,
    transactions: action.payload.transactions,
  };
};

const setInvestItem = (state, action) => ({
  ...state,
  investItem: action.payload,
});

const setInvestItemRead = state => ({
  ...state,
  investItem: {
    ...state.investItem,
    read: true
  },
});

const addNewTx = (state, action) => {
  if (!action.payload) {
    return state;
  }
  state.transactions.unshift(action.payload);
  return {
    ...state,
    transactions: state.transactions.map(t => t)
  };
};

const setTransactionRead = (state, action) => {
  const transaction = state.transactions.find(t => t.txId === action.txId);
  transaction.read = true;
  return {
    ...state,
    transactions: state.transactions.map(t => t)
  };
};

const updateTransactions = (state, action) => {
  const { transactions } = state;
  const itemIndex = transactions.findIndex(t => t.txId === action.payload.txId || t.SK === action.payload.SK);
  if (itemIndex === -1) {
    transactions.unshift(action.payload);
  }
  else {
    transactions[itemIndex] = action.payload;
  }
  return {
    ...state,
    transactions: state.transactions.map(t => t)
  };
};

const getBerbixStatus = (state, action) => ({
  ...state,
  berbix: action.payload
});

const sendConfirmationCode = (state, action) => ({
  ...state,
  confirmationId: action.payload.id
});

const getOwnLegalItems = (state, action) => ({
  ...state,
  legalItems: action.payload,
});

const getCompensations = (state, action) => ({
  ...state,
  compensations : action.payload.compensations.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt)),
  enrollments   : action.payload.enrollments.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt)),
  pools         : action.payload.pools
});

const updateCompensation = (state, action) => {
  const compensations = state.compensations.map(e => (e.id === action.payload.compensation.id ? action.payload.compensation : e));
  return {
    ...state,
    compensations
  };
};

const updateEnrollment = (state, action) => {
  const enrollments = state.enrollments.map(e => (e.id === action.payload.enrollment.id ? action.payload.enrollment : e));
  return {
    ...state,
    enrollments
  };
};

const getBankInfo = (state, action) => ({
  ...state,
  bankInfo: action.payload,
});

const getOrgBeneficiary = (state, action) => ({
  ...state,
  orgBeneficiary: action.payload,
});

const getCurrentAllocation = (state, action) => ({
  ...state,
  allocation        : action.payload.allocation || null,
  investmentAllowed : action.payload.investmentAllowed
});

const checkGeoFence = (state, { payload }) => ({
  ...state,
  geoFence: payload.geoFence,
});

const checkIPCountry = (state, action) => ({
  ...state,
  ipCountry: action.payload.country,
});

// map action types to reducer functions
export const handlers = {
  [Types.SET_TRANSACTION_READ]               : setTransactionRead,
  [Types.SET_INVEST_ITEM_READ]               : setInvestItemRead,
  [Types.GENERATE2_FA_SECRET]                : generate2FASecret,
  [success(Types.GET_USER_AND_WALLET)]       : getUserWalletInvestItem,
  [success(Types.REFRESH_USER_AND_WALLET)]   : getUserWalletInvestItem,
  [success(Types.UPDATE_WALLET)]             : getUserWalletItem,
  [success(Types.UPDATE_USER)]               : getUserWalletItem,
  [success(Types.VERIFICATION_FINISHED)]     : getUserWalletItem,
  [success(Types.SET_WELCOMED)]              : setWelcomed,
  [success(Types.ACTIVATE2_FA)]              : activate2FA,
  [success(Types.DEACTIVATE2_FA)]            : deactivate2FA,
  [success(Types.GET_TRANSACTIONS)]          : getTransactions,
  [Types.UPDATE_TRANSACTIONS]                : updateTransactions,
  [success(Types.INITIALIZE_CORG)]           : refreshOrgInfo,
  [success(Types.REFRESH_ORG_INFO)]          : refreshOrgInfo,
  [success(Types.REFRESH_ACCOUNT_INFO)]      : refreshAccountInfo,
  [success(Types.SIGN_AND_BUY)]              : setInvestItem,
  [success(Types.START_BANK_TRANSFER)]       : setInvestItem,
  [success(Types.CANCEL_INVESTMENT)]         : setInvestItem,
  [success(Types.REMOVE_PRICE_PROTECTION)]   : setInvestItem,
  [success(Types.UPDATE_INVEST_ITEM_STATUS)] : setInvestItem,
  [success(Types.BUY_TOKENS)]                : addNewTx,
  [success(Types.WITHDRAW_CURRENCY)]         : addNewTx,
  [success(Types.USER_TRANSFER_TOKEN)]       : addNewTx,
  [success(Types.GET_BERBIX_STATUS)]         : getBerbixStatus,
  [success(Types.SEND_CONFIRMATION_CODE)]    : sendConfirmationCode,
  [success(Types.GET_OWN_LEGAL_ITEMS)]       : getOwnLegalItems,
  [success(Types.GET_COMPENSATIONS)]         : getCompensations,
  [success(Types.SET_INVITED_ENROLL_ACTION)] : updateEnrollment,
  [success(Types.SET_ENROLLMENT_SIGNED)]     : updateEnrollment,
  [success(Types.SET_ENROLLMENT_READ)]       : updateEnrollment,
  [success(Types.SET_COMPENSATION_READ)]     : updateCompensation,
  [success(Types.SET_COMPENSATION_SIGNED)]   : updateCompensation,
  [success(Types.GET_BANK_INFO)]             : getBankInfo,
  [success(Types.GET_CURRENT_ALLOCATION)]    : getCurrentAllocation,
  [success(Types.GET_ORG_BENEFICIARY)]       : getOrgBeneficiary,
  [success(Types.CHECK_GEO_FENCE)]           : checkGeoFence,
  [success(Types.CHECK_IP_COUNTRY)]          : checkIPCountry,

  [success(adminRecipientTypes.SEND_USD_TO_RECIPIENT)] : addNewTx,
  [success(adminRecipientTypes.TRANSFER_TOKEN)]        : addNewTx,
  [success(adminInvestmentTypes.CALL_MANUAL_BUY)]      : addNewTx,
  [authTypes.SIGN_OUT]                                 : signOut,
};

// Export Reducer
export default createReducer(initialState, handlers);
