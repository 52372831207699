import { createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  getAccreditationLogs    : ["email"],
  getEvents               : ["payload"],
  getKYCData              : ["email"],
  searchWallets           : ["payload"],
  updateWallet            : ["email", "payload"],
  updateWalletFunding     : ["email", "payload"],
  getProcessedInvestments : ["email"],
  getUserInvestmentDocUrl : ["investItemSK", "fileName"],
  sendInvestmentPDF       : ["investItemSK"],
  getUserDocSignedUrl     : ["payload"],
  inviteStakeholders      : ["payload"],
  resendInvite            : ["payload"],
  getUserLegalItems       : ["email"],
  getUserCompDocUrl       : ["item", "fileName"],
  getTaxDocUrl            : ["email"],
  selectBeneficialOwner   : ["beneficialOwner"],
}, { prefix: "admin_stakeholder_" });

export { Types, Creators };
