import { NotificationManager } from "react-notifications";
import { put, call, takeLatest, all } from "redux-saga/effects";
import { STEP_ID } from "src/pages/Admin/Home/data";
import { requestCreator, successCreator, failureCreator } from "../utils/action";
import { Types } from "../actions/admin";
import { Creators as globalCreators } from "../actions/global";
import { Creators as adminContentCreators } from "../actions/admin-content";
import { Creators as adminNavCreators } from "../actions/admin-nav";
import API from "../api/admin";

function* sagaAction(action) {
  const type = action.type;
  try {
    yield put(requestCreator(type, {}));
    const payload = yield call(API[type], action);
    yield put(successCreator(type, payload));
  }
  catch (err) {
    console.error(err);
    yield put(failureCreator(type, { err }));
  }
}

function* addPoolAction(action) {
  const type = action.type;
  try {
    yield put(requestCreator(type, {}));
    const payload = yield call(API[type], action);
    yield put(successCreator(type, payload));

    // Created from pools page
    if (action.payload.rules) {
      yield put(adminNavCreators.updateNavStep(0));
      yield put(adminContentCreators.updateOrgOnboarding({
        [STEP_ID.COMPENSATION_POOL] : true,
        [STEP_ID.COMPENSATION_ECA]  : true,
        [STEP_ID.COMPENSATION_SPC]  : true,
      }));
    }
    else {
      yield put(globalCreators.setCurrentDialog(null));
      yield put(adminContentCreators.updateOrgOnboarding({
        [STEP_ID.COMPENSATION_POOL]: true,
      }));
    }
  }
  catch (err) {
    console.error(err);
    yield put(failureCreator(type, { err }));
  }
}

function* updatePoolAction(action) {
  const type = action.type;
  try {
    yield put(requestCreator(type, {}));
    const payload = yield call(API[type], action);
    yield put(successCreator(type, payload));

    // Updated from pools page
    if (action.payload.rules) {
      NotificationManager.success(`The pool was updated`);
      yield put(adminContentCreators.updateOrgOnboarding({
        [STEP_ID.COMPENSATION_POOL] : true,
        [STEP_ID.COMPENSATION_ECA]  : true,
        [STEP_ID.COMPENSATION_SPC]  : true,
      }));
    }
    else {
      yield put(adminContentCreators.updateOrgOnboarding({
        [STEP_ID.COMPENSATION_POOL]: true,
      }));
    }
  }
  catch (err) {
    console.error(err);
    yield put(failureCreator(type, { err }));
  }
}

function* deletePoolAction(action) {
  const type = action.type;
  try {
    yield put(requestCreator(type, {}));
    const payload = yield call(API[type], action);
    if (payload.success) {
      yield put(successCreator(type, action.payload));

      NotificationManager.success(`Successfully deleted!`);
      yield put(globalCreators.setCurrentDialog(null));
    }
  }
  catch (err) {
    console.error(err);
    yield put(failureCreator(type, { err }));
  }
}

export function* adminSaga() {
  yield all([
    takeLatest(Types.GET_INVESTORS,                  sagaAction),
    takeLatest(Types.ADD_POOL,                       addPoolAction),
    takeLatest(Types.UPDATE_POOL,                    updatePoolAction),
    takeLatest(Types.DELETE_POOL,                    deletePoolAction),
    takeLatest(Types.LIST_POOLS,                     sagaAction),
    takeLatest(Types.EXPORT_DATA,                    sagaAction),
    takeLatest(Types.GET_ORG_CONTRACTS,              sagaAction),
    takeLatest(Types.GET_COMPENSATION_BY_POOL,       sagaAction),
    takeLatest(Types.GET_NAV_LEFT_STATUS,            sagaAction),
  ]);
}
