import { createMessages } from "../../lib/createMessages";

const prefix = "Core";

export const messages = createMessages(prefix, {
  errorReload: {
    defaultMessage : "Your investment portal has been updated, please refresh the page to upgrade to the latest version.",
    description    : "Info message for issue - index.js",
  },
  reload: {
    defaultMessage : "Reload",
    description    : "Button text - index.js",
  },
});
