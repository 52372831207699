import { createReducer } from "reduxsauce";
import { Types } from "../actions/admin-notification";
import { success } from "../utils/action";

// Initial State
const initialState = {
  events: [],
};

const getEvents = (state, action) => ({
  ...state,
  events: action.payload.Items
});

// map action types to reducer functions
export const handlers = {
  [success(Types.GET_EVENTS)]: getEvents,
};

// Export Reducer
export default createReducer(initialState, handlers);
