import { callLambda, SERVICE_ADMIN } from "src/utils/lambda";
import { Types } from "../actions/admin-customer";

const apis = {};

apis[Types.GET_CUSTOMERS] = async () => {
  const data = await callLambda({
    method  : "post",
    service : SERVICE_ADMIN,
    url     : "getAllOrgItems"
  });

  return data.sort((c1, c2) => (c2.statistics?.amountInvested || 0) - (c1.statistics?.amountInvested || 0));
};

apis[Types.GET_EVENTS] = action => callLambda({
  method  : "post",
  service : SERVICE_ADMIN,
  url     : `getAdminEvents`,
  body    : action.payload
});

apis[Types.GET_ALLOCATIONS] = action => callLambda({
  method  : "post",
  service : SERVICE_ADMIN,
  url     : `getAllocations`,
  body    : action.payload
});

apis[Types.SEARCH_WALLETS] = search => callLambda({
  method  : "post",
  service : SERVICE_ADMIN,
  url     : "searchWallets",
  body    : search
});

apis[Types.APPROVE_PORTAL_LIVE] = action => callLambda({
  method  : "post",
  service : SERVICE_ADMIN,
  url     : "approvePortalLive",
  body    : action.payload
});

apis[Types.REJECT_PORTAL] = action => callLambda({
  method  : "post",
  service : SERVICE_ADMIN,
  url     : "rejectPortal",
  body    : action.payload
});

apis[Types.DELETE_PORTAL] = action => callLambda({
  method  : "post",
  service : SERVICE_ADMIN,
  url     : "removePortal",
  body    : action.payload
});

export default apis;
