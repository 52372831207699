export default {
  // button's color is editable on admin content page
  root: ({ orgConfig }) => ({
    "&.ui.primary.button": {
      background  : `${orgConfig.theme.primary} !important`,
      borderColor : `${orgConfig.theme.primary} !important`
    },
    "&.ui.primary.basic.button": {
      background : `transparent !important`,
      border     : `2px solid ${orgConfig.theme.primary} !important`,
      boxShadow  : `none !important`,
      color      : `${orgConfig.theme.primary} !important`
    },
    "&.ui.positive.button": {
      background  : `${orgConfig.theme.primary} !important`,
      borderColor : `${orgConfig.theme.primary} !important`,
      color       : "white !important"
    },
    "&.ui.negative.button": {
      background  : "#F5F7FA !important",
      borderColor : "none !important",
      color       : `${orgConfig.theme.primary} !important`
    },
    "&.ui.negative.red.button": {
      background  : "#F5F7FA !important",
      borderColor : "none !important",
      color       : "#F44336 !important"
    },
    "&.ui.basic.red.button": {
      background : "transparent !important",
      border     : `2px solid #F44336 !important`,
      boxShadow  : `none !important`,
      color      : "#F44336 !important"
    },
  })
};
