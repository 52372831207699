import { populateOrgConfig } from "src/utility";
import { callLambda, SERVICE_ADMIN } from "src/utils/lambda";
import { Types } from "../actions/admin-allocation";

const apis = {};

apis[Types.GET_ALLOCATIONS] = ({ payload }) => callLambda({
  method  : "post",
  service : SERVICE_ADMIN,
  url     : "getAllocations",
  body    : payload
});

apis[Types.ADD_ALLOCATION] = async ({ payload }) => {
  const { contracts } = window;
  const { allocationParams } = payload;
  const { endPrice, startPrice, tokenAmount, equityCommitment } = allocationParams;

  const { signature, message } = await contracts.signPermitNewAllocation({
    startPrice,
    endPrice,
    tokenAmount,
    newCommitment: equityCommitment
  });

  const body = {
    allocationParams,
    permits: {
      signature,
      message
    }
  };

  return callLambda({
    method  : "post",
    service : SERVICE_ADMIN,
    url     : "addAllocation",
    body
  });
};

apis[Types.UPDATE_ALLOCATION] = ({ payload }) => callLambda({
  method  : "post",
  service : SERVICE_ADMIN,
  url     : "updateAllocation",
  body    : payload
});

apis[Types.RESET_ALLOCATION] = async () => {
  const { orgConfig } = await callLambda({
    method  : "post",
    service : SERVICE_ADMIN,
    url     : "resetAllocation",
  });
  return { orgConfig: await populateOrgConfig(orgConfig) };
};

apis[Types.UPDATE_ALLOCATION_STATUS] = ({ payload }) => callLambda({
  method  : "post",
  service : SERVICE_ADMIN,
  url     : "updateAllocationStatus",
  body    : payload
});

export default apis;
