import { CUSTOMER_SUPPORT, USER_PERMISSION } from "../constants";

const Messenger = {
  open: null
};

Messenger.loadMessenger = () => null;

Messenger.signIn = (userItem, walletItem, payload) => {
  try {
    // Intercom
    if (payload.customerSupport === CUSTOMER_SUPPORT.INTERCOM) {
      const bootSettings = {
        app_id      : payload.appId,
        user_id     : payload.userId,
        user_hash   : payload.userHash,
        email       : userItem.email,
        is_customer : false
      };
      if (userItem.firstName) {
        bootSettings.name = userItem.firstName + " " + userItem.lastName;
      }
      if (walletItem.permissions?.includes(USER_PERMISSION.ADMIN)) {
        bootSettings.is_customer = true;
        bootSettings.company = {
          id   : window.orgConfig.id,
          name : window.orgConfig.name
        };
      }
      window.Intercom("boot", bootSettings);
      Messenger.open = CUSTOMER_SUPPORT.INTERCOM;
    }
  }
  catch (err) {
    console.log(err);
  }
};

Messenger.startAnonymous = () => {
  try {
    const { customerSupport } = window.orgConfig;

    if (!customerSupport) {
      window.Intercom("shutdown");
      Messenger.open = null;
    }
    else if (customerSupport === CUSTOMER_SUPPORT.INTERCOM) {
      window.Intercom("shutdown");
      window.Intercom("boot", {
        app_id: window.orgConfig.intercomAppId,
      });
      Messenger.open = CUSTOMER_SUPPORT.INTERCOM;
    }
  }
  catch (err) {
    console.log(err);
  }
};

Messenger.showMessenger = (message) => {
  try {
    const { customerSupport } = window.orgConfig;

    if (customerSupport === CUSTOMER_SUPPORT.ERXES) {
      window.Erxes.showMessenger();
    }
    else if (Messenger.open === CUSTOMER_SUPPORT.INTERCOM) {
      window.Intercom("showNewMessage", message);
    }
  }
  catch (err) {
    console.log(err);
  }
};

export default Messenger;
