import { globalSaga } from "./global";
import { authSaga } from "./auth";
import { adminSaga } from "./admin";
import { adminRecipientSaga } from "./admin-recipient";
import { adminContentSaga } from "./admin-content";
import { adminDashboardSaga } from "./admin-dashboard";
import { adminInvestmentSaga } from "./admin-investment";
import { adminStakeholderSaga } from "./admin-stakeholder";
import { adminNotificationSaga } from "./admin-notification";
import { adminCompensationSaga } from "./admin-compensation";
import { adminCustomerSaga } from "./admin-customer";
import { adminEquitySaga } from "./admin-equity";
import { adminOfferingSaga } from "./admin-offering";
import { adminPaymentSaga } from "./admin-payment";
import { adminRiskFactorSaga } from "./admin-risk-factor";
import { adminAllocationSaga } from "./admin-allocation";
import { userSaga } from "./user";
import { offeringSummarySaga } from "./offering-summary";
import { accreditationLinkSaga } from "./accreditation-link";

export default [
  authSaga,
  adminSaga,
  adminRecipientSaga,
  adminContentSaga,
  adminDashboardSaga,
  adminInvestmentSaga,
  adminStakeholderSaga,
  adminNotificationSaga,
  adminCompensationSaga,
  adminCustomerSaga,
  adminEquitySaga,
  adminOfferingSaga,
  adminPaymentSaga,
  adminRiskFactorSaga,
  userSaga,
  globalSaga,
  offeringSummarySaga,
  accreditationLinkSaga,
  adminAllocationSaga,
];
