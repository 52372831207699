import React, { lazy } from "react";
import PropTypes from "prop-types";
import { LoadingSpinner, RichLoadingSpinner, ReloadCard } from "src/components";
import { Types as authTypes } from "src/redux/actions/auth";
import { Types as userTypes } from "src/redux/actions/user";
import { Types as adminTypes } from "src/redux/actions/admin";
import { Types as offeringSummaryTypes } from "src/redux/actions/offering-summary";
import { Types as adminCustomerTypes } from "src/redux/actions/admin-customer";
import { Types as adminRecipientTypes } from "src/redux/actions/admin-recipient";
import { Types as adminContentTypes } from "src/redux/actions/admin-content";
import { Types as adminInvestmentTypes } from "src/redux/actions/admin-investment";
import { Types as adminStakeholderTypes } from "src/redux/actions/admin-stakeholder";
import { Types as adminCompensationTypes } from "src/redux/actions/admin-compensation";
import { Types as adminEquityTypes } from "src/redux/actions/admin-equity";
import { Types as adminNotificationTypes } from "src/redux/actions/admin-notification";
import { Types as adminOfferingTypes } from "src/redux/actions/admin-offering";
import { Types as adminPaymentTypes } from "src/redux/actions/admin-payment";
import { Types as adminRiskFactorTypes } from "src/redux/actions/admin-risk-factor";
import { Types as adminAllocationTypes } from "src/redux/actions/admin-allocation";
import { Types as globalTypes } from "src/redux/actions/global";
import { Types as accreditationLinkTypes } from "src/redux/actions/accreditation-link";
import { ACTION_STATUS, ORG_STATUS } from "src/constants";
import Routes from "./routes";
import DialogWrapper from "./dialog-wrapper";

const Maintainance = lazy(() => import("src/pages/Maintainance"));
const SubscriptionExpiredDialog = lazy(() => import("src/components/SubscriptionExpiredDialog"));

const Content = ({ global, hasError }) => {
  const { status, orgConfig } = global;
  const loadingTypes = [
    authTypes.CHECK_EMAIL,
    authTypes.SIGN_IN,
    authTypes.SIGN_OUT,
    authTypes.CHECK2_FA_CODE,
    userTypes.SET_WELCOMED,
    userTypes.VERIFICATION_FINISHED,
    userTypes.UPDATE_USER,
    userTypes.UPDATE_WALLET,
    userTypes.GET_TRANSACTIONS,
    userTypes.SEND_CONFIRMATION_CODE,
    userTypes.WITHDRAW_CURRENCY,
    userTypes.USER_TRANSFER_TOKEN,
    userTypes.SIGN_AND_BUY,
    userTypes.START_BANK_TRANSFER,
    userTypes.CANCEL_INVESTMENT,
    userTypes.REMOVE_PRICE_PROTECTION,
    userTypes.BUY_TOKENS,
    userTypes.INITIALIZE_CORG,
    userTypes.GET_BERBIX_STATUS,
    userTypes.GET_TOKEN_PRICE_HISTORY,
    userTypes.ACTIVATE2_FA,
    userTypes.DEACTIVATE2_FA,
    userTypes.REQUEST_INVESTMENT_PDF,
    userTypes.UPDATE_INVEST_ITEM_STATUS,
    userTypes.GET_COMPENSATIONS,
    userTypes.SET_INVITED_ENROLL_ACTION,
    userTypes.SET_ENROLLMENT_SIGNED,
    userTypes.SET_ENROLLMENT_READ,
    userTypes.SET_COMPENSATION_READ,
    userTypes.SET_COMPENSATION_SIGNED,
    userTypes.GET_OWN_INVESTMENT_DOC_URL,
    userTypes.GET_OWN_COMP_DOC_URL,
    userTypes.UPDATE_EMAIL,
    userTypes.GET_BANK_INFO,
    userTypes.SEND_KYC_EMAIL_TO_BO,
    userTypes.CHECK_GEO_FENCE,
    userTypes.CHECK_IP_COUNTRY,
    offeringSummaryTypes.GET_TOKEN_PRICE_HISTORY,
    offeringSummaryTypes.GET_RISK_FACTORS,
    adminTypes.GET_INVESTORS,
    adminTypes.EXPORT_DATA,
    adminTypes.LIST_POOL,
    adminTypes.ADD_POOL,
    adminTypes.UPDATE_POOL,
    adminTypes.DELETE_POOL,
    adminTypes.GET_ORG_CONTRACTS,
    adminCustomerTypes.GET_CUSTOMERS,
    adminCustomerTypes.GET_EVENTS,
    adminCustomerTypes.SEARCH_WALLETS,
    adminCustomerTypes.REJECT_PORTAL,
    adminCustomerTypes.DELETE_PORTAL,
    adminRecipientTypes.SEARCH_TRANSACTIONS,
    adminRecipientTypes.LIST_RECIPIENT,
    adminRecipientTypes.ADD_RECIPIENT,
    adminRecipientTypes.UPDATE_RECIPIENT,
    adminRecipientTypes.DELETE_RECIPIENT,
    adminRecipientTypes.SEND_USD_TO_RECIPIENT,
    adminRecipientTypes.TRANSFER_TOKEN,
    adminContentTypes.UPDATE_PAGE_CONTENTS,
    adminContentTypes.UPDATE_ORG_INFO,
    adminContentTypes.UPDATE_ORG_ONBOARDING,
    adminContentTypes.UPDATE_JURISDICTION_INFO,
    adminContentTypes.GET_ADMIN_CONTENTS,
    adminContentTypes.GET_DNS_STATUS,
    adminContentTypes.GO_PORTAL_LIVE,
    adminContentTypes.ACTIVATE_LIVE_DOMAIN,
    adminContentTypes.UPDATE_LIVE_DOMAIN,
    adminInvestmentTypes.CALL_MANUAL_BUY,
    adminInvestmentTypes.SEARCH_INVESTMENTS,
    adminInvestmentTypes.DELETE_INVESTMENT,
    adminInvestmentTypes.CANCEL_INVESTMENT,
    adminInvestmentTypes.MAKE_OFF_CHAIN_INVESTMENT,
    adminStakeholderTypes.GET_EVENTS,
    adminStakeholderTypes.GET_KYC_DATA,
    adminStakeholderTypes.SEARCH_WALLETS,
    adminStakeholderTypes.UPDATE_WALLET,
    adminStakeholderTypes.GET_PROCESSED_INVESTMENTS,
    adminStakeholderTypes.GET_USER_INVESTMENT_DOC_URL,
    adminStakeholderTypes.SEND_INVESTMENT_PDF,
    adminStakeholderTypes.GET_USER_DOC_SIGNED_URL,
    adminStakeholderTypes.INVITE_STAKEHOLDERS,
    adminStakeholderTypes.RESEND_INVITE,
    adminStakeholderTypes.GET_USER_LEGAL_ITEMS,
    adminStakeholderTypes.GET_USER_COMP_DOC_URL,
    adminStakeholderTypes.GET_TAX_DOC_URL,
    adminStakeholderTypes.GET_USER_ENROLLMENTS,
    adminCompensationTypes.GET_COMPENSATION_HTML,
    adminCompensationTypes.GET_ECA_DOC_HTML,
    adminCompensationTypes.UPDATE_POOL_CONTRACT,
    adminCompensationTypes.RESET_COMPENSATION,
    adminCompensationTypes.RESET_ECA,
    adminCompensationTypes.SEARCH_WALLET,
    adminCompensationTypes.SEARCH_COMPENSATIONS,
    adminCompensationTypes.DELETE_COMPENSATION,
    adminCompensationTypes.GENERATE_SPC,
    adminCompensationTypes.GET_COMPENSATION_EVENTS,
    adminEquityTypes.GRANT_POOL,
    adminEquityTypes.SEARCH_WALLET,
    adminEquityTypes.GET_USER_ENROLLMENTS,
    adminEquityTypes.GET_ORG_CONTRACTS,
    adminEquityTypes.LIST_POOLS,
    adminNotificationTypes.GET_EVENTS,
    adminRiskFactorTypes.GET_RISK_FACTORS,
    adminRiskFactorTypes.ADD_CATEGORY,
    adminRiskFactorTypes.UPDATE_CATEGORY,
    adminRiskFactorTypes.DELETE_CATEGORY,
    adminRiskFactorTypes.UPDATE_HEADER,
    adminAllocationTypes.GET_ALLOCATIONS,
    adminAllocationTypes.UPDATE_ALLOCATION,
    adminAllocationTypes.UPDATE_ALLOCATION_STATUS,
    adminAllocationTypes.RESET_ALLOCATION,
    adminOfferingTypes.GET_OFFERING_HTML,
    adminPaymentTypes.PROCESS_PAYMENT,
    accreditationLinkTypes.GET_USER_BY_LINK_ID,
    accreditationLinkTypes.UPLOAD_DOCUMENT_BY_LINK_ID,
    globalTypes.GET_CONFIGURATION,
  ];
  const initialLoadings = [
    authTypes.CHECK_TOKEN,
    authTypes.CHECK2_FA_TOKEN,
    userTypes.GET_USER_AND_WALLET,
  ];

  // Failed to load UI (unknown)
  if (hasError) {
    return <ReloadCard />;
  }

  // Show maintenance UI
  if ([ORG_STATUS.PAUSE, ORG_STATUS.MAINTENANCE].includes(orgConfig.status)) {
    return <Maintainance />;
  }

  if (orgConfig.status === ORG_STATUS.SUBSCRIPTION_EXPIRED) {
    return <SubscriptionExpiredDialog />;
  }

  // Render
  return (
    <>
      <Routes />

      <DialogWrapper global={global} />

      {loadingTypes.map(t => status[t]).includes(ACTION_STATUS.REQUEST)
        && <LoadingSpinner />}
      {initialLoadings.map(t => status[t]).includes(ACTION_STATUS.REQUEST)
        && <RichLoadingSpinner />}
    </>
  );
};

Content.propTypes = {
  global   : PropTypes.object,
  hasError : PropTypes.bool,
};

export default Content;
