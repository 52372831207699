export const TYPE = {
  ADMIN : "admin",
  ALL   : "all"
};

export const SEARCH_BY_OPTION = {
  EMAIL  : "email",
  WALLET : "walletAddress",
  NAME   : "name",
};
