import { callLambda, SERVICE_2, uploadFileToS3ByLinkId } from "src/utils/lambda";
import { Types } from "../actions/accreditation-link";

const apis = {};

apis[Types.GET_USER_BY_LINK_ID] = action => callLambda({
  method   : "post",
  service  : SERVICE_2,
  url      : "getUserByLinkId",
  body     : { linkId: action.payload.linkId },
  unauthed : true
});

apis[Types.UPLOAD_DOCUMENT_BY_LINK_ID] = async (action) => {
  const { document, linkId } = action.payload;

  // Upload File to s3
  const s3FileKey = await uploadFileToS3ByLinkId(
    linkId,
    document.docData,
    document.docData.name,
    document.docData.type
  );

  // Call update user certifiate doc
  return callLambda({
    method  : "post",
    service : SERVICE_2,
    url     : "uploadAccreditationDocByLinkId",
    body    : { linkId, s3FileKey }
  });
};

export default apis;
