import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Button } from "semantic-ui-react";
import { withStyles } from "@mui/styles";
import classNames from "classnames";

import themeStyles from "./theme";

const ThemeButton = ({ orgConfig, classes, className, ...semanticProps }) => (
  <Button
    className={classNames(className, classes.root, classes[orgConfig.id])}
    {...semanticProps}
  />
);

const mapStateToProps = store => ({
  orgConfig: store.global.orgConfig
});

const mapDispatchToProps = {
};

ThemeButton.propTypes = {
  orgConfig : PropTypes.object,
  classes   : PropTypes.object,
  className : PropTypes.string,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(themeStyles)(ThemeButton));
