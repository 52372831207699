import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withStyles } from "@mui/styles";
import classNames from "classnames";

import AvatarSvg from "src/assets/images/avatar.svg";

import "./style.less";
import themeStyles from "./theme";

const Avatar = ({ orgConfig, classes }) => (
  <div className={classNames("avatar", classes.root, classes[orgConfig.id])}>
    <div className="avatar-main">
      <div className="short-username"><img src={AvatarSvg} alt="avatar" /></div>
    </div>
  </div>
);

Avatar.propTypes = {
  orgConfig : PropTypes.object.isRequired,
  classes   : PropTypes.object,
};

const mapStateToProps = store => ({
  orgConfig: store.global.orgConfig
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(themeStyles)(Avatar));
