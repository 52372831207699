import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";

import { AUTH_LEVEL, ROUTE_PATH } from "src/constants";
import { getAuthLevel } from "src/utility";

const Navigator = ({
  auth,
  componentLevel,
  permission,
  isSetupMode,
  children
}) => {
  if (componentLevel === AUTH_LEVEL.FREE) {
    return <>{children}</>;
  }

  const authLevel = getAuthLevel(auth);
  if (!authLevel) {
    return null;
  }

  if (authLevel === AUTH_LEVEL.SIGN_IN) {
    if (componentLevel === AUTH_LEVEL.SIGN_IN) {
      return <>{children}</>;
    }
    if (componentLevel === AUTH_LEVEL.TWO_FACTOR) {
      return <Redirect to={ROUTE_PATH.SIGN_IN} />;
    }
    if (componentLevel === AUTH_LEVEL.DASHBOARD) {
      return <Redirect to={ROUTE_PATH.SIGN_IN} />;
    }
    if (componentLevel === AUTH_LEVEL.ADMIN) {
      return <Redirect to={ROUTE_PATH.SIGN_IN} />;
    }
  }

  if (authLevel === AUTH_LEVEL.TWO_FACTOR) {
    if (componentLevel === AUTH_LEVEL.SIGN_IN) {
      return <Redirect to={ROUTE_PATH.TWO_FACTOR} />;
    }
    if (componentLevel === AUTH_LEVEL.TWO_FACTOR) {
      return <>{children}</>;
    }
    if (componentLevel === AUTH_LEVEL.DASHBOARD) {
      return <Redirect to={ROUTE_PATH.TWO_FACTOR} />;
    }
    if (componentLevel === AUTH_LEVEL.ADMIN) {
      return <Redirect to={ROUTE_PATH.TWO_FACTOR} />;
    }
  }

  if (authLevel === AUTH_LEVEL.DASHBOARD) {
    if (componentLevel === AUTH_LEVEL.SIGN_IN) {
      return <Redirect to={ROUTE_PATH.DASHBOARD} />;
    }
    if (componentLevel === AUTH_LEVEL.TWO_FACTOR) {
      return <Redirect to={ROUTE_PATH.DASHBOARD} />;
    }
    if (componentLevel === AUTH_LEVEL.DASHBOARD) {
      return <>{children}</>;
    }
    if (componentLevel === AUTH_LEVEL.ADMIN) {
      return <Redirect to={ROUTE_PATH.DASHBOARD} />;
    }
  }

  if (authLevel === AUTH_LEVEL.ADMIN) {
    if (componentLevel === AUTH_LEVEL.SIGN_IN) {
      return isSetupMode
        ? <Redirect to={ROUTE_PATH.ADMIN_HOME} />
        : <Redirect to={ROUTE_PATH.ADMIN} />;
    }
    if (componentLevel === AUTH_LEVEL.TWO_FACTOR) {
      return isSetupMode
        ? <Redirect to={ROUTE_PATH.ADMIN_HOME} />
        : <Redirect to={ROUTE_PATH.ADMIN} />;
    }
    if (componentLevel === AUTH_LEVEL.DASHBOARD) {
      return <>{children}</>;
    }
    if (componentLevel === AUTH_LEVEL.ADMIN) {
      if (auth.permissions?.includes(permission)) {
        return <>{children}</>;
      }
      return <Redirect to={ROUTE_PATH.ADMIN} />;
    }
  }

  return null;
};

Navigator.propTypes = {
  auth           : PropTypes.object.isRequired,
  componentLevel : PropTypes.string,
  permission     : PropTypes.string,
  isSetupMode    : PropTypes.bool,
  children       : PropTypes.node.isRequired,
};

const mapStateToProps = store => ({
  auth: store.auth,
});

export default connect(mapStateToProps)(Navigator);
