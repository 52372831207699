import { VERIFICATION_STATUS } from "src/constants";

export const getUserKYBStep = (userItem, walletItem) => {
  if (walletItem.verification_status !== VERIFICATION_STATUS.NEED_KYB) {
    return null;
  }
  if (!userItem.companyInfo?.companyType) return 0;
  if (!userItem.address) return 1;
  if (!userItem.companyInfo?.isBeneficialOwner) return 2;
  if (!userItem.companyInfo?.documents) return 3;
  if (userItem.companyInfo?.country === "US" && userItem.companyInfo?.accreditationOption) {
    return 5;
  }
  return 4;
};

export const isRegSInvestor = (userItem, walletItem) => {
  if (!userItem || !walletItem) return false;
  if (!walletItem.verified_identity) return false;
  const country = userItem.companyInfo ? userItem.companyInfo.country : userItem.countryCode;
  if (!country || country === "US") return false;
  return true;
};
