/**
 * Root Reducer
 */
import { combineReducers } from "redux";

// Import Reducers
import { connectRouter } from "connected-react-router";

import auth from "./auth";
import admin from "./admin";
import adminRecipient from "./admin-recipient";
import adminContent from "./admin-content";
import adminDashboard from "./admin-dashboard";
import adminEquity from "./admin-equity";
import adminInvestment from "./admin-investment";
import adminStakeholder from "./admin-stakeholder";
import adminNav from "./admin-nav";
import adminNotification from "./admin-notification";
import adminCompensation from "./admin-compensation";
import adminCustomer from "./admin-customer";
import adminOffering from "./admin-offering";
import adminPayment from "./admin-payment";
import adminRiskFactor from "./admin-risk-factor";
import adminAllocation from "./admin-allocation";
import user from "./user";
import global from "./global";
import offeringSummary from "./offering-summary";
import accreditationLink from "./accreditation-link";

export default history => combineReducers({
  router: connectRouter(history),
  auth,
  admin,
  adminRecipient,
  adminContent,
  adminDashboard,
  adminEquity,
  adminInvestment,
  adminStakeholder,
  adminNav,
  adminNotification,
  adminCompensation,
  adminCustomer,
  adminOffering,
  adminPayment,
  adminRiskFactor,
  adminAllocation,
  user,
  global,
  offeringSummary,
  accreditationLink
});
