import { callLambda, injectS3Url, SERVICE_ADMIN, SERVICE_ADMIN_2, SERVICE_2 } from "src/utils/lambda";
import { populateOrgConfig, populatePageContent } from "src/utility";
import { Types } from "../actions/admin-content";

const apis = {};

apis[Types.UPDATE_PAGE_CONTENTS] = async ({ payload }) => {
  const { globalData, pages } = payload;
  const newGlobalData = globalData ? await injectS3Url(globalData) : {};
  const newPageData = await injectS3Url(pages);

  // Call update content API
  const { orgConfig, adminContent } = await callLambda({
    method  : "post",
    service : SERVICE_ADMIN,
    url     : "updateContent",
    body    : { globalData: newGlobalData, pages: newPageData }
  });

  return {
    orgConfig    : await populateOrgConfig(orgConfig),
    adminContent : await populatePageContent(adminContent)
  };
};

apis[Types.UPDATE_ORG_INFO] = async ({ globalData }) => {
  // Update images to S3
  globalData = await injectS3Url(globalData);

  // Call update content API
  const { orgConfig, adminContent } = await callLambda({
    method  : "post",
    service : SERVICE_ADMIN,
    url     : "updateContent",
    body    : { globalData }
  });

  return {
    orgConfig    : await populateOrgConfig(orgConfig),
    adminContent : await populatePageContent(adminContent)
  };
};

apis[Types.UPDATE_JURISDICTION_INFO] = async ({ jurisdictions }) => {
  // Call update content API
  const { orgConfig, adminContent } = await callLambda({
    method  : "post",
    service : SERVICE_ADMIN,
    url     : "updateContent",
    body    : {
      globalData: { jurisdictions }
    }
  });

  return {
    orgConfig    : await populateOrgConfig(orgConfig),
    adminContent : await populatePageContent(adminContent)
  };
};

apis[Types.GET_EMAIL_PREVIEW_DATA] = ({ data }) => callLambda({
  method  : "post",
  service : SERVICE_ADMIN,
  url     : "getEmailPreviewData",
  body    : { data }
});

apis[Types.GET_ADMIN_CONTENTS] = async () => {
  const { adminContent } = await callLambda({
    method  : "post",
    service : SERVICE_ADMIN,
    url     : "getAdminContents",
  });

  return { adminContent: await populatePageContent(adminContent) };
};

apis[Types.GET_DNS_STATUS] =  () => callLambda({
  method  : "post",
  service : SERVICE_ADMIN,
  url     : "getDNSStatus",
});

apis[Types.ACTIVATE_LIVE_DOMAIN] = () => callLambda({
  method  : "post",
  service : SERVICE_ADMIN,
  url     : "activateLiveDomain",
});

apis[Types.UPDATE_LIVE_DOMAIN] = async ({ payload }) => {
  const { subDomain, domain } = payload;

  const { orgConfig, adminContent } = await callLambda({
    method  : "post",
    service : SERVICE_ADMIN_2,
    url     : "updateLiveDomain",
    body    : { subDomain, domain }
  });

  return {
    orgConfig    : await populateOrgConfig(orgConfig),
    adminContent : await populatePageContent(adminContent)
  };
};

apis[Types.GO_PORTAL_LIVE] = async ({ payload }) => {
  const { orgConfig } = await callLambda({
    method  : "post",
    service : SERVICE_ADMIN,
    url     : "goPortalLive",
    body    : payload,
  });

  return { orgConfig: await populateOrgConfig(orgConfig) };
};

apis[Types.GET_ORG_RISK_FACTORS] = () => callLambda({
  method  : "post",
  service : SERVICE_2,
  url     : "getRiskFactors",
});

apis[Types.UPDATE_ORG_ONBOARDING] = async ({ onboarding }) => {
  const { adminContent } = await callLambda({
    method  : "post",
    service : SERVICE_ADMIN,
    url     : "updateContent",
    body    : { globalData: { onboarding } }
  });

  return {
    adminContent: await populatePageContent(adminContent)
  };
};

export default apis;
