import { callLambda, SERVICE_ADMIN } from "src/utils/lambda";
import { Types } from "../actions/admin-equity";

const apis = {};

apis[Types.SEARCH_WALLET] = ({ email }) => callLambda({
  method  : "post",
  service : SERVICE_ADMIN,
  url     : "searchWallets",
  body    : { email }
});

apis[Types.GRANT_POOL] = async ({ payload }) => {
  if (!payload.enrolled) {
    await callLambda({
      method  : "post",
      service : SERVICE_ADMIN,
      url     : "enrollStakeholder",
      body    : {
        email      : payload.email,
        poolId     : payload.poolId,
        contractId : payload.spcId || "",
        params     : {
          service_contract: payload.service_contract
        }
      }
    });
  }
  const result = await callLambda({
    method  : "post",
    service : SERVICE_ADMIN,
    url     : "rewardStakeholder",
    body    : {
      email      : payload.email,
      poolId     : payload.poolId,
      contractId : payload.ecaId,
      params     : {
        equity_agreement: payload.equity_agreement
      }
    }
  });
  return result;
};

apis[Types.GET_USER_ENROLLMENTS] = ({ email }) => callLambda({
  method  : "post",
  service : SERVICE_ADMIN,
  url     : "getEnrollmentsByEmail",
  body    : { email }
});

apis[Types.LIST_POOLS] = () => callLambda({
  method  : "post",
  service : SERVICE_ADMIN,
  url     : "listPools",
});

apis[Types.GET_ORG_CONTRACTS] = () => callLambda({
  method  : "post",
  service : SERVICE_ADMIN,
  url     : "getOrgContracts"
});

export default apis;
