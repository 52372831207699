import { createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  searchTransactions : ["payload"],
  addRecipient       : ["payload"],
  updateRecipient    : ["payload"],
  deleteRecipient    : ["payload"],
  listRecipients     : [],
  selectRecipient    : ["payload"],
  sendUSDToRecipient : ["payload"],
  transferToken      : ["payload"],
}, { prefix: "admin_recipient_" });

export { Types, Creators };
