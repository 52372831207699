export default {
  root: ({ orgConfig }) => ({
    "&.app-header": {
      "& .ui.menu .header.item": {
        "& .logo": {
          maxWidth  : "120px",
          width     : "auto",
          height    : "48px",
          objectFit : "contain"
        },
        "&:after": {
          content    : `"| INVEST"`,
          color      : "#3F3F3F",
          marginLeft : "6px"
        }
      },
      "& .ui.menu .right.item, .ui.menu.vertical .item": {
        "& a.item.active, & a.item:hover": {
          background   : `${orgConfig.theme.primary}26` || "#69BBFB",
          borderRadius : "4px"
        }
      },
      "& .ui.menu .ui.dropdown .menu > .item": {
        "&.active, &:hover": {
          background   : `${orgConfig.theme.primary}26 !important` || "#69BBFB !important",
          color        : `${orgConfig.theme.primary} !important`,
          borderRadius : 0
        }
      },
      "& .ui.button": {
        "&.active": {
          background   : `${orgConfig.theme.primary}26` || "#69BBFB",
          borderRadius : "4px"
        }
      }
    }
  }),
  fairmint: () => ({
    "&.app-header .ui.menu .header.item:after": {
      content: `""`,
    }
  }),
};
