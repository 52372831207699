import { createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  updateOrgInfo          : ["globalData", "closeDialog"],
  updateJurisdictionInfo : ["jurisdictions"],
  updatePageContents     : ["payload", "onboarding"],
  getEmailPreviewData    : ["data"],
  getAdminContents       : [],
  getDNSStatus           : [],
  goPortalLive           : ["payload"],
  activateLiveDomain     : [],
  updateLiveDomain       : ["payload"],
  getOrgRiskFactors      : [],
  updateOrgOnboarding    : ["payload", "redirect"],
}, { prefix: "admin_content_" });

export { Types, Creators };
