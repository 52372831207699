import React from "react";

import "./styles.less";

const RichLoadingSpinner = () => (
  <div className="rich-loading-spinner-overlay-div">
    <div className="wrapper">
      <div className="spinner">
        <div className="rect1" />
        <div className="rect2" />
        <div className="rect3" />
        <div className="rect4" />
        <div className="rect5" />
      </div>
      <div className="text-wrapper">
        <p>Making community ownership real</p>
      </div>
    </div>
  </div>
);

export default RichLoadingSpinner;
