import { exportCSV } from "src/utils/csv";
import { callLambda, SERVICE_ADMIN, SERVICE_ADMIN_2 } from "src/utils/lambda";
import { Types } from "../actions/admin";

const apis = {};

apis[Types.GET_INVESTORS] = () => callLambda({
  method  : "post",
  service : SERVICE_ADMIN,
  url     : "getInvestors",
});

apis[Types.ADD_POOL] = action => callLambda({
  method  : "post",
  service : SERVICE_ADMIN,
  url     : "addPool",
  body    : action.payload
});

apis[Types.UPDATE_POOL] = action => callLambda({
  method  : "post",
  service : SERVICE_ADMIN,
  url     : `updatePool/${action.payload.id}`,
  body    : { name: action.payload.name, rules: action.payload.rules }
});

apis[Types.DELETE_POOL] = action => callLambda({
  method  : "post",
  service : SERVICE_ADMIN,
  url     : `deletePool/${action.payload.id}`,
});

apis[Types.LIST_POOLS] = () => callLambda({
  method  : "post",
  service : SERVICE_ADMIN,
  url     : "listPools",
});

apis[Types.EXPORT_DATA] = async (action) => {
  const { data, options } = await callLambda({
    method  : "post",
    service : SERVICE_ADMIN,
    url     : "getExportData",
    body    : { type: action.payload }
  });
  exportCSV(options, data);
};

apis[Types.GET_ORG_CONTRACTS] = () => callLambda({
  method  : "post",
  service : SERVICE_ADMIN,
  url     : "getOrgContracts"
});

apis[Types.GET_COMPENSATION_BY_POOL] = () => callLambda({
  method  : "post",
  service : SERVICE_ADMIN,
  url     : "getCompensationByPool",
});

apis[Types.GET_NAV_LEFT_STATUS] = () => callLambda({
  method  : "post",
  service : SERVICE_ADMIN_2,
  url     : "getNavLeftStatus",
});

export default apis;
