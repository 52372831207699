import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { AppHeader, AppFooter, UserTopBanner } from "src/components";
import { USER_PERMISSION } from "src/constants";

const UserLayout = ({ children, hasFooter, user: { walletItem } }) => (
  <div className="user-layout">
    {walletItem?.permissions?.includes(USER_PERMISSION.ADMIN) && <UserTopBanner />}
    <AppHeader />
    <div className="page-content">{children}</div>
    {hasFooter && <AppFooter />}
  </div>
);

const mapStateToProps = store => ({
  user: store.user,
});

UserLayout.propTypes = {
  children  : PropTypes.node.isRequired,
  hasFooter : PropTypes.bool,
  user      : PropTypes.object,
};

export default connect(mapStateToProps, null)(UserLayout);
