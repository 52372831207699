import { createReducer } from "reduxsauce";
import { Types } from "../actions/admin-risk-factor";
import { success } from "../utils/action";

// Initial State
const initialState = {
  headers    : "",
  categories : [],
};

const getRiskFactors = (state, { payload }) => ({ ...payload });

const updateHeader = (state, { payload: { headers } }) => ({ ...state, headers });

const addCategory = (state, { payload }) => ({
  ...state,
  categories: [...state.categories, payload],
});

const updateCategory = (state, { payload }) => {
  const { categories } = state;
  const index = categories.findIndex(({ id }) => id === payload.id);
  categories[index] = payload;

  return {
    ...state,
    categories: [...categories],
  };
};

const deleteCategory = (state, { payload }) => {
  const { categories } = state;
  const index = categories.findIndex(({ id }) => id === payload);
  categories.splice(index, 1);

  return {
    ...state,
    categories,
  };
};

// map action types to reducer functions
export const handlers = {
  [success(Types.GET_RISK_FACTORS)] : getRiskFactors,
  [success(Types.ADD_CATEGORY)]     : addCategory,
  [success(Types.UPDATE_CATEGORY)]  : updateCategory,
  [success(Types.DELETE_CATEGORY)]  : deleteCategory,
  [success(Types.UPDATE_HEADER)]    : updateHeader,
};

// Export Reducer
export default createReducer(initialState, handlers);
