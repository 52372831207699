import { createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  getRiskFactors : [],
  updateHeader   : ["headers"],
  addCategory    : ["payload"],
  updateCategory : ["payload"],
  deleteCategory : ["id"],
}, { prefix: "admin_risk_factor_" });

export { Types, Creators };
