import { string, number } from "yup";
import { getAddress, isAddress } from "@ethersproject/address";

export const validateNonEmptyString = (str) => {
  const schema = string().trim().min(1).required();
  return schema.isValidSync(str);
};

export const validateEmail = (email) => {
  const schema = string().email().required();
  return schema.isValidSync(email);
};

export const validatePositiveFloat = (numberInput) => {
  const schema = number().positive().required();
  return schema.isValidSync(numberInput);
};

export const validateWalletAddress = walletAddress => isAddress(walletAddress);

export const getChecksumAddress = walletAddress => getAddress(walletAddress);

export const validateURL = (str) => {
  const schema = string().url().required();
  return schema.isValidSync(str);
};

export const validateTokenName = (tokenName) => {
  const pattern = new RegExp("^[A-Za-z0-9]{1,7}$");
  return !!pattern.test(tokenName);
};
