import BigNumber from "bignumber.js";
import { callLambda, SERVICE_2, SERVICE_ADMIN } from "src/utils/lambda";
import { CAFE_CONSTANTS, TX_TYPE } from "src/constants";
import config from "src/config";
import { Types } from "../actions/admin-recipient";

const apis = {};

apis[Types.SEARCH_TRANSACTIONS] = action => callLambda({
  method  : "post",
  service : SERVICE_ADMIN,
  url     : "searchTransactions",
  body    : action.payload
});

apis[Types.ADD_RECIPIENT] = action => callLambda({
  method  : "post",
  service : SERVICE_ADMIN,
  url     : "addRecipient",
  body    : action.payload
});

apis[Types.UPDATE_RECIPIENT] = action => callLambda({
  method  : "post",
  service : SERVICE_ADMIN,
  url     : "updateRecipient",
  body    : action.payload
});

apis[Types.DELETE_RECIPIENT] = async (action) => {
  await callLambda({
    method  : "post",
    service : SERVICE_ADMIN,
    url     : "deleteRecipient",
    body    : { id: action.payload.id }
  });
  return action.payload;
};

apis[Types.LIST_RECIPIENTS] = () => callLambda({
  method  : "post",
  service : SERVICE_ADMIN,
  url     : "listRecipients",
});

apis[Types.SEND_USD_TO_RECIPIENT] = async (action) => {
  const { contracts } = window;
  const { amount, toAddress, toEmail, toName, recipientId } = action.payload;

  const amountValue = new BigNumber(amount)
    .shiftedBy(contracts.data.currency.decimals)
    .dp(0)
    .toFixed();

  const approveSignature = await contracts.signCurrencyPermit(
    config.operatorAddress,
    amountValue,
    CAFE_CONSTANTS.MAX_UINT
  );

  const body = {
    type    : TX_TYPE.WITHDRAW,
    toAddress,
    toEmail,
    toName,
    recipientId,
    byAdmin : true,
    amount,
    amountValue,
    approveSignature,
  };
  const { txItem } = await callLambda({
    method  : "post",
    service : SERVICE_2,
    url     : "sendPermits",
    body,
  });
  return txItem;
};

apis[Types.TRANSFER_TOKEN] = async (action) => {
  const { contracts } = window;
  const { amount, toEmail, tokenPrice } = action.payload;

  const amountValue = new BigNumber(amount)
    .shiftedBy(contracts.data.decimals)
    .dp(0)
    .toFixed();

  const approveSignature = await contracts.signPermit(
    config.operatorAddress,
    amountValue,
    CAFE_CONSTANTS.MAX_UINT
  );

  const body = {
    type: TX_TYPE.TRANSFER_TOKEN,
    toEmail,
    amount,
    amountValue,
    tokenPrice,
    approveSignature,
  };
  const { txItem } = await callLambda({
    method  : "post",
    service : SERVICE_2,
    url     : "sendPermits",
    body,
  });
  return txItem;
};

export default apis;
