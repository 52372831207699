import { put, call, takeLatest, all } from "redux-saga/effects";

import { DIALOG_NAME, POOL_STATUS } from "src/constants";
import { STEP_ID } from "src/pages/Admin/Home/data";
import { requestCreator, successCreator, failureCreator } from "../utils/action";
import API from "../api/admin-compensation";
import { Types } from "../actions/admin-compensation";
import { Creators as globalCreators } from "../actions/global";
import { Creators as adminContentCreators } from "../actions/admin-content";

function* sagaAction(action) {
  const type = action.type;
  try {
    yield put(requestCreator(type, {}));
    const payload = yield call(API[type], action);
    yield put(successCreator(type, payload));

    if (type === Types.RESET_COMPENSATION) {
      yield put(adminContentCreators.updateOrgOnboarding({
        [STEP_ID.COMPENSATION_PLAN]: false,
      }));
    }
  }
  catch (err) {
    console.error(err);
    yield put(failureCreator(type, { err }));
  }
}

function* resetECAAction(action) {
  const type = action.type;

  try {
    yield put(requestCreator(type, {}));
    const payload = yield call(API[type], action);
    yield put(successCreator(type, payload));

    if (action.onboarding) {
      yield put(adminContentCreators.updateOrgOnboarding({
        [STEP_ID.COMPENSATION_ECA]           : false,
        [STEP_ID.COMPENSATION_AGREEMENT_SIG] : false,
      }));
    }
  }
  catch (err) {
    console.error(err);
    yield put(failureCreator(type, { err }));
  }
}

function* generateCompensationPlanAction(action) {
  const type = action.type;
  try {
    yield put(globalCreators.setCurrentDialog(DIALOG_NAME.GeneratingCompensation));

    yield put(requestCreator(type, {}));
    const payload = yield call(API[type], action);
    yield put(successCreator(type, payload));

    yield put(adminContentCreators.updateOrgOnboarding({
      [STEP_ID.COMPENSATION_PLAN]: true,
    }, true));
  }
  catch (err) {
    console.error(err);
    yield put(failureCreator(type, { err }));

    yield put(globalCreators.setCurrentDialog(null));
  }
}

function* generateECAAction(action) {
  const type = action.type;
  try {
    yield put(globalCreators.setCurrentDialog(DIALOG_NAME.GeneratingECADoc));

    yield put(requestCreator(type, {}));
    const payload = yield call(API[type], action);
    yield put(successCreator(type, payload));

    yield put(adminContentCreators.updateOrgOnboarding({
      [STEP_ID.COMPENSATION_ECA]: true,
    }, true));
  }
  catch (err) {
    console.error(err);
    yield put(failureCreator(type, { err }));

    yield put(globalCreators.setCurrentDialog(null));
  }
}

function* generateSPCAction(action) {
  const type = action.type;
  try {
    yield put(requestCreator(type, {}));
    const payload = yield call(API[type], action);
    yield put(successCreator(type, payload));

    yield put(adminContentCreators.updateOrgOnboarding({
      [STEP_ID.COMPENSATION_SPC]: true,
    }, true));
  }
  catch (err) {
    console.error(err);
    yield put(failureCreator(type, { err }));
  }
}

function* updatePoolContractAction(action) {
  const type = action.type;
  try {
    yield put(requestCreator(type, {}));
    const payload = yield call(API[type], action);
    yield put(successCreator(type, payload));

    if (payload.pools.find(o => o.status === POOL_STATUS.LIVE)) {
      yield put(adminContentCreators.updateOrgOnboarding({
        [STEP_ID.COMPENSATION_AGREEMENT_SIG]: true,
      }));
    }
  }
  catch (err) {
    console.error(err);
    yield put(failureCreator(type, { err }));
  }
}

export function* adminCompensationSaga() {
  yield all([
    takeLatest(Types.GET_COMPENSATION_HTML,       sagaAction),
    takeLatest(Types.GET_ECA_DOC_HTML,            sagaAction),
    takeLatest(Types.GET_USER_ENROLLMENTS,        sagaAction),
    takeLatest(Types.GENERATE_COMPENSATION_PLAN,  generateCompensationPlanAction),
    takeLatest(Types.GENERATE_ECA,                generateECAAction),
    takeLatest(Types.GENERATE_SPC,                generateSPCAction),
    takeLatest(Types.UPDATE_POOL_CONTRACT,        updatePoolContractAction),
    takeLatest(Types.RESET_ECA,                   resetECAAction),
    takeLatest(Types.RESET_COMPENSATION,          sagaAction),
    takeLatest(Types.SEARCH_WALLET,               sagaAction),
    takeLatest(Types.SEARCH_COMPENSATIONS,        sagaAction),
    takeLatest(Types.DELETE_COMPENSATION,         sagaAction),
    takeLatest(Types.GET_COMPENSATION_EVENTS,     sagaAction),
  ]);
}
