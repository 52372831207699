import { put, call, takeLatest, all } from "redux-saga/effects";

import { requestCreator, successCreator, failureCreator } from "../utils/action";
import { Types } from "../actions/admin-payment";
import API from "../api/admin-payment";

function* sagaAction(action) {
  const type = action.type;
  try {
    yield put(requestCreator(type, {}));
    const payload = yield call(API[type], action);
    yield put(successCreator(type, payload));
  }
  catch (err) {
    console.error(err);
    yield put(failureCreator(type, { err }));
  }
}

export function* adminPaymentSaga() {
  yield all([
    takeLatest(Types.PROCESS_PAYMENT, sagaAction),
  ]);
}
