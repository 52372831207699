import { createMessages } from "../../lib/createMessages";

const prefix = "components.Header";

export const messages = createMessages(prefix, {
  dashboard: {
    defaultMessage : "Dashboard",
    description    : "Menu text - index.js",
  },
  whyFairmint: {
    defaultMessage : "Why {customer}",
    description    : "Menu text - index.js",
  },
  offeringData: {
    defaultMessage : "Offering Data",
    description    : "Menu text - index.js",
  },
  investNow: {
    defaultMessage : "Invest Now",
    description    : "Menu text - index.js",
  },
  fundAccount: {
    defaultMessage : "Fund my account",
    description    : "Menu text - index.js",
  },
  deactivate2FA: {
    defaultMessage : "Deactivate 2FA",
    description    : "Menu text - index.js",
  },
  secureAccount: {
    defaultMessage : "Secure my account",
    description    : "Menu text - index.js",
  },
  updateMyEmail: {
    defaultMessage : "Update my email",
    description    : "Menu text - index.js",
  },
  diagnostics: {
    defaultMessage : "Diagnostics",
    description    : "Menu text - index.js",
  },
  admin: {
    defaultMessage : "Admin",
    description    : "Menu text - index.js",
  },
  withdraw: {
    defaultMessage : "Withdraw",
    description    : "Menu text - index.js",
  },
  myDocuments: {
    defaultMessage : "My documents",
    description    : "Menu text - index.js",
  },
  transactionHistory: {
    defaultMessage : "Transaction history",
    description    : "Menu text - index.js",
  },
  signOut: {
    defaultMessage : "Sign out",
    description    : "Menu text - index.js",
  },
  profileActions: {
    defaultMessage : "Profile actions",
    description    : "Menu text - index.js",
  },
});
