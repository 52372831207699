import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { isTablet } from "react-device-detect";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import classNames from "classnames";

import { AdminNavLeft, AdminNavTop } from "src/components";
import { Creators as adminContentCreators } from "src/redux/actions/admin-content";
import { Creators as adminNavCreators } from "src/redux/actions/admin-nav";
import { ROUTE_PATH } from "src/constants";
import getPageName from "src/utils/page-name";

import "./style.less";

const AdminLayout = ({ orgConfig, resetNavState, getAdminContents, children }) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const location = useLocation();
  const pageName = getPageName({
    orgConfig,
    path: location.pathname
  });

  useEffect(() => {
    resetNavState();
    getAdminContents();
  }, [location.pathname, getAdminContents, resetNavState]);

  return (
    <>
      <Helmet>
        <title>{`${orgConfig.name} - Admin ${pageName}`}</title>
      </Helmet>
      <div className="admin-layout">
        <div className="page-layout">
          <div className={classNames("admin-nav-left-container", { isTablet, mobileMenuOpen })}>
            <AdminNavLeft mobileOpen={mobileMenuOpen} setMobileMenuOpen={setMobileMenuOpen} />
          </div>
          <div className="main-layout">
            {isTablet && mobileMenuOpen && <div className="tablet-overlay" onClick={() => setMobileMenuOpen(false)} />}
            <div className="scroll-container">
              <div className="admin-nav-top-container">
                <AdminNavTop pageName={pageName} setMobileMenuOpen={setMobileMenuOpen} />
              </div>
              <div className={classNames("page-content", { "full-content": location.pathname === ROUTE_PATH.ADMIN_ANALYTICS, isTablet })}>{children}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = store => ({
  orgConfig: store.global.orgConfig
});

const mapDispatchToProps = {
  ...adminContentCreators,
  ...adminNavCreators
};

AdminLayout.propTypes = {
  orgConfig        : PropTypes.object.isRequired,
  resetNavState    : PropTypes.func.isRequired,
  getAdminContents : PropTypes.func.isRequired,
  children         : PropTypes.node.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminLayout);
