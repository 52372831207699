import { createReducer } from "reduxsauce";
import { TX_TYPE } from "src/constants";
import { Types } from "../actions/admin-recipient";
import { Types as UserTypes } from "../actions/user";
import { success } from "../utils/action";

// Initial State
const initialState = {
  searchedTxs       : {},
  recipients        : [],
  selectedRecipient : {},
};

const searchTransactions = (state, action) => ({
  ...state,
  searchedTxs: action.payload
});

const addRecipient = (state, action) => ({
  ...state,
  recipients: [state.recipients, action.payload.recipient]
});

const updateRecipient = (state, action) => {
  const { recipient } = action.payload;
  const { recipients } = state;
  const index = recipients.findIndex(({ id }) => id === recipient.id);
  recipients[index] = recipient;

  return {
    ...state,
    recipients,
    selectedRecipient: recipient,
  };
};

const deleteRecipient = (state, action) => {
  const { recipients } = state;
  const index = recipients.findIndex(({ id }) => id === action.payload.id);
  recipients.splice(index, 1);

  return {
    ...state,
    recipients,
    selectedRecipient: recipients.length > index
      ? recipients[index]
      : recipients[recipients.length - 1],
  };
};

const listRecipients = (state, action) => ({
  ...state,
  recipients: action.payload.recipients
});

const selectRecipient = (state, action) => ({
  ...state,
  selectedRecipient: action.payload
});

const addNewTx = (state, action) => {
  if (!action.payload
    || !state.selectedRecipient
    || !state.searchedTxs.Items
    || action.payload.toAddress !== state.selectedRecipient.walletAddress
    || action.payload.type !== TX_TYPE.WITHDRAW
  ) {
    return state;
  }
  state.searchedTxs.Items.unshift(action.payload);
  return { ...state };
};

const updateTxs = (state, action) => {
  const { searchedTxs, selectedRecipient } = state;
  if (!action.payload
    || !selectedRecipient
    || !searchedTxs.Items
    || action.payload.toAddress !== selectedRecipient.walletAddress
    || action.payload.type !== TX_TYPE.WITHDRAW
  ) {
    return state;
  }

  const itemIndex = searchedTxs.Items?.findIndex(t => t.txId === action.payload.txId || t.SK === action.payload.SK);
  if (itemIndex === -1) {
    searchedTxs.Items.unshift(action.payload);
  }
  else {
    searchedTxs.Items[itemIndex] = action.payload;
  }

  return { ...state };
};

// map action types to reducer functions
export const handlers = {
  [success(Types.SEARCH_TRANSACTIONS)]   : searchTransactions,
  [success(Types.ADD_RECIPIENT)]         : addRecipient,
  [success(Types.UPDATE_RECIPIENT)]      : updateRecipient,
  [success(Types.DELETE_RECIPIENT)]      : deleteRecipient,
  [success(Types.LIST_RECIPIENTS)]       : listRecipients,
  [success(Types.SEND_USD_TO_RECIPIENT)] : addNewTx,

  [Types.SELECT_RECIPIENT]        : selectRecipient,
  [UserTypes.UPDATE_TRANSACTIONS] : updateTxs,
};

// Export Reducer
export default createReducer(initialState, handlers);
