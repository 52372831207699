import {
  INVESTMENT_STATUS,
  INVESTMENT_TYPE,
  TX_STATUS
} from "src/constants";

const Investment = {};

Investment.isInProgress = investItem => ![
  INVESTMENT_STATUS.PROCESSED,
  INVESTMENT_STATUS.CANCELED,
].includes(investItem.status);

Investment.isFundingNeeded = investItem => [
  INVESTMENT_TYPE.UNDETERMINED,
  INVESTMENT_TYPE.ON_CHAIN,
].includes(investItem.type)
&& [
  INVESTMENT_STATUS.FUNDING_NEEDED,
  INVESTMENT_STATUS.FUNDING_STARTED,
  INVESTMENT_STATUS.FUNDING_NEED_MORE,
].includes(investItem.status);

Investment.getStatusColor = (investItem) => {
  const { status } = investItem;
  if (status === INVESTMENT_STATUS.CANCELED) {
    return { background: "#EEEEEE", color: "#7E7E7E" };
  }
  if (status === INVESTMENT_STATUS.PROCESSED) {
    return { background: "#BDFBE4", color: "#00B875" };
  }

  // In-Progress
  return { background: "#FFF7CC", color: "#DBA400" };
};

Investment.updateFromTransactions = (investItem, transactions) => {
  if (investItem.processed) return;

  const txItem = transactions.find(tx => tx.investItemSK === investItem.SK);
  if (txItem?.status === TX_STATUS.CONFIRMED) {
    investItem.status = INVESTMENT_STATUS.PROCESSED;
    investItem.processed = {
      txId          : txItem.txId,
      amount        : txItem.input.value,
      tokenEstimate : txItem.output.value,
      tokenPrice    : txItem.tokenPrice
    };
  }

  return txItem;
};

export default Investment;
