import {
  createClient, configureChains
} from "@wagmi/core";
import { mainnet, optimism, goerli, optimismGoerli } from "@wagmi/core/chains";
import { alchemyProvider } from "@wagmi/core/providers/alchemy";
import { publicProvider } from "@wagmi/core/providers/public";
import { Magic } from "magic-sdk";
import { Web3Provider } from "@ethersproject/providers";
import { APP_STAGE, S_CONTRACT_NETWORK } from "src/constants";
import config from "src/config";

const prodOptimism = {
  magic: {
    network: {
      rpcUrl: "https://opt-mainnet.g.alchemy.com/v2/Kvh-Gs89fS9U1FrP-qffQxoE66FRZ94e",
    }
  },
  wagmi: {
    network     : optimism,
    alcheApiKey : "Kvh-Gs89fS9U1FrP-qffQxoE66FRZ94e",
  }
};
const prodEth = {
  magic: {
    network: "mainnet"
  },
  wagmi: {
    network     : mainnet,
    alcheApiKey : "vyZ2fkYuioSr8ANNIA3vdN9_V13QzE1h",
  }
};
const devOptimism = {
  magic: {
    network: {
      rpcUrl: "https://opt-goerli.g.alchemy.com/v2/YnNtrZH9SmFewsSP53T9GEcnx3i1U7Xg",
    }
  },
  wagmi: {
    network     : optimismGoerli,
    alcheApiKey : "YnNtrZH9SmFewsSP53T9GEcnx3i1U7Xg",
  }
};
const devEth = {
  magic: {
    network: "goerli"
  },
  wagmi: {
    network     : goerli,
    alcheApiKey : "PEf96lic5wwSeLFwT0S3Px5522S8xMX7",
  }
};

function getOptions(orgConfig) {
  if (config.REACT_APP_STAGE === APP_STAGE.PRODUCTION) {
    if (orgConfig.contracts.network === S_CONTRACT_NETWORK.OPTIMISM) {
      return prodOptimism;
    }
    return prodEth;
  }

  if (orgConfig.contracts.network === S_CONTRACT_NETWORK.OPTIMISM) {
    return devOptimism;
  }
  return devEth;
}

export default function initNetwork(orgConfig) {
  const options = getOptions(orgConfig);
  window.magic = new Magic(orgConfig.magicKey, options.magic);
  window.provider = new Web3Provider(window.magic.rpcProvider);

  const { provider, webSocketProvider } = configureChains(
    [options.wagmi.network],
    [
      alchemyProvider({ apiKey: options.wagmi.alcheApiKey, priority: 1 }),
      publicProvider({ priority: 2 }),
    ]
  );
  createClient({
    provider,
    webSocketProvider,
  });
}
