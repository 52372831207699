import { createReducer } from "reduxsauce";

// Initial State
const initialState = {
};

// map action types to reducer functions
export const handlers = {
};

// Export Reducer
export default createReducer(initialState, handlers);
