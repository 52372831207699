import React from "react";
import PropTypes from "prop-types";
import { Loader } from "semantic-ui-react";

import styles from "./styles.module.less";

const style = {
  margin   : "0px",
  position : "absolute",
  top      : "calc(50% - 24px)",
  left     : "calc(50% - 24px)"
};

const View = ({ text }) => (
  <div className={styles.loadingOverlayDiv}>
    {!text && (
      <div className={styles.loadingCenterWrapper}>
        <Loader active inline="centered" size="large" style={style} />
      </div>
    )}
    {text && (
      <div className={styles.loadingCenterWithText}>
        <Loader active inline="centered" size="large" style={style} />
        <span className="text">{text}</span>
      </div>
    )}
  </div>
);

View.propTypes = {
  text: PropTypes.string
};

export default View;
