import { createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  getConfiguration        : [],
  updateState             : ["payload"],
  setCurrentDialog        : ["name", "content"],
  setInvestDialog         : ["flag"],
  setFundDialog           : ["flag"],
  setVerifyIdentityDialog : ["flag"],
}, { prefix: "global_" });

export { Types, Creators };
