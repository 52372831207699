import { CAFE_CONSTANTS } from "src/constants";
import config from "src/config";

const Contracts = {};

Contracts.init = async (provider, orgConfig) => {
  const {
    CAFEContracts,
    SAFEContracts,
    CAFEContractsDummyData,
  } = await import("@fairmint/safe-js");

  let contracts;
  if (orgConfig.contracts.address === CAFE_CONSTANTS.ZERO_ADDRESS) {
    contracts = new CAFEContractsDummyData();
  }
  else if (orgConfig.communityOffering) {
    contracts = new SAFEContracts(provider, orgConfig.contracts.address, config.mcAddress);
  }
  else {
    contracts = new CAFEContracts(provider, orgConfig.contracts.address);
  }
  await contracts.init();

  // Return
  return contracts;
};

export default Contracts;
