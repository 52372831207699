import { createReducer } from "reduxsauce";
import { DIALOG_NAME } from "src/constants";
import { Types } from "../actions/global";
import { Types as userTypes } from "../actions/user";
import { Types as adminCompensationTypes } from "../actions/admin-compensation";
import { Types as adminContentTypes } from "../actions/admin-content";
import { Types as adminOfferingTypes } from "../actions/admin-offering";
import { Types as adminAllocationTypes } from "../actions/admin-allocation";
import { Types as adminPaymentTypes } from "../actions/admin-payment";
import { success } from "../utils/action";

// Initial State
const initialState = {
  status        : {},
  currentDialog : null,
};

/* Handlers */
const updateState = (state, action) => ({
  ...state,
  ...action.payload,
});

const getConfiguration = (state, action) => ({
  ...state,
  orgConfig: action.payload.orgConfig
});

const setCurrentDialog = (state, action) => {
  const newState = {
    ...state,
    status        : { ...state.status },
    currentDialog : {
      name    : action.name,
      content : action.content
    }
  };

  if (action.name === DIALOG_NAME.Activate2FA) {
    newState.status[userTypes.ACTIVATE2_FA] = null;
  }
  else if (action.name === DIALOG_NAME.Deactivate2FA) {
    newState.status[userTypes.DEACTIVATE2_FA] = null;
  }
  else if (action.name === DIALOG_NAME.Withdrawal) {
    newState.status[userTypes.WITHDRAW_CURRENCY] = null;
  }
  else if (action.name === DIALOG_NAME.UserTransferToken) {
    newState.status[userTypes.USER_TRANSFER_TOKEN] = null;
  }
  else if (action.name === DIALOG_NAME.Payment) {
    newState.status[adminPaymentTypes.PROCESS_PAYMENT] = null;
  }
  return newState;
};

// map action types to reducer functions
export const handlers = {
  [Types.UPDATE_STATE]                                         : updateState,
  [success(Types.GET_CONFIGURATION)]                           : getConfiguration,
  [success(userTypes.GET_USER_AND_WALLET)]                     : getConfiguration,
  [success(adminContentTypes.UPDATE_PAGE_CONTENTS)]            : getConfiguration,
  [success(adminContentTypes.UPDATE_ORG_INFO)]                 : getConfiguration,
  [success(adminContentTypes.UPDATE_JURISDICTION_INFO)]        : getConfiguration,
  [success(adminContentTypes.GO_PORTAL_LIVE)]                  : getConfiguration,
  [success(adminOfferingTypes.GENERATE_OFFERING_PDF)]          : getConfiguration,
  [success(adminCompensationTypes.GENERATE_COMPENSATION_PLAN)] : getConfiguration,
  [success(adminCompensationTypes.RESET_COMPENSATION)]         : getConfiguration,
  [success(adminAllocationTypes.RESET_ALLOCATION)]             : getConfiguration,
  [success(adminPaymentTypes.PROCESS_PAYMENT)]                 : getConfiguration,
  [Types.SET_CURRENT_DIALOG]                                   : setCurrentDialog,
};

// Export Reducer
export default createReducer(initialState, handlers);
