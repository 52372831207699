import { createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  getCustomers      : [],
  getEvents         : ["payload"],
  getAllocations    : ["payload"],
  searchWallets     : ["payload"],
  approvePortalLive : ["payload"],
  rejectPortal      : ["payload"],
  deletePortal      : ["payload"],
}, { prefix: "admin-customer_" });

export { Types, Creators };
