import { createReducer } from "reduxsauce";
import { Types } from "../actions/admin-investment";
import { success } from "../utils/action";

// Initial State
const initialState = {
  investments : [],
  lastItemKey : null
};

const updateInvestments = (state, action) => ({
  ...state,
  investments : action.payload.Items,
  lastItemKey : action.payload.LastEvaluatedKey
});

const deleteInvestment = (state, action) => {
  const { investments } = state;
  const newInvestments = investments.filter(investment => investment.SK !== action.payload.investItemSK);
  return { investments: newInvestments };
};

const updateInvestment = (state, action) => {
  const { investments } = state;
  const { investItem } = action.payload;

  const index = investments.findIndex(investment => investment.SK === investItem.SK);
  investments[index] = investItem;

  return { investments: [...investments] };
};

// map action types to reducer functions
export const handlers = {
  [success(Types.SEARCH_INVESTMENTS)]        : updateInvestments,
  [success(Types.DELETE_INVESTMENT)]         : deleteInvestment,
  [success(Types.CANCEL_INVESTMENT)]         : updateInvestment,
  [success(Types.MAKE_OFF_CHAIN_INVESTMENT)] : updateInvestment
};

// Export Reducer
export default createReducer(initialState, handlers);
