import { createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  grantPool          : ["payload"],
  getOrgContracts    : [],
  getUserEnrollments : ["email"],
  listPools          : [],
  searchWallet       : ["email"],
}, { prefix: "admin_equity_" });

export { Types, Creators };
