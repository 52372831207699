import { createReducer } from "reduxsauce";
import { Types } from "../actions/admin-dashboard";
import { success } from "../utils/action";

// Initial State
const initialState = {
  searchedInvestments  : [],
  searchedWallets      : {},
  events               : [],
  compensations        : [],
  investmentIntentions : {
    totalIntention : 0,
    userCount      : 0,
  }
};

const searchInvestments = (state, action) => ({
  ...state,
  searchedInvestments: action.payload.Items
});

const searchWallets = (state, action) => ({
  ...state,
  searchedWallets: action.payload
});

const getEvents = (state, action) => ({
  ...state,
  events: action.payload.Items
});

const getInvestmentIntentions = (state, { payload }) => ({
  ...state,
  investmentIntentions: payload,
});

const searchCompensations = (state, action) => ({ ...state, compensations: action.payload.Items });

// map action types to reducer functions
export const handlers = {
  [success(Types.SEARCH_INVESTMENTS)]        : searchInvestments,
  [success(Types.SEARCH_WALLETS)]            : searchWallets,
  [success(Types.GET_EVENTS)]                : getEvents,
  [success(Types.GET_INVESTMENT_INTENTIONS)] : getInvestmentIntentions,
  [success(Types.SEARCH_COMPENSATIONS)]      : searchCompensations,
};

// Export Reducer
export default createReducer(initialState, handlers);
