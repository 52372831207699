import { callLambda, SERVICE_ADMIN } from "src/utils/lambda";
import { Types } from "../actions/admin-notification";

const apis = {};

apis[Types.GET_EVENTS] = action => callLambda({
  method  : "post",
  service : SERVICE_ADMIN,
  url     : `getAdminEvents`,
  body    : action.payload
});

export default apis;
