import { createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  updatePageName : ["pageName"],
  updateNavStep  : ["step"],
  resetNavState  : [],
  setOpenInfo    : ["flag"]
}, { prefix: "admin_nav_" });

export { Types, Creators };
