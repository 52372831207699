import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Dialog } from "@mui/material";

const MuiDialog = ({ dispatch, orgConfig, sx, ...props }) => (
  <Dialog
    {...props}
    sx={{
      ".MuiBackdrop-root": {
        backgroundColor: orgConfig.theme.dimmer || "#2020207F",
      },
      ...sx
    }}
  />
);

const mapStateToProps = store => ({
  orgConfig: store.global.orgConfig,
});

MuiDialog.propTypes = {
  dispatch  : PropTypes.func,
  orgConfig : PropTypes.object,
  sx        : PropTypes.object,
};

export default connect(mapStateToProps)(MuiDialog);
