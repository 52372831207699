import { put, call, takeLatest, all } from "redux-saga/effects";
import { requestCreator, successCreator, failureCreator } from "../utils/action";
import { Types } from "../actions/offering-summary";
import API from "../api/offering-summary";

function* sagaAction(action) {
  const type = action.type;
  try {
    yield put(requestCreator(type, {}));
    const payload = yield call(API[type], action);
    yield put(successCreator(type, payload));
  }
  catch (err) {
    console.error(err);
    yield put(failureCreator(type, { err }));
  }
}

function* getTokenPriceHistory(action) {
  const type = action.type;
  try {
    yield put(requestCreator(type, {}));
    const payload = yield call(API[type], action);

    yield put(successCreator(type, { tokenPriceHistory: payload }));
  }
  catch (err) {
    console.error(err);
    yield put(failureCreator(type, { err }));
  }
}

export function* offeringSummarySaga() {
  yield all([
    takeLatest(Types.GET_TOKEN_PRICE_HISTORY,   getTokenPriceHistory),
    takeLatest(Types.GET_RISK_FACTORS,          sagaAction),
  ]);
}
