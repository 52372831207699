/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import { Menu } from "semantic-ui-react";
import { NavLink, useLocation } from "react-router-dom";

import { FileIcon, UpArrowIcon } from "src/assets/icons";
import config from "src/config";
import { ROUTE_PATH, APP_STAGE } from "src/constants";

import "./style.less";

const ContentMenu = ({ orgConfig, disabled }) => {
  let menuItems = useMemo(() => [
    {
      title : "General",
      to    : ROUTE_PATH.ADMIN_CONTENT_GENERAL,
    },
    {
      title : "Signup and Login Page",
      to    : ROUTE_PATH.ADMIN_CONTENT_LOGIN,
    },
    {
      title : "Welcome Flow",
      to    : ROUTE_PATH.ADMIN_CONTENT_WELCOME,
    },
    {
      title : "Dashboard",
      to    : ROUTE_PATH.ADMIN_CONTENT_DASHBOARD,
    },
    {
      title : `Why ${orgConfig?.name}`,
      to    : ROUTE_PATH.ADMIN_CONTENT_WHYPAGE,
    },
    {
      title : "Offering Page",
      to    : ROUTE_PATH.ADMIN_CONTENT_OFFERING,
    },
  ], [orgConfig?.name]);

  const location = useLocation();
  const open = menuItems.map(item => item.to).includes(location.pathname);
  const [expand, setExpand] = useState(open);

  useEffect(() => {
    if (!expand) {
      setExpand(open);
    }
  }, [open]);

  if (config.REACT_APP_STAGE === APP_STAGE.PRODUCTION) {
    menuItems = menuItems.filter(route => !route.devOnly);
  }

  return (
    <div className="content-menu">
      <Menu.Item className="menu-header" onClick={() => setExpand(!expand)}>
        <div className="flex-wrapper">
          <FileIcon />
          Content
        </div>
        <div style={{ transform: !expand && "rotateX(180deg)" }}>
          <UpArrowIcon className="dropdown-icon"  />
        </div>
      </Menu.Item>
      {expand && menuItems.map(menu => (
        <Menu.Item disabled={disabled} key={menu.to} as={NavLink} className="menu-item" to={menu.to}>
          {menu.title}
        </Menu.Item>
      ))}
    </div>
  );
};

ContentMenu.propTypes = {
  orgConfig : PropTypes.object,
  disabled  : PropTypes.bool,
};

export default ContentMenu;
