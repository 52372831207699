import { createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  getCompensationHTML      : ["payload"],
  getECADocHTML            : ["payload"],
  getUserEnrollments       : ["email"],
  generateCompensationPlan : ["payload"],
  generateECA              : ["payload"],
  generateSPC              : ["payload"],
  updatePoolContract       : ["payload"],
  resetCompensation        : [],
  resetECA                 : ["payload", "onboarding"],
  searchWallet             : ["email"],
  searchCompensations      : ["payload"],
  deleteCompensation       : ["id"],
  getCompensationEvents    : ["payload"],
}, { prefix: "admin_compensation_" });

export { Types, Creators };
