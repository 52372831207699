import { createReducer } from "reduxsauce";
import { Types } from "../actions/admin-customer";
import { success } from "../utils/action";

// Initial State
const initialState = {
  customers       : [],
  events          : [],
  allocations     : [],
  searchedWallets : {
    lastItemKey : null,
    wallets     : []
  },
};

const getCustomers = (state, { payload }) => ({
  ...state,
  customers: payload,
});

const getEvents = (state, action) => ({
  ...state,
  events: action.payload.Items
});

const getAllocations = (state, action) => ({
  ...state,
  allocations: action.payload.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
});

const searchWallets = (state, action) => ({
  ...state,
  searchedWallets: action.payload
});

const updateCustomers = (state, { payload }) => ({
  ...state,
  customers: state.customers.map(c => (c.id === payload.orgItem.id ? payload.orgItem : c)),
});

const deleteCustomers = (state, { payload }) => ({
  ...state,
  customers: state.customers.filter(({ id }) => id !== payload.id)
});

// map action types to reducer functions
export const handlers = {
  [success(Types.GET_CUSTOMERS)]       : getCustomers,
  [success(Types.GET_EVENTS)]          : getEvents,
  [success(Types.GET_ALLOCATIONS)]     : getAllocations,
  [success(Types.SEARCH_WALLETS)]      : searchWallets,
  [success(Types.APPROVE_PORTAL_LIVE)] : updateCustomers,
  [success(Types.REJECT_PORTAL)]       : updateCustomers,
  [success(Types.DELETE_PORTAL)]       : deleteCustomers,
};

// Export Reducer
export default createReducer(initialState, handlers);
