export default {
  root: ({ orgConfig }) => ({
    "&.ui.container.grid": {
      margin    : "2em auto",
      borderTop : "solid 1px lightgray",
      padding   : "1em 0 0",
      fontSize  : "11px",

      "& p": {
        color : "#909090",
        "& a" : {
          color: orgConfig.theme.linkColor
        }
      }
    }
  })
};
