import { put, call, takeLatest, all, select } from "redux-saga/effects";
import { NotificationManager } from "react-notifications";
import { push } from "connected-react-router";
import { DIALOG_NAME, DNS_STATUS, ROUTE_PATH } from "src/constants";
import { REDIRECT_PATH, STEP_ID } from "src/pages/Admin/Home/data";

import { requestCreator, successCreator, failureCreator } from "../utils/action";
import { Types, Creators } from "../actions/admin-content";
import { Creators as globalCreators } from "../actions/global";
import API from "../api/admin-content";

function* sagaAction(action) {
  const type = action.type;
  try {
    yield put(requestCreator(type, {}));
    const payload = yield call(API[type], action);
    yield put(successCreator(type, payload));

    if (type === Types.UPDATE_PAGE_CONTENTS && action.onboarding) {
      yield put(Creators.updateOrgOnboarding(action.onboarding));
    }

    if (type === Types.ACTIVATE_LIVE_DOMAIN) {
      yield put(Creators.updateOrgOnboarding({ [STEP_ID.DNS_VERIFICATION]: true }));
    }
    if (type === Types.UPDATE_LIVE_DOMAIN) {
      yield put(Creators.updateOrgOnboarding({ [STEP_ID.DNS_DOMAIN]: true }));
    }
  }
  catch (err) {
    console.error(err);
    yield put(failureCreator(type, { err }));
  }
}

function* updateJurisdiction(action) {
  const { type, jurisdictions } = action;
  try {
    yield put(requestCreator(type, {}));
    const payload = yield call(API[type], action);
    yield put(successCreator(type, payload));

    const country = Object.keys(jurisdictions)[0];
    if (jurisdictions[country].hellosign_agreement) {
      NotificationManager.success(`Your legal document has been successfully saved`);
    }

    if (jurisdictions[country].signature_verified) {
      NotificationManager.success(`Document verified`);

      const { orgConfig } = payload;
      const isVerified = orgConfig.jurisdictions.DEFAULT.signature_verified && orgConfig.jurisdictions.US.signature_verified;
      if (isVerified) {
        yield put(Creators.updateOrgOnboarding({ [STEP_ID.FUNDRAISING_AGREEMENT_SIG]: true }));
      }
    }
  }
  catch (err) {
    console.error(err);
    yield put(failureCreator(type, { err }));
  }
}

function* updateOrgInfo(action) {
  const type = action.type;
  try {
    yield put(requestCreator(type, {}));
    const payload = yield call(API[type], action);
    yield put(successCreator(type, payload));

    if (typeof action.globalData.redirectPublicPage === "boolean") {
      const portalUrl = window.location.origin;
      if (payload.orgConfig.redirectPublicPage) {
        NotificationManager.success(`New users who access ${portalUrl} will be redirected to your public page`);
      }
      else {
        NotificationManager.success(`New users who access ${portalUrl} will be redirected to the sign in / sign up page`);
      }
    }
    else if (action.globalData.entriDone) {
      yield put(globalCreators.setCurrentDialog(DIALOG_NAME.EntriDone));
    }

    if (action.closeDialog) {
      yield put(globalCreators.setCurrentDialog(null));
    }
  }
  catch (err) {
    console.error(err);
    yield put(failureCreator(type, { err }));
  }
}

function* goPortalLive(action) {
  const type = action.type;
  try {
    yield put(requestCreator(type, {}));
    const payload = yield call(API[type], action);
    yield put(successCreator(type, payload));

    yield put(push(ROUTE_PATH.ADMIN));
  }
  catch (err) {
    console.error(err);
    yield put(failureCreator(type, { err }));
  }
}

function* updateOrgOnboarding(action) {
  const { type } = action;
  try {
    const onboarding = action.payload;

    yield put(requestCreator(type, {}));
    const payload = yield call(API[type], { onboarding });
    yield put(successCreator(type, payload));

    if (action.redirect) {
      yield put(globalCreators.setCurrentDialog(null));
      let redirectPath = ROUTE_PATH.ADMIN_HOME;
      if (onboarding[STEP_ID.FIRST_ALLOCATION]) redirectPath += REDIRECT_PATH.ALLOCATION;
      else if (onboarding[STEP_ID.COMPENSATION_PLAN]) redirectPath += REDIRECT_PATH.COMPENSATION_PLAN;
      else if (onboarding[STEP_ID.COMPENSATION_ECA]) redirectPath +=  REDIRECT_PATH.ECA;
      else if (onboarding[STEP_ID.COMPENSATION_SPC]) redirectPath += REDIRECT_PATH.SPC;

      yield put(push(redirectPath));
    }
  }
  catch (err) {
    console.error(err);
    yield put(failureCreator(type, { err }));
  }
}

function* getDNSStatus(action) {
  const { type } = action;
  try {
    const adminContent = yield select(state => state.adminContent);

    yield put(requestCreator(type, {}));
    const payload = yield call(API[type], action);
    yield put(successCreator(type, payload));

    if (payload?.every(({ Status }) => Status === DNS_STATUS.VERIFIED)) {
      yield put(globalCreators.setCurrentDialog(DIALOG_NAME.FinishDomainSetup));
    }
    else if (adminContent.entriDone) {
      yield put(globalCreators.setCurrentDialog(DIALOG_NAME.EntriDone));
    }
    else {
      yield put(globalCreators.setCurrentDialog(DIALOG_NAME.DomainSetup));
    }
  }
  catch (err) {
    console.error(err);
    yield put(failureCreator(type, { err }));
  }
}

export function* adminContentSaga() {
  yield all([
    takeLatest(Types.UPDATE_PAGE_CONTENTS,           sagaAction),
    takeLatest(Types.UPDATE_ORG_INFO,                updateOrgInfo),
    takeLatest(Types.UPDATE_JURISDICTION_INFO,       updateJurisdiction),
    takeLatest(Types.GET_EMAIL_PREVIEW_DATA,         sagaAction),
    takeLatest(Types.GET_ADMIN_CONTENTS,             sagaAction),
    takeLatest(Types.ACTIVATE_LIVE_DOMAIN,           sagaAction),
    takeLatest(Types.UPDATE_LIVE_DOMAIN,             sagaAction),
    takeLatest(Types.GO_PORTAL_LIVE,                 goPortalLive),
    takeLatest(Types.GET_DNS_STATUS,                 getDNSStatus),
    takeLatest(Types.GET_ORG_RISK_FACTORS,           sagaAction),
    takeLatest(Types.UPDATE_ORG_ONBOARDING,          updateOrgOnboarding),
  ]);
}
