import React, { Suspense } from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";

import LoadingSpinner from "src/components/LoadingSpinner";
import { AUTH_LEVEL, ROUTE_PATH } from "src/constants";

import AdminLayout from "./AdminLayout";
import UserLayout from "./UserLayout";

const Layout = ({ routeViews, children }) => {
  const location = useLocation();
  const routeView = routeViews.find(({ path }) => path === location.pathname || `/international${path}` === location.pathname);

  if (location.pathname === ROUTE_PATH.PRESENTATION) {
    return <Suspense fallback={<LoadingSpinner />}>{children}</Suspense>;
  }

  if (routeView?.componentLevel === AUTH_LEVEL.ADMIN) {
    return (
      <AdminLayout>
        <Suspense fallback={<LoadingSpinner />}>{children}</Suspense>
      </AdminLayout>
    );
  }

  if (routeView?.componentLevel === AUTH_LEVEL.DASHBOARD) {
    return (
      <UserLayout hasFooter={routeView.hasFooter}>
        <Suspense fallback={<LoadingSpinner />}>{children}</Suspense>
      </UserLayout>
    );
  }

  return <Suspense fallback={<LoadingSpinner />}>{children}</Suspense>;
};

Layout.propTypes = {
  routeViews : PropTypes.array.isRequired,
  children   : PropTypes.node.isRequired,
};

export default Layout;
