import BigNumber from "bignumber.js";
import {
  TX_TYPE, ADMIN_INVESTMENT_ACTION, CAFE_CONSTANTS
} from "src/constants";
import { callLambda, SERVICE_2, SERVICE_ADMIN } from "src/utils/lambda";
import { Types } from "../actions/admin-investment";

const apis = {};

apis[Types.SEARCH_INVESTMENTS] = (search) => {
  const params = {
    type      : search.filterType,
    status    : search.filterStatus,
    pageLimit : search.pageLimit,
  };

  if (search.ExclusiveStartKey && search.isMore) {
    params.ExclusiveStartKey = search.ExclusiveStartKey;
  }

  return callLambda({
    method  : "post",
    service : SERVICE_ADMIN,
    url     : "searchInvestments",
    body    : params
  });
};

apis[Types.DELETE_INVESTMENT] = action => callLambda({
  method  : "post",
  service : SERVICE_ADMIN,
  url     : "updateInvestment",
  body    : { investItemSK: action.investItemSK, action: ADMIN_INVESTMENT_ACTION.DELETE }
});

apis[Types.CANCEL_INVESTMENT] = action => callLambda({
  method  : "post",
  service : SERVICE_ADMIN,
  url     : "updateInvestment",
  body    : { investItemSK: action.investItemSK, action: ADMIN_INVESTMENT_ACTION.CANCEL }
});

apis[Types.MAKE_OFF_CHAIN_INVESTMENT] = action => callLambda({
  method  : "post",
  service : SERVICE_ADMIN,
  url     : "updateInvestment",
  body    : { investItemSK: action.investItemSK, action: ADMIN_INVESTMENT_ACTION.MAKE_OFF_CHAIN }
});

apis[Types.CALL_MANUAL_BUY] = async ({ amount, fundsReceptionDate, investItem }) => {
  const { contracts, orgConfig } = window;

  // Get token estimate
  const tokenEstimate = await contracts.estimateBuyValue(amount);
  if (!tokenEstimate || tokenEstimate.eq(0)) {
    throw new Error("0 expected value");
  }

  // Check price protection
  const tokenPrice = Number(amount) / tokenEstimate.toNumber();
  if (
    investItem.priceProtection
    && !investItem.priceProtection.removedByInvestor
    && tokenPrice > investItem.priceProtection.price
  ) {
    throw new Error("Price Protection Activated");
  }

  // Get manualBuy method
  const amountValue = new BigNumber(amount)
    .shiftedBy(contracts.data.currency.decimals)
    .dp(0)
    .toFixed();
  const signature = await contracts.signManualBuyPermit(
    investItem.walletAddress,
    amountValue,
    CAFE_CONSTANTS.MAX_UINT
  );

  // Add a new tx item
  const manualBuyTxItem = {
    type         : TX_TYPE.MANUAL_BUY,
    input        : { value: Number(amount),           currency: orgConfig.currencySymbol },
    output       : { value: tokenEstimate.toNumber(), currency: orgConfig.tokenSymbol },
    toAddress    : investItem.walletAddress,
    toEmail      : investItem.email,
    tokenPrice,
    fundsReceptionDate,
    investItemSK : investItem.SK,
  };

  // Send permits
  const body = {
    type    : TX_TYPE.MANUAL_BUY,
    manualBuyTxItem,
    toEmail : investItem.email,
    data    : {
      to     : investItem.walletAddress,
      amount : Number(amount),
      amountValue,
      signature,
    }
  };
  const { txItem } = await callLambda({
    method  : "post",
    service : SERVICE_2,
    url     : "sendPermits",
    body,
  });
  return txItem;
};

export default apis;
