import axios from "axios";

import config from "src/config";

export const SERVICE_1 = "service1";
export const SERVICE_2 = "service2";
export const SERVICE_ADMIN = "admin";
export const SERVICE_ADMIN_2 = "admin2";

export const callLambda = async ({ method, service, url, body = {}, queryStringParameters = {}, unauthed = false }) => {
  const accessToken = window.localStorage.getItem("access_token");
  const headers = unauthed ? {} : { Authorization: accessToken };
  const { data } = await axios({
    url    : `${config.apiEndpoint}/${service}/${url}`,
    method,
    headers,
    params : {
      ...queryStringParameters,
      domain : config.domain,
      t      : Date.now()
    },
    data: body,
  });
  return data;
};

export const fetchAllResult = async ({ service, url }) => {
  const result = [];
  let lastItemKey;

  do {
    const response = await callLambda({
      method : "POST",
      service,
      url,
      body   : {
        pageLimit         : 10000,
        ExclusiveStartKey : lastItemKey
      }
    });

    result.push(...response.Items);
    lastItemKey = response.LastEvaluatedKey;
  } while (lastItemKey);

  return result;
};

export const uploadFileToS3 = async (data, filename, type = "image/png") => {
  const { signedUrl, fileUrl } = await callLambda({
    method  : "post",
    service : SERVICE_ADMIN,
    url     : "getPreSignedURL",
    body    : { contentType: type, key: filename }
  });
  await axios({
    data,
    method  : "PUT",
    url     : signedUrl,
    headers : { "Content-Type": type }
  });
  return fileUrl;
};

export const uploadFileToPrivateS3 = async (data, filename, type = "image/png") => {
  const { signedUrl, s3FileKey } = await callLambda({
    method  : "post",
    service : SERVICE_2,
    url     : "getUserPreSignedURL",
    body    : { contentType: type, key: filename }
  });

  await axios({
    data,
    method  : "PUT",
    url     : signedUrl,
    headers : { "Content-Type": type }
  });

  return s3FileKey;
};

export const uploadFileToS3ByLinkId = async (linkId, data, filename, type = "application/pdf") => {
  const { signedUrl, s3FileKey } = await callLambda({
    method  : "post",
    service : SERVICE_2,
    url     : "getPreSignedURLByLinkId",
    body    : { contentType: type, key: filename, linkId }
  });

  await axios({
    data,
    method  : "PUT",
    url     : signedUrl,
    headers : { "Content-Type": type }
  });

  return s3FileKey;
};

export const injectS3Url = async (obj) => {
  if (obj instanceof Blob) {
    return uploadFileToS3(obj, `${Date.now()}/${obj.name}`, obj.type);
  }
  if (obj && typeof obj === "object") {
    const newItem = Array.isArray(obj) ? [] : {};
    const promises = [];
    for (const itemKey of Object.keys(obj)) {
      promises.push(injectS3Url(obj[itemKey]).then((url) => {
        newItem[itemKey] = url;
      }));
    }
    await Promise.all(promises);
    return newItem;
  }
  return obj;
};
