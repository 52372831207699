import { put, call, takeLatest, all, select } from "redux-saga/effects";

import Messenger from "src/utils/messenger";
import { callLambda, SERVICE_1 } from "src/utils/lambda";
import { ROUTE_PATH } from "src/constants";

import { requestCreator, successCreator, failureCreator } from "../utils/action";
import { Types, Creators } from "../actions/auth";
import { Creators as userCreators } from "../actions/user";
import API from "../api/auth";

function* sagaAction(action) {
  const type = action.type;
  try {
    yield put(requestCreator(type, {}));
    const payload = yield call(API[type], action);
    yield put(successCreator(type, payload));
  }
  catch (err) {
    yield put(failureCreator(type, { err, logError: false }));
  }
}

function* signOut(action) {
  window.localStorage.removeItem("access_token");
  const auth = yield select(state => state.auth);
  if (!auth.two_factor_permanent) {
    window.localStorage.removeItem("two_factor_token");
  }

  const type = action.type;
  try {
    yield put(requestCreator(type, {}));
    const payload = yield call(API[type], action);
    yield put(successCreator(type, payload));

    Messenger.startAnonymous();
  }
  catch (err) {
    yield put(failureCreator(type, { err, logError: false }));
  }
}

function* check2FAToken(action) {
  const type = action.type;
  try {
    yield put(requestCreator(type, {}));
    const payload = yield call(API[type], action);
    yield put(successCreator(type, payload));
  }
  catch (err) {
    yield put(failureCreator(type, { err, logError: false, showAlert: false }));
  }
}

function* checkEmail(action) {
  const type = action.type;
  try {
    yield put(requestCreator(type, {}));
    const payload = yield call(API[type], action);
    yield put(successCreator(type, payload));
    if (payload.exists) {
      yield put(Creators.signIn(action.email));
    }
  }
  catch (err) {
    yield put(failureCreator(type, { err, logError: false }));
  }
}

function* signIn(action) {
  const type = action.type;
  try {
    yield put(requestCreator(type, {}));
    const payload = yield call(API[type], action);
    yield put(successCreator(type, payload));

    yield put(Creators.checkToken(action.code));
  }
  catch (err) {
    yield put(failureCreator(type, { err, logError: false }));
  }
}

function* checkDiscourseSSO() {
  if (window.location.pathname !== ROUTE_PATH.SIGN_IN_DISCOURSE) {
    return;
  }

  console.log("AUTH: Discourse SSO");
  const queryString = new URLSearchParams(window.location.search);
  const sso = queryString.get("sso");
  const sig = queryString.get("sig");
  if (sso && sig) {
    try {
      const { redirectURL } = yield call(callLambda, {
        method  : "post",
        service : SERVICE_1,
        url     : "discourseSSO",
        body    : { sso, sig },
      });
      window.location = redirectURL;
    }
    catch (error) {
      console.log("AUTH: Invalid Discourse SSO & SIG");
    }
  }
}

function* checkToken(action) {
  const type = action.type;
  try {
    yield put(requestCreator(type, {}));
    const payload = yield call(API[type], action);
    window.localStorage.setItem("access_token", payload.accessToken);
    window.localStorage.setItem("isOldUser", true);

    yield call(checkDiscourseSSO);

    yield put(successCreator(type, payload));

    yield put(Creators.check2FAToken(payload.walletAddress));
    yield put(userCreators.getTransactions());
    yield put(userCreators.getUserAndWallet(payload.invitationCode));
    yield put(userCreators.getCompensations());
    yield put(userCreators.getCurrentAllocation());
    yield put(userCreators.generate2FASecret());
    yield put(userCreators.initializeCorg(payload.walletAddress));
  }
  catch (err) {
    window.localStorage.removeItem("access_token");
    yield put(failureCreator(type, { err, logError: false, showAlert: false }));
    yield put(Creators.check2FAToken(null));

    Messenger.startAnonymous();
  }
}

export function* authSaga() {
  yield all([
    takeLatest(Types.CHECK_TOKEN,     checkToken),
    takeLatest(Types.CHECK_EMAIL,     checkEmail),
    takeLatest(Types.SIGN_IN,         signIn),
    takeLatest(Types.SIGN_OUT,        signOut),
    takeLatest(Types.CHECK2_FA_TOKEN, check2FAToken),
    takeLatest(Types.CHECK2_FA_CODE,  sagaAction),
    takeLatest(Types.CHECK_INVITATION_CODE,      sagaAction),
  ]);
}
