import { populateOrgConfig, populatePageContent } from "src/utility";
import { callLambda, SERVICE_ADMIN_2 } from "src/utils/lambda";
import { Types } from "../actions/admin-payment";

const apis = {};

apis[Types.PROCESS_PAYMENT] = async (action) => {
  const { orgConfig, adminContent } = await callLambda({
    method  : "post",
    service : SERVICE_ADMIN_2,
    url     : "processPayment",
    body    : action.payload
  });

  return {
    orgConfig    : await populateOrgConfig(orgConfig),
    adminContent : await populatePageContent(adminContent)
  };
};

export default apis;
