import { populateOrgConfig, populatePageContent } from "src/utility";
import { callLambda, SERVICE_ADMIN, SERVICE_ADMIN_2 } from "src/utils/lambda";
import { Types } from "../actions/admin-compensation";

const apis = {};

apis[Types.GET_COMPENSATION_HTML] = ({ payload }) => callLambda({
  method  : "post",
  service : SERVICE_ADMIN,
  url     : "getSendgridHTML",
  body    : { type: "compensation-plan", value: payload }
});

apis[Types.GET_ECA_DOC_HTML] = ({ payload }) => callLambda({
  method  : "post",
  service : SERVICE_ADMIN,
  url     : "getSendgridHTML",
  body    : { type: "eca", value: payload.type }
});

apis[Types.GENERATE_COMPENSATION_PLAN] = async (action) => {
  const { orgConfig, adminContent } = await callLambda({
    method  : "post",
    service : SERVICE_ADMIN_2,
    url     : "generateCompensationPlan",
    body    : action.payload
  });
  return {
    orgConfig    : await populateOrgConfig(orgConfig),
    adminContent : await populatePageContent(adminContent)
  };
};

apis[Types.GENERATE_ECA] = ({ payload }) => callLambda({
  method  : "post",
  service : SERVICE_ADMIN_2,
  url     : "generateECA",
  body    : payload
});

apis[Types.GENERATE_SPC] = ({ payload }) => callLambda({
  method  : "post",
  service : SERVICE_ADMIN_2,
  url     : "generateSPC",
  body    : payload
});

apis[Types.RESET_COMPENSATION] = async ({ payload }) => {
  const { orgConfig } = await callLambda({
    method  : "post",
    service : SERVICE_ADMIN_2,
    url     : "resetCompensation",
    body    : payload
  });

  return { orgConfig: await populateOrgConfig(orgConfig) };
};

apis[Types.RESET_ECA] =  ({ payload }) => callLambda({
  method  : "post",
  service : SERVICE_ADMIN_2,
  url     : "resetECA",
  body    : payload
});

apis[Types.UPDATE_POOL_CONTRACT] =  ({ payload }) => callLambda({
  method  : "post",
  service : SERVICE_ADMIN_2,
  url     : "updatePoolContract",
  body    : payload
});

apis[Types.SEARCH_WALLET] = ({ email }) => callLambda({
  method  : "post",
  service : SERVICE_ADMIN,
  url     : "searchWallets",
  body    : { email }
});

apis[Types.SEARCH_COMPENSATIONS] = ({ payload }) => callLambda({
  method  : "post",
  service : SERVICE_ADMIN,
  url     : "searchCompensations",
  body    : payload
});

apis[Types.DELETE_COMPENSATION] = ({ id }) => callLambda({
  method  : "post",
  service : SERVICE_ADMIN,
  url     : "deleteCompensation",
  body    : { id }
});

apis[Types.GET_USER_ENROLLMENTS] = ({ email }) => callLambda({
  method  : "post",
  service : SERVICE_ADMIN,
  url     : "getEnrollmentsByEmail",
  body    : { email }
});

apis[Types.GET_COMPENSATION_EVENTS] = ({ payload }) => callLambda({
  method  : "post",
  service : SERVICE_ADMIN_2,
  url     : "getCompensationEvents",
  body    : payload
});

export default apis;
