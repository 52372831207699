import { createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  searchInvestments      : ["searchParams"],
  callManualBuy          : ["amount", "fundsReceptionDate", "investItem"],
  deleteInvestment       : ["investItemSK"],
  cancelInvestment       : ["investItemSK"],
  makeOffChainInvestment : ["investItemSK"],
}, { prefix: "admin_investment_" });

export { Types, Creators };
