export default {
  root: ({ orgConfig }) => ({
    "&.admin-nav-top": {
      "& .badge-wrapper .badge-outer": {
        background: orgConfig.theme.primary || "#69BBFB"
      },
      "& .badge-wrapper .badge-inner": {
        background: orgConfig.theme.primary || "#69BBFB"
      }
    }
  }),
};
