import { createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  getInvestors          : [],
  addPool               : ["payload"],
  updatePool            : ["payload"],
  deletePool            : ["payload"],
  listPools             : [],
  exportData            : ["payload"],
  callConvertToCafe     : ["commitment", "amount", "cafeAmount", "investor"],
  getOrgContracts       : [],
  getCompensationByPool : [],
  getNavLeftStatus      : []
}, { prefix: "admin_" });

export { Types, Creators };
