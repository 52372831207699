import { createReducer } from "reduxsauce";
import { Types } from "../actions/admin-stakeholder";
import { request, success } from "../utils/action";

// Initial State
const initialState = {
  accreditationLogs : [],
  events            : [],
  kycData           : {},
  searchedWallets   : {
    lastItemKey : null,
    wallets     : []
  },
  updateWalletResponse    : {},
  legalItems              : [],
  selectedBeneficialOwner : null,
};

const getAccreditationLogs = (state, action) => ({
  ...state,
  accreditationLogs: action.payload.Items
});

const getEvents = (state, action) => ({
  ...state,
  events: action.payload.Items
});

const getKYCData = (state, action) => ({
  ...state,
  kycData: action.payload
});

const searchWallets = (state, action) => ({
  ...state,
  searchedWallets: action.payload
});

const searchWalletsRequest = (state, action) => ({
  ...state,
  searchedWallets: {
    ...state.searchedWallets,
    wallets: action.payload ? state.searchedWallets.wallets : []
  }
});

const updateWallet = (state, action) => ({
  ...state,
  updateWalletResponse : action.payload,
  searchedWallets      : {
    ...state.searchedWallets,
    wallets: state.searchedWallets.wallets.map(item => (
      item.walletAddress === action.payload.walletAddress ? action.payload : item
    ))
  }
});

const updateProcessedInvestment = (state, action) => {
  const { legalItems } = state;
  const { investItem } = action.payload;

  const index = legalItems.findIndex(item => item.PK === investItem.PK && item.SK === investItem.SK);
  legalItems[index] = investItem;

  return { ...state, legalItems: [...legalItems] };
};

const getUserLegalItems = (state, action) => {
  const legalItems = action.payload;

  return { ...state, legalItems };
};

const initUserLegalItems = state => ({
  ...state,
  legalItems: []
});

const selectBeneficialOwner = (state, action) => ({
  ...state,
  selectedBeneficialOwner: action.beneficialOwner
});

// map action types to reducer functions
export const handlers = {
  [success(Types.GET_ACCREDITATION_LOGS)]      : getAccreditationLogs,
  [success(Types.GET_EVENTS)]                  : getEvents,
  [success(Types.GET_KYC_DATA)]                : getKYCData,
  [request(Types.SEARCH_WALLETS)]              : searchWalletsRequest,
  [success(Types.SEARCH_WALLETS)]              : searchWallets,
  [success(Types.UPDATE_WALLET)]               : updateWallet,
  [success(Types.UPDATE_WALLET_FUNDING)]       : updateWallet,
  [success(Types.GET_USER_INVESTMENT_DOC_URL)] : updateProcessedInvestment,
  [success(Types.SEND_INVESTMENT_PDF)]         : updateProcessedInvestment,
  [request(Types.GET_USER_LEGAL_ITEMS)]        : initUserLegalItems,
  [success(Types.GET_USER_LEGAL_ITEMS)]        : getUserLegalItems,
  [Types.SELECT_BENEFICIAL_OWNER]              : selectBeneficialOwner,
};

// Export Reducer
export default createReducer(initialState, handlers);
