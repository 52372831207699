import { put, call, takeLatest, all } from "redux-saga/effects";

import { DIALOG_NAME } from "src/constants";
import { STEP_ID } from "src/pages/Admin/Home/data";
import { requestCreator, successCreator, failureCreator } from "../utils/action";
import { Types, Creators } from "../actions/admin-offering";
import { Creators as globalCreators } from "../actions/global";
import { Creators as userCreators } from "../actions/user";
import { Creators as adminContentCreators } from "../actions/admin-content";

import API from "../api/admin-offering";

function* sagaAction(action) {
  const type = action.type;
  try {
    yield put(requestCreator(type, {}));
    const payload = yield call(API[type], action);
    yield put(successCreator(type, payload));
  }
  catch (err) {
    console.error(err);
    yield put(failureCreator(type, { err }));
  }
}

function* generateOfferingPDFAction(action) {
  const type = action.type;
  try {
    const { allocationParams } = action.payload;
    yield put(globalCreators.setCurrentDialog(DIALOG_NAME.GeneratingAllocation, allocationParams));

    yield put(requestCreator(type, {}));
    const payload = yield call(API[type], action);
    yield put(successCreator(type, payload));

    yield put(Creators.setPdfGeneratedBanner(true));
    yield put(userCreators.getCurrentAllocation());

    yield put(adminContentCreators.updateOrgOnboarding({
      [STEP_ID.FIRST_ALLOCATION] : true,
      [STEP_ID.DNS_DOMAIN]       : true
    }, true));
  }
  catch (err) {
    console.error(err);
    yield put(failureCreator(type, { err }));

    yield put(globalCreators.setCurrentDialog(null));
  }
}

export function* adminOfferingSaga() {
  yield all([
    takeLatest(Types.GET_OFFERING_HTML,     sagaAction),
    takeLatest(Types.GENERATE_OFFERING_PDF, generateOfferingPDFAction),
  ]);
}
