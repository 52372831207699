import { NotificationManager } from "react-notifications";
import { put, call, select, takeLatest, all } from "redux-saga/effects";
import { DIALOG_NAME } from "src/constants";
import { handleDownload } from "src/utility";
import {
  FILTER_BY_OPTION,
  WALLET_SK2_PREFIX,
  WALLET_GSI6SK_PREFIX,
  SORT_BY_OPTION,
  SEARCH_BY_OPTION
} from "src/pages/Admin/Stakeholders/data";
import { validateEmail } from "src/utils/validation";
import { requestCreator, successCreator, failureCreator } from "../utils/action";
import { Creators as globalCreators } from "../actions/global";
import { Creators as adminCreators } from "../actions/admin";
import { Types, Creators } from "../actions/admin-stakeholder";
import API from "../api/admin-stakeholder";

function* sagaAction(action) {
  const type = action.type;
  try {
    yield put(requestCreator(type, {}));
    const payload = yield call(API[type], action);
    yield put(successCreator(type, payload));
  }
  catch (err) {
    console.error(err);
    yield put(failureCreator(type, { err }));
  }
}

function* updateWallet(action) {
  const type = action.type;
  try {
    yield put(requestCreator(type, {}));
    const payload = yield call(API[type], action);
    yield put(successCreator(type, payload));
    yield put(adminCreators.getNavLeftStatus());

    if (["accept", "reject"].includes(payload.KYC)) {
      NotificationManager.success("Request has been accepted, will be done in a minute!");
    }
  }
  catch (err) {
    console.error(err);
    yield put(failureCreator(type, { err }));
  }
}

function* updateWalletFunding(action) {
  const type = action.type;
  try {
    yield put(requestCreator(type, {}));
    const payload = yield call(API[type], action);
    yield put(successCreator(type, payload));
    yield put(adminCreators.getNavLeftStatus());

    if (payload.funding_enabled) {
      const name = payload.firstName ? `${payload.firstName} ${payload.lastName}` : payload.email;
      NotificationManager.success(`${name} is now allowed to invest`);
    }
  }
  catch (err) {
    console.error(err);
    yield put(failureCreator(type, { err }));
  }
}

function* searchWallets(action) {
  const { type, payload } = action;

  try {
    yield put(requestCreator(type, payload.isFetchMore));

    const { searchedWallets: { wallets, lastItemKey } } = yield select(state => state.adminStakeholder);

    const search = { pageLimit: payload.pageLimit || 100 };
    if (payload.isFetchMore) {
      search.ExclusiveStartKey = lastItemKey;
    }

    if (payload.searchKeyword) {
      if (validateEmail(payload.searchKeyword)) {
        search[SEARCH_BY_OPTION.EMAIL] = payload.searchKeyword;
      }
      else if (payload.searchKeyword.startsWith("0x")) {
        search[SEARCH_BY_OPTION.WALLET] = payload.searchKeyword;
      }
      else {
        search[SEARCH_BY_OPTION.NAME] = payload.searchKeyword;
      }
    }
    else if (payload.filterOption && payload.filterOption !== FILTER_BY_OPTION.ALL) {
      search[payload.filterOption] = true;
    }
    if (payload.sortOption === SORT_BY_OPTION.UP) {
      search.sort_asc = true;
    }

    const { Items, LastEvaluatedKey } = yield call(API[type], search);

    if (payload.searchKeyword?.length >= 3) {
      const newItems = Items.filter((obj) => {
        switch (payload.filterOption) {
        case FILTER_BY_OPTION.ALL: return true;
        case FILTER_BY_OPTION.VERIFIED: return obj.SK2.startsWith(WALLET_SK2_PREFIX.VERIFIED);
        case FILTER_BY_OPTION.LEGAL_SIGNED: return obj.SK2.startsWith(WALLET_SK2_PREFIX.LEGAL_SIGNED);
        case FILTER_BY_OPTION.FUNDS_SENT: return obj.SK2.startsWith(WALLET_SK2_PREFIX.FUNDS_SENT);
        case FILTER_BY_OPTION.INVESTMENT_EXECUTED: return obj.SK2.startsWith(WALLET_SK2_PREFIX.INVESTED);
        case FILTER_BY_OPTION.ACTION_REQUIRED: return obj.GSI6SK.startsWith(WALLET_GSI6SK_PREFIX);
        default: return false;
        }
      });
      if (payload.filterOption === FILTER_BY_OPTION.ACTION_REQUIRED) {
        newItems.sort((a, b) => new Date(a.action_required_date) - new Date(b.action_required_date));
      }
      else {
        newItems.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
      }
      yield put(successCreator(type, { wallets: payload.sortOption === SORT_BY_OPTION.UP ? newItems : newItems.reverse() }));
    }
    else {
      const newWallets = {
        wallets     : payload.isFetchMore ? [...wallets, ...Items] : Items,
        lastItemKey : LastEvaluatedKey
      };

      yield put(successCreator(type, newWallets));
    }
  }
  catch (err) {
    console.error(err);
    yield put(failureCreator(type, { err }));
  }
}

function* sendInvestmentPDF(action) {
  const type = action.type;
  try {
    yield put(requestCreator(type, {}));
    const payload = yield call(API[type], action);
    yield put(successCreator(type, payload));

    yield put(globalCreators.setCurrentDialog(DIALOG_NAME.AdminDocumentSent, payload.investItem.email));
  }
  catch (err) {
    console.error(err);
    yield put(failureCreator(type, { err }));
  }
}

function* getUserDocSignedUrl(action) {
  const type = action.type;
  try {
    yield put(requestCreator(type, {}));
    const payload = yield call(API[type], action);
    yield put(successCreator(type, payload));

    const { fileName } = action.payload;
    handleDownload(payload.signedUrl, fileName);
  }
  catch (err) {
    console.error(err);
    yield put(failureCreator(type, { err }));
  }
}

function* inviteStakeholders(action) {
  const type = action.type;
  try {
    yield put(requestCreator(type, {}));
    const payload = yield call(API[type], action);
    yield put(successCreator(type, payload));

    const { results } = payload;
    const failedEmails = [];
    results.forEach((result) => {
      if (result.status === "ERROR") {
        failedEmails.push(result.email);
      }
    });

    if (failedEmails.length) {
      if (failedEmails.length === action.payload.length) {
        yield put(globalCreators.setCurrentDialog(DIALOG_NAME.InviteStakeholderResult, { success: false }));
      }
      else {
        yield put(globalCreators.setCurrentDialog(DIALOG_NAME.InviteStakeholderResult, { success: false, emails: failedEmails }));
        yield put(Creators.searchWallets({ filterOption: "all" }));
      }
    }
    else if (results[0]?.status === "EXISTS") {
      const { email } = results[0];
      yield put(globalCreators.setCurrentDialog(DIALOG_NAME.ResendInvite, { email }));
    }
    else {
      yield put(globalCreators.setCurrentDialog(DIALOG_NAME.InviteStakeholderResult, { success: true, emails: results }));
      yield put(Creators.searchWallets({ filterOption: "all" }));
    }
  }
  catch (err) {
    console.error(err);
    yield put(globalCreators.setCurrentDialog(DIALOG_NAME.InviteStakeholderResult, { success: false }));
    yield put(failureCreator(type, { err }));
  }
}

export function* adminStakeholderSaga() {
  yield all([
    takeLatest(Types.GET_ACCREDITATION_LOGS,      sagaAction),
    takeLatest(Types.GET_EVENTS,                  sagaAction),
    takeLatest(Types.GET_KYC_DATA,                sagaAction),
    takeLatest(Types.SEARCH_WALLETS,              searchWallets),
    takeLatest(Types.UPDATE_WALLET,               updateWallet),
    takeLatest(Types.UPDATE_WALLET_FUNDING,       updateWalletFunding),
    takeLatest(Types.GET_PROCESSED_INVESTMENTS,   sagaAction),
    takeLatest(Types.GET_USER_INVESTMENT_DOC_URL, sagaAction),
    takeLatest(Types.SEND_INVESTMENT_PDF,         sendInvestmentPDF),
    takeLatest(Types.GET_USER_DOC_SIGNED_URL,     getUserDocSignedUrl),
    takeLatest(Types.INVITE_STAKEHOLDERS,         inviteStakeholders),
    takeLatest(Types.RESEND_INVITE,               inviteStakeholders),
    takeLatest(Types.GET_USER_LEGAL_ITEMS,        sagaAction),
    takeLatest(Types.GET_USER_COMP_DOC_URL,       sagaAction),
    takeLatest(Types.GET_TAX_DOC_URL,             sagaAction),
  ]);
}
