import React, { Suspense, lazy } from "react";
import PropTypes from "prop-types";
import { LoadingSpinner } from "src/components";
import { DIALOG_NAME } from "src/constants";

const FundDialog                    = lazy(() => import("src/components/FundDialog"));
const VerifyIdentityDialog          = lazy(() => import("src/components/VerifyIdentityDialog"));
const ContactUsKYBDialog            = lazy(() => import("src/components/ContactUsKYBDialog"));
const AccreditationDialog           = lazy(() => import("src/components/AccreditationDialog"));
const Activate2FADialog             = lazy(() => import("src/components/Activate2FADialog"));
const Deactivate2FADialog           = lazy(() => import("src/components/Deactivate2FADialog"));
const Invest                        = lazy(() => import("src/components/Invest"));
const BuySuccess                    = lazy(() => import("src/components/Investment/BuySuccess"));
const FinalizeInvestment            = lazy(() => import("src/components/Investment/FinalizeInvestment"));
const PendingInvestment             = lazy(() => import("src/components/Investment/PendingInvestment"));
const PendingSetup                  = lazy(() => import("src/components/Investment/PendingSetup"));
const NotReadyInvestment            = lazy(() => import("src/components/Investment/NotReadyInvestment"));
const KYBWaitInvestment             = lazy(() => import("src/components/Investment/KYBWaitInvestment"));
const WithdrawDialog                = lazy(() => import("src/components/WithdrawDialog"));
const UserTransferTokenDialog       = lazy(() => import("src/components/UserTransferTokenDialog"));
const DiagnosticsModal              = lazy(() => import("src/components/DiagnosticsModal"));
const SendUSDCDialog                = lazy(() => import("src/components/SendUSDCDialog"));
const TransferTokenDialog           = lazy(() => import("src/components/TransferTokenDialog"));
const DocumentDialog                = lazy(() => import("src/components/DocumentDialog"));
const InvestmentSimulatorDialog     = lazy(() => import("src/components/InvestmentSimulatorDialog"));
const AccreditedInvestorDialog      = lazy(() => import("src/components/AccreditedInvestorDialog"));
const AddRecipientDialog            = lazy(() => import("src/pages/Admin/Recipients/AddRecipientDialog"));
const EditRecipientDialog           = lazy(() => import("src/pages/Admin/Recipients/EditRecipientDialog"));
const DeleteRecipientDialog         = lazy(() => import("src/pages/Admin/Recipients/DeleteRecipientDialog"));
const AddPoolDialog                 = lazy(() => import("src/pages/Admin/StakeholdersCompensation/Pools/AddPoolDialog"));
const DeletePoolDialog              = lazy(() => import("src/pages/Admin/StakeholdersCompensation/Pools/DeletePoolDialog"));
const SafeToCafeDialog              = lazy(() => import("src/pages/Admin/Dashboard/SafeToCafeDialog"));
const CompensateStakeholderDialog   = lazy(() => import("src/pages/Admin/StakeholdersCompensation/CompensateStakeholderDialog"));
const EditCompanyInfoDialog         = lazy(() => import("src/pages/Admin/Settings/General/Dialogs/EditCompanyInfoDialog"));
const EditCompanySignerDialog       = lazy(() => import("src/pages/Admin/Settings/General/Dialogs/EditSignerDialog"));
const EditCompanyEmailsDialog       = lazy(() => import("src/pages/Admin/Settings/General/Dialogs/EditEmailsDialog"));
const EditCompanySocialDialog       = lazy(() => import("src/pages/Admin/Settings/General/Dialogs/EditSocialAccountsDialog"));
const CloseTabDialog                = lazy(() => import("src/components/CloseTabDialog"));
const AdminDocumentSentDialog       = lazy(() => import("src/components/AdminDocumentSentDialog"));
const PublicInvestDialog            = lazy(() => import("src/components/PublicInvestDialog"));
const InviteStakeholderDialog       = lazy(() => import("src/components/InviteStakeholderDialog"));
const ResendInviteDialog            = lazy(() => import("src/components/ResendInviteDialog"));
const InviteStakeholderResult       = lazy(() => import("src/components/InviteStakeholderResult"));
const GrantEquitySuccessDialog      = lazy(() => import("src/components/GrantEquitySuccessDialog"));
const UnlockCommunityDialog         = lazy(() => import("src/components/UnlockCommunityDialog"));
const CancelInvestmentDialog        = lazy(() => import("src/components/CancelInvestmentDialog"));
const DomainSetupDialog             = lazy(() => import("src/components/DomainSetupDialog"));
const FinishDomainSetupDialog       = lazy(() => import("src/components/FinishDomainSetupDialog"));
const BankInfoDialog                = lazy(() => import("src/components/BankInfoDialog"));
const GeneratingAllocationDialog    = lazy(() => import("src/components/GeneratingAllocationDialog"));
const GeneratingCompensationDialog  = lazy(() => import("src/components/GeneratingCompensationDialog"));
const GeneratingECADocDialog        = lazy(() => import("src/components/GeneratingECADocDialog"));
const CompanyWalletInfoDialog       = lazy(() => import("src/components/CompanyWalletInfoDialog"));
const ProcessingPortalLiveDialog    = lazy(() => import("src/components/ProcessingPortalLiveDialog"));
const CreatingAllocationDialog      = lazy(() => import("src/components/CreatingAllocationDialog"));
const PaymentDialog                 = lazy(() => import("src/components/PaymentDialog"));
const EntriDoneDialog               = lazy(() => import("src/components/EntriDoneDialog"));
const KYBSubmitReviewDialog         = lazy(() => import("src/components/KYBSubmitReviewDialog"));

const DialogWrapper = ({ global }) => {
  const { currentDialog, orgConfig } = global;

  // No open dialog
  if (!currentDialog) return null;

  // Render
  return (
    <Suspense fallback={<LoadingSpinner />}>
      {currentDialog.name === DIALOG_NAME.CompensateStakeholder && <CompensateStakeholderDialog />}
      {currentDialog.name === DIALOG_NAME.InvestmentSimulator && <InvestmentSimulatorDialog />}
      {currentDialog.name === DIALOG_NAME.Diagnostics && <DiagnosticsModal />}
      {currentDialog.name === DIALOG_NAME.CloseTab && <CloseTabDialog />}
      {currentDialog.name === DIALOG_NAME.AddRecipient && <AddRecipientDialog />}
      {currentDialog.name === DIALOG_NAME.EditRecipient && <EditRecipientDialog />}
      {currentDialog.name === DIALOG_NAME.DeleteRecipient && <DeleteRecipientDialog />}
      {currentDialog.name === DIALOG_NAME.PendingInvestment && <PendingInvestment />}
      {currentDialog.name === DIALOG_NAME.NotReadyInvestment && <NotReadyInvestment />}
      {currentDialog.name === DIALOG_NAME.PendingSetup && <PendingSetup />}
      {currentDialog.name === DIALOG_NAME.Fund && <FundDialog />}
      {currentDialog.name === DIALOG_NAME.Invest && <Invest />}
      {currentDialog.name === DIALOG_NAME.FinalizeInvest && <FinalizeInvestment />}
      {currentDialog.name === DIALOG_NAME.AccreditedInvestor  && <AccreditedInvestorDialog />}
      {currentDialog.name === DIALOG_NAME.ContactUsKYB && <ContactUsKYBDialog />}
      {currentDialog.name === DIALOG_NAME.Accreditation && <AccreditationDialog />}
      {currentDialog.name === DIALOG_NAME.VerifyIdentity && <VerifyIdentityDialog />}
      {currentDialog.name === DIALOG_NAME.SafeToCafe && <SafeToCafeDialog />}
      {currentDialog.name === DIALOG_NAME.SendUSDC && <SendUSDCDialog />}
      {currentDialog.name === DIALOG_NAME.TransferToken && <TransferTokenDialog />}
      {currentDialog.name === DIALOG_NAME.AddPool && <AddPoolDialog />}
      {currentDialog.name === DIALOG_NAME.DeletePool && <DeletePoolDialog />}
      {currentDialog.name === DIALOG_NAME.Activate2FA && <Activate2FADialog />}
      {currentDialog.name === DIALOG_NAME.Deactivate2FA && <Deactivate2FADialog />}
      {currentDialog.name === DIALOG_NAME.Documents && <DocumentDialog />}
      {currentDialog.name === DIALOG_NAME.Withdrawal && <WithdrawDialog orgConfig={orgConfig} />}
      {currentDialog.name === DIALOG_NAME.UserTransferToken && <UserTransferTokenDialog />}
      {currentDialog.name === DIALOG_NAME.BuySuccess && <BuySuccess />}
      {currentDialog.name === DIALOG_NAME.AdminDocumentSent && <AdminDocumentSentDialog />}
      {currentDialog.name === DIALOG_NAME.PublicInvest && <PublicInvestDialog />}
      {currentDialog.name === DIALOG_NAME.InviteStakeholder && <InviteStakeholderDialog />}
      {currentDialog.name === DIALOG_NAME.ResendInvite && <ResendInviteDialog />}
      {currentDialog.name === DIALOG_NAME.InviteStakeholderResult && <InviteStakeholderResult />}
      {currentDialog.name === DIALOG_NAME.GrantEquitySuccess && <GrantEquitySuccessDialog />}
      {currentDialog.name === DIALOG_NAME.UnlockCommunity && <UnlockCommunityDialog />}
      {currentDialog.name === DIALOG_NAME.CancelInvestment && <CancelInvestmentDialog />}
      {currentDialog.name === DIALOG_NAME.EditCompanyInfo && <EditCompanyInfoDialog />}
      {currentDialog.name === DIALOG_NAME.EditCompanyEmails && <EditCompanyEmailsDialog />}
      {currentDialog.name === DIALOG_NAME.EditCompanySigner && <EditCompanySignerDialog />}
      {currentDialog.name === DIALOG_NAME.EditCompanySocialAccounts && <EditCompanySocialDialog />}
      {currentDialog.name === DIALOG_NAME.DomainSetup && <DomainSetupDialog />}
      {currentDialog.name === DIALOG_NAME.FinishDomainSetup && <FinishDomainSetupDialog />}
      {currentDialog.name === DIALOG_NAME.BankInfo && <BankInfoDialog />}
      {currentDialog.name === DIALOG_NAME.GeneratingAllocation && <GeneratingAllocationDialog />}
      {currentDialog.name === DIALOG_NAME.GeneratingCompensation && <GeneratingCompensationDialog />}
      {currentDialog.name === DIALOG_NAME.GeneratingECADoc && <GeneratingECADocDialog />}
      {currentDialog.name === DIALOG_NAME.CompanyWalletInfo && <CompanyWalletInfoDialog />}
      {currentDialog.name === DIALOG_NAME.ProcessingPortalLive && <ProcessingPortalLiveDialog />}
      {currentDialog.name === DIALOG_NAME.CreatingAllocation && <CreatingAllocationDialog />}
      {currentDialog.name === DIALOG_NAME.Payment && <PaymentDialog />}
      {currentDialog.name === DIALOG_NAME.EntriDone && <EntriDoneDialog />}
      {currentDialog.name === DIALOG_NAME.KYBSubmitReview && <KYBSubmitReviewDialog />}
      {currentDialog.name === DIALOG_NAME.KYBWaitInvestment && <KYBWaitInvestment />}
    </Suspense>
  );
};

DialogWrapper.propTypes = {
  global: PropTypes.object,
};

export default DialogWrapper;
