import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Card, Image } from "semantic-ui-react";
import { FormattedMessage } from "react-intl";
import { withStyles } from "@mui/styles";
import classNames from "classnames";

import BrokenImg from "src/assets/images/default/Refresh.svg";
import Button from "src/components/Button";

import { messages } from "./messages";
import themeStyles from "./theme";

const ReloadCard = ({ orgConfig, classes }) => (
  <Card className={classNames("reload-card", classes.root, classes[orgConfig.id])}>
    <Card.Content>
      <Card.Description>
        <Image src={BrokenImg} />
        <FormattedMessage {...messages.errorReload} />
        <Button
          primary
          fluid
          onClick={() => window.location.reload()}
        >
          <FormattedMessage {...messages.reload} />
        </Button>
      </Card.Description>
    </Card.Content>
  </Card>
);

const mapStateToProps = store => ({
  orgConfig: store.global.orgConfig
});

ReloadCard.propTypes = {
  orgConfig : PropTypes.object.isRequired,
  classes   : PropTypes.object,
};

export default connect(mapStateToProps)(withStyles(themeStyles)(ReloadCard));
