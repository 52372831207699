export const SECTION_ID = {
  GETTING_STARTED_FAIRMINT : "gettingStartedFairmint",
  SETUP_EQUITY_ALLOCATION  : "setupEquityAllocation",
  PREPARE_FUNDRAISING      : "prepareFundraising",
  DESIGN_EQUITY_GRANT      : "designEquityGrant",
  CUSTOMIZE_PORTAL         : "customizePortal",
  PRESENT_COMPANY          : "presentCompany",
  PERSONALIZE_WELCOME_FLOW : "personalizeWelcomeFlow",
  PROMOTE_COMMUNITY        : "promoteCommunity",
  CONNECT_DOTS             : "connectDots",
  INTEGRATE_WE_BUILT       : "integrateWeBuilt",
  ADD_ONS                  : "addOns",
};

export const REDIRECT_PATH = {
  ALLOCATION        : "#allocation",
  COMPENSATION_PLAN : "#compensation-plan",
  ECA               : "#eca",
  SPC               : "#spc",
};

// These values should be matched to backend values
export const STEP_ID = {
  INTRO                      : "intro",
  COMPANY_INFO               : "settingsCompanyInfo",
  FOUNDER_PROFILE            : "contentFounderProfile",
  LEGAL_SIGNER               : "settingsLegalSigner",
  INVITE_TEAMMATES           : "teamInvitations",
  FIRST_ALLOCATION           : "firstAllocation",
  TICKER_SYMBOL              : "tickerSymbol",
  FUNDRAISING_AGREEMENT      : "fundraisingAgreement",
  FUNDRAISING_PARAMS         : "fundraisingParams",
  FUNDRAISING_BANK           : "fundraisingBank",
  FUNDRAISING_WALLET         : "fundraisingWallet",
  FUNDRAISING_RISK_FACTORS   : "fundraisingRiskFactors",
  FUNDRAISING_AGREEMENT_SIG  : "fundraisingAgreementSig",
  COMPENSATION_PLAN          : "compensationPlan",
  COMPENSATION_POOL          : "compensationPool",
  COMPENSATION_SPC           : "compensationSpc",
  COMPENSATION_ECA           : "compensationEca",
  COMPENSATION_AGREEMENT_SIG : "compensationAgreementSig",
  BRAND_IMAGES               : "brandImages",
  BRAND_COLORS               : "brandColors",
  CONTENT_CEO_HEADLINE       : "contentCeoHeadline",
  CONTENT_STORY              : "contentStory",
  CONTENT_MAIN_INVESTORS     : "contentMainInvestors",
  CONTENT_TEAM               : "contentTeam",
  CONTENT_COMPANY_LINKS      : "contentCompanyLinks",
  CONTENT_TESTIMONIALS       : "contentTestimonials",
  CONTENT_COMPANY_PROFILE    : "contentCompanyProfile",
  CONTENT_COMPANY_MEDIA      : "contentCompanyMedia",
  SETTINGS_PUBLIC_PAGE       : "settingsPublicPage",
  CONTENT_SIGNUP_PAGE        : "contentSignupPage",
  CONTENT_WELCOME_MSG        : "contentWelcomeMsg",
  SETTINGS_SOCIAL_MEDIA      : "settingsSocialMedia",
  CONTENT_TWITTER_MSG        : "contentTwitterMsg",
  LEARN_OFFERING_PROMOTION   : "learnOfferingPromotion",
  DNS_DOMAIN                 : "dnsDomain",
  DNS_VERIFICATION           : "dnsVerification",
  EMAILS                     : "emails",
  SETTINGS_LEGAL             : "settingsLegal",
  INTEGRATIONS               : "integrations",
  WIDGET                     : "widget",
  ADD_ON_SEC                 : "addonSec",
  ADD_ON_INSURANCE           : "addonInsurance",
  CUSTOM_PORTAL              : "customPortal",
};

export const ONBOARDING_STEPS = [
  {
    id        : STEP_ID.INTRO,
    sectionId : SECTION_ID.GETTING_STARTED_FAIRMINT,
    percent   : 0,
    minute    : 1,
    optional  : true
  },
  {
    id        : STEP_ID.COMPANY_INFO,
    sectionId : SECTION_ID.GETTING_STARTED_FAIRMINT,
    percent   : 4,
    minute    : 1
  },
  {
    id        : STEP_ID.FOUNDER_PROFILE,
    sectionId : SECTION_ID.GETTING_STARTED_FAIRMINT,
    percent   : 4,
    minute    : 2
  },
  {
    id        : STEP_ID.LEGAL_SIGNER,
    sectionId : SECTION_ID.GETTING_STARTED_FAIRMINT,
    percent   : 4,
    minute    : 1
  },
  {
    id        : STEP_ID.INVITE_TEAMMATES,
    sectionId : SECTION_ID.GETTING_STARTED_FAIRMINT,
    percent   : 0,
    minute    : 1,
    optional  : true
  },
  {
    id        : STEP_ID.FIRST_ALLOCATION,
    sectionId : SECTION_ID.SETUP_EQUITY_ALLOCATION,
    percent   : 8,
    minute    : 9
  },
  {
    id        : STEP_ID.TICKER_SYMBOL,
    sectionId : SECTION_ID.SETUP_EQUITY_ALLOCATION,
    percent   : 5,
    minute    : 1
  },
  {
    id        : STEP_ID.FUNDRAISING_AGREEMENT,
    sectionId : SECTION_ID.PREPARE_FUNDRAISING,
    percent   : 2,
    minute    : 3
  },
  {
    id        : STEP_ID.FUNDRAISING_PARAMS,
    sectionId : SECTION_ID.PREPARE_FUNDRAISING,
    percent   : 2,
    minute    : 1
  },
  {
    id        : STEP_ID.FUNDRAISING_BANK,
    sectionId : SECTION_ID.PREPARE_FUNDRAISING,
    percent   : 4,
    minute    : 3
  },
  {
    id        : STEP_ID.FUNDRAISING_WALLET,
    sectionId : SECTION_ID.PREPARE_FUNDRAISING,
    percent   : 2,
    minute    : 2,
  },
  {
    id        : STEP_ID.FUNDRAISING_RISK_FACTORS,
    sectionId : SECTION_ID.PREPARE_FUNDRAISING,
    percent   : 2,
    minute    : 10
  },
  {
    id        : STEP_ID.FUNDRAISING_AGREEMENT_SIG,
    sectionId : SECTION_ID.PREPARE_FUNDRAISING,
    percent   : 2,
    minute    : 1
  },
  {
    id        : STEP_ID.COMPENSATION_PLAN,
    sectionId : SECTION_ID.DESIGN_EQUITY_GRANT,
    percent   : 2,
    minute    : 8
  },
  {
    id        : STEP_ID.COMPENSATION_POOL,
    sectionId : SECTION_ID.DESIGN_EQUITY_GRANT,
    percent   : 2,
    minute    : 1
  },
  {
    id        : STEP_ID.COMPENSATION_ECA,
    sectionId : SECTION_ID.DESIGN_EQUITY_GRANT,
    percent   : 4,
    minute    : 5
  },
  {
    id        : STEP_ID.COMPENSATION_SPC,
    sectionId : SECTION_ID.DESIGN_EQUITY_GRANT,
    percent   : 2,
    minute    : 4
  },
  {
    id        : STEP_ID.COMPENSATION_AGREEMENT_SIG,
    sectionId : SECTION_ID.DESIGN_EQUITY_GRANT,
    percent   : 2,
    minute    : 1
  },
  {
    id        : STEP_ID.BRAND_IMAGES,
    sectionId : SECTION_ID.CUSTOMIZE_PORTAL,
    percent   : 5,
    minute    : 10
  },
  {
    id        : STEP_ID.BRAND_COLORS,
    sectionId : SECTION_ID.CUSTOMIZE_PORTAL,
    percent   : 5,
    minute    : 2
  },
  {
    id        : STEP_ID.CONTENT_CEO_HEADLINE,
    sectionId : SECTION_ID.PRESENT_COMPANY,
    percent   : 2,
    minute    : 1
  },
  {
    id        : STEP_ID.CONTENT_STORY,
    sectionId : SECTION_ID.PRESENT_COMPANY,
    percent   : 2,
    minute    : 3
  },
  {
    id        : STEP_ID.CONTENT_MAIN_INVESTORS,
    sectionId : SECTION_ID.PRESENT_COMPANY,
    percent   : 2,
    minute    : 2,
    optional  : true
  },
  {
    id        : STEP_ID.CONTENT_TEAM,
    sectionId : SECTION_ID.PRESENT_COMPANY,
    percent   : 2,
    minute    : 2,
    optional  : true
  },
  {
    id        : STEP_ID.CONTENT_COMPANY_LINKS,
    sectionId : SECTION_ID.PRESENT_COMPANY,
    percent   : 2,
    minute    : 2
  },
  {
    id        : STEP_ID.CONTENT_TESTIMONIALS,
    sectionId : SECTION_ID.PRESENT_COMPANY,
    percent   : 2,
    minute    : 2,
    optional  : true
  },
  {
    id        : STEP_ID.CONTENT_COMPANY_PROFILE,
    sectionId : SECTION_ID.PRESENT_COMPANY,
    percent   : 2,
    minute    : 1,
    optional  : true
  },
  {
    id        : STEP_ID.CONTENT_COMPANY_MEDIA,
    sectionId : SECTION_ID.PRESENT_COMPANY,
    percent   : 2,
    minute    : 1,
    optional  : true
  },
  {
    id        : STEP_ID.SETTINGS_PUBLIC_PAGE,
    sectionId : SECTION_ID.PRESENT_COMPANY,
    percent   : 0,
    minute    : 1,
    optional  : true
  },
  {
    id        : STEP_ID.CONTENT_SIGNUP_PAGE,
    sectionId : SECTION_ID.PERSONALIZE_WELCOME_FLOW,
    percent   : 4,
    minute    : 5
  },
  {
    id        : STEP_ID.CONTENT_WELCOME_MSG,
    sectionId : SECTION_ID.PERSONALIZE_WELCOME_FLOW,
    percent   : 4,
    minute    : 3
  },
  {
    id        : STEP_ID.SETTINGS_SOCIAL_MEDIA,
    sectionId : SECTION_ID.PROMOTE_COMMUNITY,
    percent   : 3,
    minute    : 2
  },
  {
    id        : STEP_ID.CONTENT_TWITTER_MSG,
    sectionId : SECTION_ID.PROMOTE_COMMUNITY,
    percent   : 0,
    minute    : 5,
    optional  : true
  },
  {
    id        : STEP_ID.LEARN_OFFERING_PROMOTION,
    sectionId : SECTION_ID.PROMOTE_COMMUNITY,
    percent   : 0,
    minute    : 1,
    optional  : true
  },
  {
    id        : STEP_ID.DNS_DOMAIN,
    sectionId : SECTION_ID.CONNECT_DOTS,
    percent   : 2,
    minute    : 1
  },
  {
    id        : STEP_ID.DNS_VERIFICATION,
    sectionId : SECTION_ID.CONNECT_DOTS,
    percent   : 4,
    minute    : 8
  },
  {
    id        : STEP_ID.EMAILS,
    sectionId : SECTION_ID.CONNECT_DOTS,
    percent   : 2,
    minute    : 1
  },
  {
    id        : STEP_ID.SETTINGS_LEGAL,
    sectionId : SECTION_ID.CONNECT_DOTS,
    percent   : 4,
    minute    : 10
  },
  {
    id        : STEP_ID.INTEGRATIONS,
    sectionId : SECTION_ID.INTEGRATE_WE_BUILT,
    percent   : 0,
    minute    : 4,
    optional  : true
  },
  {
    id        : STEP_ID.WIDGET,
    sectionId : SECTION_ID.INTEGRATE_WE_BUILT,
    percent   : 0,
    minute    : 3,
    optional  : true
  },
  {
    id        : STEP_ID.ADD_ON_SEC,
    sectionId : SECTION_ID.ADD_ONS,
    percent   : 0,
    minute    : 2,
    optional  : true
  },
  {
    id        : STEP_ID.ADD_ON_INSURANCE,
    sectionId : SECTION_ID.ADD_ONS,
    percent   : 0,
    minute    : 2,
    optional  : true
  },
  {
    id        : STEP_ID.CUSTOM_PORTAL,
    sectionId : SECTION_ID.ADD_ONS,
    percent   : 0,
    minute    : 2,
    optional  : true
  },
];
