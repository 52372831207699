import { callLambda, SERVICE_2 } from "src/utils/lambda";
import { Types } from "../actions/offering-summary";

const apis = {};

apis[Types.GET_TOKEN_PRICE_HISTORY] = () => callLambda({
  method   : "get",
  service  : SERVICE_2,
  url      : "getTokenPriceHistory",
  unauthed : true
});

apis[Types.GET_RISK_FACTORS] = () => callLambda({
  method  : "post",
  service : SERVICE_2,
  url     : "getRiskFactors",
});

export default apis;
