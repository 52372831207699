import { put, call, takeLatest, all } from "redux-saga/effects";
import { push } from "connected-react-router";
import { NotificationManager } from "react-notifications";

import { ROUTE_PATH } from "src/constants";
import { STEP_ID } from "src/pages/Admin/Home/data";
import { requestCreator, successCreator, failureCreator } from "../utils/action";
import { Types } from "../actions/admin-allocation";
import { Creators as globalCreators } from "../actions/global";
import { Creators as userCreators } from "../actions/user";
import { Creators as adminContentCreators } from "../actions/admin-content";
import API from "../api/admin-allocation";

function* sagaAction(action) {
  const type = action.type;
  try {
    if (!action.hideLoading) {
      yield put(requestCreator(type, {}));
    }

    const payload = yield call(API[type], action);
    yield put(successCreator(type, payload));
    if (type === Types.UPDATE_ALLOCATION) {
      NotificationManager.success("The changes were saved");
    }
    if (type === Types.RESET_ALLOCATION) {
      yield put(adminContentCreators.updateOrgOnboarding({
        [STEP_ID.FIRST_ALLOCATION]           : false,
        [STEP_ID.FUNDRAISING_AGREEMENT_SIG]  : false,
        [STEP_ID.COMPENSATION_PLAN]          : false,
        [STEP_ID.COMPENSATION_SPC]           : false,
        [STEP_ID.COMPENSATION_ECA]           : false,
        [STEP_ID.COMPENSATION_AGREEMENT_SIG] : false,
      }));
      yield put(push(ROUTE_PATH.ADMIN_ALLOCATIONS_1));
    }
  }
  catch (err) {
    console.error(err);
    yield put(failureCreator(type, { err }));
  }
}

function* addAllocation(action) {
  const type = action.type;
  try {
    yield put(requestCreator(type, {}));

    const payload = yield call(API[type], action);
    yield put(successCreator(type, payload));

    yield put(userCreators.getCurrentAllocation());
    yield put(userCreators.refreshOrgInfo());

    NotificationManager.success("Your allocation was created");
  }
  catch (err) {
    console.error(err);
    yield put(failureCreator(type, { err: "The allocation could not be created. Please try again in a few minutes." }));
  }

  yield put(globalCreators.setCurrentDialog(null));
}

export function* adminAllocationSaga() {
  yield all([
    takeLatest(Types.ADD_ALLOCATION, addAllocation),
    takeLatest(Types.GET_ALLOCATIONS, sagaAction),
    takeLatest(Types.UPDATE_ALLOCATION, sagaAction),
    takeLatest(Types.RESET_ALLOCATION, sagaAction),
    takeLatest(Types.UPDATE_ALLOCATION_STATUS, sagaAction),
  ]);
}
