export default {
  // global styles for the app
  root: ({ global: { orgConfig } }) => ({
    "& .ui.menu a.item": {
      "&:hover, &:hover svg rect, &:hover svg path,&.active, &.active svg rect, &.active svg path, &.active:hover": {
        color  : orgConfig && orgConfig.theme.primary,
        stroke : orgConfig && orgConfig.theme.primary
      }
    },
    "& .mobile-navmenu.menu .right.item a.item, & .mobile-header-navmenu.menu .right.item a.item, .mobile-close-icon": {
      "& svg rect, svg path": {
        fill   : orgConfig && orgConfig.theme.primary,
        stroke : orgConfig && orgConfig.theme.primary
      }
    }
  }),
  fairmint : () => ({}),
  erxes    : ({ orgConfig }) => ({
    "& .ui.menu a.item": {
      "&:hover, &:hover svg rect, &:hover svg path,&.active, &.active svg rect, &.active svg path": {
        color  : orgConfig && orgConfig.theme.secondary,
        stroke : orgConfig && orgConfig.theme.secondary
      }
    },
  }),
  glided    : () => ({}),
  greenruhm : () => ({}),
  hickies   : () => ({
    "& .ui.menu a.item": {
      "&:hover, &:hover svg rect, &:hover svg path,&.active, &.active svg rect, &.active svg path": {
        color  : "#4EADA4",
        stroke : "#4EADA4",
      }
    },
  }),
  opengrants : () => ({}),
  beem       : () => ({}),
  endowl     : () => ({}),
};
