import { populateOrgConfig } from "src/utility";
import { callLambda, SERVICE_1 } from "src/utils/lambda";
import { Types } from "../actions/global";

const apis = {};

apis[Types.GET_CONFIGURATION] = async () => {
  const { orgConfig } = await callLambda({
    method   : "get",
    service  : SERVICE_1,
    url      : "getConfiguration",
    unauthed : true
  });

  return { orgConfig: await populateOrgConfig(orgConfig) };
};

export default apis;
