export const SIDE_PANEL = {
  BALANCES     : "balances",
  ACTIONS      : "actions",
  LEGAL_DOC    : "legal_documents",
  KYC          : "kyc",
  NOTIFICATION : "notification"
};

export const SEARCH_BY_OPTION = {
  EMAIL  : "email",
  WALLET : "walletAddress",
  NAME   : "name",
};

export const FILTER_BY_OPTION = {
  ALL                 : "all",
  VERIFIED            : "verified_identity",
  LEGAL_SIGNED        : "legal_signed",
  FUNDS_SENT          : "funds_sent",
  INVESTMENT_EXECUTED : "investment_executed",
  ACTION_REQUIRED     : "action_required",
};

export const SORT_BY_OPTION = {
  UP   : "date_up",
  DOWN : "date_down",
};

export const WALLET_SK2_PREFIX = {
  VERIFIED     : "ST#verified",
  LEGAL_SIGNED : "ST#legal_signed",
  FUNDS_SENT   : "ST#funds_sent",
  INVESTED     : "ST#invested"
};

export const WALLET_GSI6SK_PREFIX = "STATUS#action_required";

export const filterByOptions = [
  {
    key   : FILTER_BY_OPTION.ALL,
    value : FILTER_BY_OPTION.ALL,
    text  : "All members",
  },
  {
    key   : FILTER_BY_OPTION.ACTION_REQUIRED,
    value : FILTER_BY_OPTION.ACTION_REQUIRED,
    text  : "Action required"
  },
  {
    key   : FILTER_BY_OPTION.VERIFIED,
    value : FILTER_BY_OPTION.VERIFIED,
    text  : "Identity verified",
  },
  {
    key   : FILTER_BY_OPTION.LEGAL_SIGNED,
    value : FILTER_BY_OPTION.LEGAL_SIGNED,
    text  : "Legal signed",
  },
  {
    key   : FILTER_BY_OPTION.FUNDS_SENT,
    value : FILTER_BY_OPTION.FUNDS_SENT,
    text  : "Funds sent",
  },
  {
    key   : FILTER_BY_OPTION.INVESTMENT_EXECUTED,
    value : FILTER_BY_OPTION.INVESTMENT_EXECUTED,
    text  : "Investment executed",
  },
];

export const signupSortByOptions = [
  {
    key   : SORT_BY_OPTION.UP,
    value : SORT_BY_OPTION.UP,
    text  : "Signup date up",
  },
  {
    key   : SORT_BY_OPTION.DOWN,
    value : SORT_BY_OPTION.DOWN,
    text  : "Signup date down",
  },
];

export const actionRequiredSortByOptions = [
  {
    key   : SORT_BY_OPTION.UP,
    value : SORT_BY_OPTION.UP,
    text  : "Action required date up",
  },
  {
    key   : SORT_BY_OPTION.DOWN,
    value : SORT_BY_OPTION.DOWN,
    text  : "Action required date down",
  },
];

export const STEP_COLORS = ["#FF5722", "#FF9C10", "#5D88FD", "#07D58B"];
export const STEP_NAMES = ["Identity verified", "Legal signed", "Funds sent", "Investment executed"];
