import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Menu, Grid, Dropdown, Image } from "semantic-ui-react";
import { NavLink } from "react-router-dom";
import { withStyles } from "@mui/styles";
import classNames from "classnames";

import { Creators as authCreators } from "src/redux/actions/auth";
import { Creators as globalCreators } from "src/redux/actions/global";
import { Creators as userCreators } from "src/redux/actions/user";
import { DIALOG_NAME, ORG_STATUS, ROUTE_PATH, USER_PERMISSION } from "src/constants";
import { isRegSInvestor } from "src/utils/user";
import defaultLogo from "src/assets/images/default/Logo.svg";
import ExternalIconSvg from "src/assets/images/external-icon.svg";
// eslint-disable-next-line import/no-unresolved
import MobileMenuSVG from "-!svg-react-loader!src/assets/images/default/mobile-menu.svg";
// eslint-disable-next-line import/no-unresolved
import CrossSVG from "-!svg-react-loader!src/assets/images/default/cross.svg";
// eslint-disable-next-line import/no-unresolved
import DashboardSVG from "-!svg-react-loader!src/assets/images/dashboard.svg";
// eslint-disable-next-line import/no-unresolved
import FinancialSVG from "-!svg-react-loader!src/assets/images/financial.svg";
// eslint-disable-next-line import/no-unresolved
import StarSVG from "-!svg-react-loader!src/assets/images/star.svg";

import Avatar from "../Avatar";
import FormattedMessage from "../FormattedMessage";
import Button from "../Button";
import { messages } from "./messages";
import "./header.less";
import themeStyles from "./theme";

const style = {
  investBtnStyle: {
    fontSize   : "1em",
    padding    : "0.8em 2.2em",
    margin     : "0 0.8em",
    fontWeight : 600,
  },
  avatarStyle: {
    width     : 45,
    height    : 45,
    margin    : 0,
    boxShadow : "4px 8px 10px rgba(190, 190, 190, 0.118171), 1px 2px 2px #EFEFEF",
  },
  investMobileBtnStyle: {
    fontSize   : "1em",
    padding    : "1em 0em",
    margin     : "0 -0.5em",
    fontWeight : 600,
    width      : "calc(100% + 1em)",
  },
  smallMobileBtnStyle: {
    fontSize   : "1em",
    lineHeight : 1.5,
    padding    : "14px 10px",
    fontWeight : 600,
    textAlign  : "left",
    color      : "#3f3f3f",
    width      : "100%",
  },
  desktopMenuStyle: {
    padding: "4px 10px",
  },
  noPaddingStyle: {
    padding: 0,
  },
  verticalMenuItemStyle: {
    display    : "flex",
    alignItems : "center",
    width      : "100%",
    padding    : "14px 10px",
    margin     : "0 0",
    lineHeight : "20px",
    fontSize   : 14,
  },
  verticalMenuItemSvgStyle: {
    width       : 20,
    height      : 20,
    marginRight : "0.5em",
  },
};

class AppHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = { mobileMenuOpen: false };
  }

  openMobileMenu = (status) => {
    if (status) {
      document.querySelector("body").classList.add("no-scroll");
    }
    else {
      document.querySelector("body").classList.remove("no-scroll");
    }
    this.setState({ mobileMenuOpen: status });
  };

  render() {
    const {
      user: { walletItem, userItem },
      setInvestDialog,
      setCurrentDialog,
      signOut,
      orgConfig,
      classes
    } = this.props;
    const { mobileMenuOpen } = this.state;
    const isRegS = isRegSInvestor(userItem, walletItem);

    return (
      <Grid container className={classNames("app-header", classes.root, classes[orgConfig.id])}>
        <Grid.Row only="computer" style={{ marginTop: 10 }}>
          <Grid.Column>
            <Menu borderless style={style.desktopMenuStyle}>
              <Menu.Item
                as={NavLink}
                to={isRegS ? `/international${ROUTE_PATH.DASHBOARD}` : ROUTE_PATH.DASHBOARD}
                activeclassname="active"
                header
              >
                <img className="logo" src={orgConfig.logo || defaultLogo} alt="logo" />
              </Menu.Item>
              {walletItem && (
                <Menu.Item position="right" style={style.noPaddingStyle}>
                  {orgConfig.status !== ORG_STATUS.LIQUIDATED && (
                    <>
                      <Menu.Item
                        as={NavLink}
                        to={isRegS ? `/international${ROUTE_PATH.DASHBOARD}` : ROUTE_PATH.DASHBOARD}
                        activeclassname="active"
                      >
                        <DashboardSVG />
                        <FormattedMessage {...messages.dashboard} />
                      </Menu.Item>
                      {orgConfig.enablePresentationPage && (
                        <Menu.Item
                          as={NavLink}
                          to={isRegS ? `/international${ROUTE_PATH.OUR_VISION}` : ROUTE_PATH.OUR_VISION}
                          activeclassname="active"
                        >
                          <StarSVG />
                          <FormattedMessage {...messages.whyFairmint} />
                        </Menu.Item>
                      )}
                      {orgConfig.chargebee.fundraising && (
                        <Menu.Item
                          as={NavLink}
                          to={isRegS ? `/international${ROUTE_PATH.OFFERING}` : ROUTE_PATH.OFFERING}
                          activeclassname="active"
                        >
                          <FinancialSVG />
                          <FormattedMessage {...messages.offeringData} />
                        </Menu.Item>
                      )}
                      <Button as="a" primary style={style.investBtnStyle} onClick={() => setInvestDialog(true)}>
                        <FormattedMessage {...messages.investNow} />
                      </Button>
                    </>
                  )}
                  <Menu.Item>
                    {userItem && (
                      <Dropdown trigger={<Avatar />} floating labeled button icon={null}>
                        <Dropdown.Menu>
                          {walletItem.two_factor_enabled ? (
                            <Dropdown.Item onClick={() => setCurrentDialog(DIALOG_NAME.Deactivate2FA)}>
                              <FormattedMessage {...messages.deactivate2FA} />
                            </Dropdown.Item>
                          ) : (
                            <Dropdown.Item onClick={() => setCurrentDialog(DIALOG_NAME.Activate2FA)}>
                              <FormattedMessage {...messages.secureAccount} />
                            </Dropdown.Item>
                          )}
                          <Dropdown.Item as={NavLink} to={isRegS ? `/international${ROUTE_PATH.UPDATE_EMAIL}` : ROUTE_PATH.UPDATE_EMAIL}>
                            <FormattedMessage {...messages.updateMyEmail} />
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => setCurrentDialog(DIALOG_NAME.Diagnostics)}>
                            <FormattedMessage {...messages.diagnostics} />
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => setCurrentDialog(DIALOG_NAME.Withdrawal)}>
                            <FormattedMessage {...messages.withdraw} />
                          </Dropdown.Item>
                          {walletItem.enableTransferToken && (
                            <Dropdown.Item onClick={() => setCurrentDialog(DIALOG_NAME.UserTransferToken)}>
                              Transfer {orgConfig.tokenSymbolDisplay}
                            </Dropdown.Item>
                          )}
                          <Dropdown.Item onClick={() => setCurrentDialog(DIALOG_NAME.Documents)}>
                            <FormattedMessage {...messages.myDocuments} />
                          </Dropdown.Item>
                          <Dropdown.Item as={NavLink} to={isRegS ? `/international${ROUTE_PATH.TRANSACTION_HISTORY}` : ROUTE_PATH.TRANSACTION_HISTORY}>
                            <FormattedMessage {...messages.transactionHistory} />
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => signOut()}>
                            <FormattedMessage {...messages.signOut} />
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    )}
                  </Menu.Item>
                </Menu.Item>
              )}
            </Menu>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row only="mobile tablet" className={mobileMenuOpen ? "mobile-navbar open" : "mobile-navbar"}>
          <Grid.Column width={16}>
            <div className="wrapper">
              <Grid.Column width={16}>
                <Menu borderless className="mobile-navmenu">
                  <Menu.Item
                    as={NavLink}
                    to={isRegS ? `/international${ROUTE_PATH.DASHBOARD}` : ROUTE_PATH.DASHBOARD}
                    activeclassname="active"
                    header
                  >
                    <img className="logo" src={orgConfig.logo || defaultLogo} alt="logo" />
                  </Menu.Item>
                  {walletItem?.permissions?.includes(USER_PERMISSION.ADMIN) && (
                    <Menu.Item
                      as={NavLink}
                      to={ROUTE_PATH.ADMIN}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Image src={ExternalIconSvg} />
                    </Menu.Item>
                  )}
                  {walletItem && (
                    <Menu.Item position="right" style={style.noPaddingStyle}>
                      <Menu.Item as="a" onClick={() => this.openMobileMenu(!mobileMenuOpen)}>
                        {!mobileMenuOpen && <MobileMenuSVG style={{ width: 36, height: 36 }} />}
                        {mobileMenuOpen && <CrossSVG style={{ width: 36, height: 36 }} />}
                      </Menu.Item>
                    </Menu.Item>
                  )}
                </Menu>
              </Grid.Column>
              {mobileMenuOpen && walletItem && (
                <>
                  <Grid.Column width={16} style={{ marginBottom: 20, marginTop: 70 }}>
                    <Button
                      as="a"
                      primary
                      style={style.investMobileBtnStyle}
                      className="mobile-invest-now-btn"
                      onClick={() => {
                        setInvestDialog(true);
                        this.openMobileMenu(false);
                      }}
                    >
                      <FormattedMessage {...messages.investNow} />
                    </Button>
                  </Grid.Column>
                  <Grid.Column width={16} onClick={() => this.openMobileMenu(false)}>
                    <Menu borderless vertical>
                      <Menu.Item style={style.noPaddingStyle}>
                        <Menu.Item
                          as={NavLink}
                          to={isRegS ? `/international${ROUTE_PATH.DASHBOARD}` : ROUTE_PATH.DASHBOARD}
                          activeclassname="active"
                          style={style.verticalMenuItemStyle}
                        >
                          <DashboardSVG style={style.verticalMenuItemSvgStyle} />
                          <FormattedMessage {...messages.dashboard} />
                        </Menu.Item>
                        {orgConfig.status !== ORG_STATUS.LIQUIDATED && (
                          <>
                            {orgConfig.enablePresentationPage && (
                              <Menu.Item
                                as={NavLink}
                                to={isRegS ? `/international${ROUTE_PATH.OUR_VISION}` : ROUTE_PATH.OUR_VISION}
                                activeclassname="active"
                                style={style.verticalMenuItemStyle}
                              >
                                <StarSVG style={style.verticalMenuItemSvgStyle} />
                                <FormattedMessage {...messages.whyFairmint} />
                              </Menu.Item>
                            )}
                            {orgConfig.chargebee.fundraising && (
                              <Menu.Item
                                as={NavLink}
                                to={isRegS ? `/international${ROUTE_PATH.OFFERING}` : ROUTE_PATH.OFFERING}
                                activeclassname="active"
                                style={style.verticalMenuItemStyle}
                              >
                                <FinancialSVG style={style.verticalMenuItemSvgStyle} />
                                <FormattedMessage {...messages.offeringData} />
                              </Menu.Item>
                            )}
                          </>
                        )}
                      </Menu.Item>
                    </Menu>
                  </Grid.Column>
                  <Grid.Column width={16} className="more-actions-column">
                    <div style={style.smallMobileBtnStyle} className="intro">
                      <FormattedMessage {...messages.profileActions} />
                    </div>
                    {walletItem.two_factor_enabled ? (
                      <Button
                        as="a"
                        style={style.smallMobileBtnStyle}
                        onClick={() => setCurrentDialog(DIALOG_NAME.Deactivate2FA)}
                      >
                        <FormattedMessage {...messages.deactivate2FA} />
                      </Button>
                    ) : (
                      <Button
                        as="a"
                        style={style.smallMobileBtnStyle}
                        onClick={() => setCurrentDialog(DIALOG_NAME.Activate2FA)}
                      >
                        <FormattedMessage {...messages.secureAccount} />
                      </Button>
                    )}
                    <Button
                      as={NavLink}
                      style={style.smallMobileBtnStyle}
                      to={isRegS ? `/international${ROUTE_PATH.UPDATE_EMAIL}` : ROUTE_PATH.UPDATE_EMAIL}
                    >
                      <FormattedMessage {...messages.updateMyEmail} />
                    </Button>
                    <Button
                      as="a"
                      style={style.smallMobileBtnStyle}
                      onClick={() => setCurrentDialog(DIALOG_NAME.Diagnostics)}
                    >
                      <FormattedMessage {...messages.diagnostics} />
                    </Button>
                    <Button
                      as="a"
                      style={style.smallMobileBtnStyle}
                      onClick={() => setCurrentDialog(DIALOG_NAME.Withdrawal)}
                    >
                      <FormattedMessage {...messages.withdraw} />
                    </Button>
                    {walletItem.enableTransferToken && (
                      <Button
                        as="a"
                        style={style.smallMobileBtnStyle}
                        onClick={() => setCurrentDialog(DIALOG_NAME.UserTransferToken)}
                      >
                        Transfer {orgConfig.tokenSymbolDisplay}
                      </Button>
                    )}
                    <Button
                      as="a"
                      style={style.smallMobileBtnStyle}
                      onClick={() => setCurrentDialog(DIALOG_NAME.Documents)}
                    >
                      <FormattedMessage {...messages.myDocuments} />
                    </Button>
                    <Button
                      as={NavLink}
                      style={style.smallMobileBtnStyle}
                      to={isRegS ? `/international${ROUTE_PATH.TRANSACTION_HISTORY}` : ROUTE_PATH.TRANSACTION_HISTORY}
                    >
                      <FormattedMessage {...messages.transactionHistory} />
                    </Button>
                    <Button as="a" style={style.smallMobileBtnStyle} onClick={() => signOut()}>
                      <FormattedMessage {...messages.signOut} />
                    </Button>
                  </Grid.Column>
                </>
              )}
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

const mapStateToProps = store => ({
  user      : store.user,
  orgConfig : store.global.orgConfig,
});

const mapDispatchToProps = {
  ...authCreators,
  ...globalCreators,
  ...userCreators,
};

AppHeader.propTypes = {
  user             : PropTypes.object,
  signOut          : PropTypes.func,
  setCurrentDialog : PropTypes.func,
  setInvestDialog  : PropTypes.func,
  orgConfig        : PropTypes.object.isRequired,
  classes          : PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(themeStyles)(AppHeader));
