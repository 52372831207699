import { callLambda, SERVICE_ADMIN_2 } from "src/utils/lambda";
import { Types } from "../actions/admin-risk-factor";

const apis = {};

apis[Types.GET_RISK_FACTORS] = ({ payload }) => callLambda({
  method  : "post",
  service : SERVICE_ADMIN_2,
  url     : "getRiskFactors",
  body    : payload
});

apis[Types.ADD_CATEGORY] = ({ payload }) => callLambda({
  method  : "post",
  service : SERVICE_ADMIN_2,
  url     : "risk_factor/addCategory",
  body    : payload
});

apis[Types.UPDATE_CATEGORY] = ({ payload }) => callLambda({
  method  : "post",
  service : SERVICE_ADMIN_2,
  url     : "risk_factor/updateCategory",
  body    : payload
});

apis[Types.DELETE_CATEGORY] = async ({ id }) => {
  await callLambda({
    method  : "post",
    service : SERVICE_ADMIN_2,
    url     : "risk_factor/deleteCategory",
    body    : { id }
  });
  return id;
};

apis[Types.UPDATE_HEADER] = ({ headers }) => callLambda({
  method  : "post",
  service : SERVICE_ADMIN_2,
  url     : "risk_factor/updateHeader",
  body    : { headers }
});

export default apis;
