import { put, call, takeLatest, all, select } from "redux-saga/effects";
import { NotificationManager } from "react-notifications";

import Messenger from "src/utils/messenger";
import { DIALOG_NAME } from "src/constants";

import { requestCreator, successCreator, failureCreator } from "../utils/action";
import { Types, Creators } from "../actions/user";
import { Creators as globalCreators } from "../actions/global";
import API from "../api/user";

function* sagaAction(action) {
  const type = action.type;
  try {
    yield put(requestCreator(type, {}));
    const payload = yield call(API[type], action);
    yield put(successCreator(type, payload));
  }
  catch (err) {
    console.error(err);
    yield put(failureCreator(type, { err }));
  }
}

function* updateUser(action) {
  const type = action.type;
  try {
    yield put(requestCreator(type, {}));
    const payload = yield call(API[type], action);
    yield put(successCreator(type, payload));
  }
  catch (err) {
    console.error(err);
    yield put(failureCreator(type, { err }));
  }
}

function* updateWallet(action) {
  const type = action.type;
  try {
    yield put(requestCreator(type, {}));
    const payload = yield call(API[type], action);
    yield put(successCreator(type, payload));

    if (action.payload && action.payload.taxFormDoc) {
      yield put(Creators.getCompensations());
    }
  }
  catch (err) {
    console.error(err);
    yield put(failureCreator(type, { err }));
  }
}

function* startBankTransfer(action) {
  const type = action.type;
  try {
    yield put(requestCreator(type, {}));
    const investItem = yield call(API[type], action);
    yield put(successCreator(type, investItem));

    yield put(globalCreators.setFundDialog(false));
  }
  catch (err) {
    console.error(err);
    yield put(failureCreator(type, { err }));
  }
}

function* cancelInvestment(action) {
  const type = action.type;
  try {
    yield put(requestCreator(type, {}));
    const investItem = yield call(API[type], action);
    yield put(successCreator(type, investItem));

    yield put(globalCreators.setFundDialog(false));
  }
  catch (err) {
    console.error(err);
    yield put(failureCreator(type, { err }));
  }
}

function* signAndBuy(action) {
  const type = action.type;
  try {
    yield put(requestCreator(type, {}));
    const investItem = yield call(API[type], action);
    yield put(successCreator(type, investItem));

    yield put(Creators.buyTokens(investItem));
  }
  catch (err) {
    console.error(err);
    yield put(failureCreator(type, { err }));
  }
}

function* deactivate2FA(action) {
  const type = action.type;
  try {
    yield put(requestCreator(type, {}));
    const payload = yield call(API[type], action);
    yield put(successCreator(type, payload));
  }
  catch (err) {
    console.error(err);
    yield put(failureCreator(type, { err, logError: false, showAlert: false }));
  }
}

function* activate2FA(action) {
  const type = action.type;
  try {
    yield put(requestCreator(type, {}));
    const payload = yield call(API[type], action);
    yield put(successCreator(type, payload));
  }
  catch (err) {
    console.error(err);
    yield put(failureCreator(type, { err, logError: false, showAlert: false }));
  }
}

function* getUserAndWallet(action) {
  const type = action.type;
  try {
    yield put(requestCreator(type, {}));
    const payload = yield call(API[type], action);
    yield put(successCreator(type, payload));

    yield put(Creators.getMessengerToken());
  }
  catch (err) {
    console.error(err);
    yield put(failureCreator(type, { err }));
  }
}

function* getMessengerToken(action) {
  const type = action.type;
  try {
    yield put(requestCreator(type, {}));
    const payload = yield call(API[type], action);
    yield put(successCreator(type, payload));

    const { userItem, walletItem } = yield select(state => state.user);
    Messenger.signIn(userItem, walletItem, payload);
  }
  catch (err) {
    console.error(err);
    yield put(failureCreator(type, { err }));
  }
}

function* withdrawCurrency(action) {
  const type = action.type;
  try {
    yield put(requestCreator(type, {}));
    const user = yield select(state => state.user);
    const payload = yield call(API[type], action, user);
    yield put(successCreator(type, payload));
  }
  catch (err) {
    console.error(err);
    yield put(failureCreator(type, { err }));
  }
}

function* userTransferToken(action) {
  const type = action.type;
  try {
    yield put(requestCreator(type, {}));
    const user = yield select(state => state.user);
    const payload = yield call(API[type], action, user);
    yield put(successCreator(type, payload));
  }
  catch (err) {
    console.error(err);
    yield put(failureCreator(type, { err }));
  }
}

function* buyTokens(action) {
  const type = action.type;
  try {
    yield put(requestCreator(type, {}));
    const buyItem = yield call(API[type], action);
    yield put(successCreator(type, buyItem));

    if (buyItem) {
      yield put(globalCreators.setCurrentDialog(DIALOG_NAME.BuySuccess, buyItem));
    }
    else {
      yield put(globalCreators.setFundDialog(true));
    }
  }
  catch (err) {
    console.error(err);
    yield put(failureCreator(type, { err }));
    yield put(globalCreators.setCurrentDialog(null));
  }
}

function* initializeCorg(action) {
  const type = action.type;
  try {
    yield put(requestCreator(type, {}));
    yield call(API[type], action);
    yield put(successCreator(type, {}));

    yield put(Creators.refreshAccountInfo(action.walletAddress));
    yield put(Creators.getOrgBeneficiary());
  }
  catch (err) {
    console.error(err);
    yield put(failureCreator(type, { err }));
    // ToDo: Freeze the app
  }
}

function* requestInvestmentPDF(action) {
  const type = action.type;
  try {
    yield put(requestCreator(type, {}));
    const payload = yield call(API[type], action);
    yield put(successCreator(type, payload));

    yield put(globalCreators.setCurrentDialog(DIALOG_NAME.Documents, payload.success));
  }
  catch (err) {
    console.error(err);
    yield put(failureCreator(type, { err }));
  }
}

function* loadCompensationsAfterAction(action) {
  const type = action.type;
  try {
    yield put(requestCreator(type, {}));
    const payload = yield call(API[type], action);
    yield put(successCreator(type, payload));

    yield put(Creators.getCompensations());
  }
  catch (err) {
    console.error(err);
    yield put(failureCreator(type, { err }));
  }
}

function* getCurrentAllocation(action) {
  const type = action.type;
  try {
    if (!action.hideLoading) {
      yield put(requestCreator(type, {}));
    }
    const payload = yield call(API[type], action);
    yield put(successCreator(type, payload));
  }
  catch (err) {
    console.error(err);
    yield put(failureCreator(type, { err }));
  }
}

function* sendKYCEmailToBO(action) {
  const type = action.type;
  try {
    yield put(requestCreator(type, {}));
    const payload = yield call(API[type], action);
    yield put(successCreator(type, payload));

    NotificationManager.success("We successfully resend an email");
  }
  catch (err) {
    console.error(err);
    yield put(failureCreator(type, { err }));
  }
}

function* checkGeoFence(action) {
  const type = action.type;
  try {
    yield put(requestCreator(type, {}));
    const payload = yield call(API[type], action);
    yield put(successCreator(type, payload));
    if (payload.geoFence?.message) {
      console.log(payload.geoFence?.message);
    }
  }
  catch (err) {
    console.error(err);
    yield put(failureCreator(type, { err }));
  }
}

function* checkIPCountry(action) {
  const type = action.type;
  try {
    yield put(requestCreator(type, {}));
    const payload = yield call(API[type], action);
    yield put(successCreator(type, payload));
    if (payload.message) {
      console.log(payload.message);
    }
  }
  catch (err) {
    console.error(err);
    yield put(failureCreator(type, { err }));
  }
}

export function* userSaga() {
  yield all([
    takeLatest(Types.REFRESH_ORG_INFO,                              sagaAction),
    takeLatest(Types.REFRESH_ACCOUNT_INFO,                          sagaAction),
    takeLatest(Types.REFRESH_USER_AND_WALLET,                       sagaAction),
    takeLatest(Types.SET_WELCOMED,                                  sagaAction),
    takeLatest(Types.VERIFICATION_FINISHED,                         sagaAction),
    takeLatest(Types.GET_TRANSACTIONS,                              sagaAction),
    takeLatest(Types.SEND_CONFIRMATION_CODE,                        sagaAction),
    takeLatest(Types.GET_BERBIX_STATUS,                             sagaAction),
    takeLatest(Types.GET_OWN_LEGAL_ITEMS,                           sagaAction),
    takeLatest(Types.SET_TRANSACTION_READ,                          sagaAction),
    takeLatest(Types.SET_INVEST_ITEM_READ,                          sagaAction),
    takeLatest(Types.REMOVE_PRICE_PROTECTION,                       sagaAction),
    takeLatest(Types.UPDATE_INVEST_ITEM_STATUS,                     sagaAction),
    takeLatest(Types.GET_COMPENSATIONS,                             sagaAction),
    takeLatest(Types.SET_COMPENSATION_READ,                         sagaAction),
    takeLatest(Types.SET_ENROLLMENT_READ,                           sagaAction),
    takeLatest(Types.SET_COMPENSATION_SIGNED,                       sagaAction),
    takeLatest(Types.SET_ENROLLMENT_SIGNED,                         loadCompensationsAfterAction),
    takeLatest(Types.SET_INVITED_ENROLL_ACTION,                     loadCompensationsAfterAction),
    takeLatest(Types.GET_OWN_INVESTMENT_DOC_URL,                    sagaAction),
    takeLatest(Types.GET_OWN_COMP_DOC_URL,                          sagaAction),
    takeLatest(Types.UPDATE_USER,                                   updateUser),
    takeLatest(Types.UPDATE_WALLET,                                 updateWallet),
    takeLatest(Types.ACTIVATE2_FA,                                  activate2FA),
    takeLatest(Types.GET_USER_AND_WALLET,                           getUserAndWallet),
    takeLatest(Types.GET_MESSENGER_TOKEN,                           getMessengerToken),
    takeLatest(Types.DEACTIVATE2_FA,                                deactivate2FA),
    takeLatest(Types.WITHDRAW_CURRENCY,                             withdrawCurrency),
    takeLatest(Types.USER_TRANSFER_TOKEN,                           userTransferToken),
    takeLatest(Types.SIGN_AND_BUY,                                  signAndBuy),
    takeLatest(Types.START_BANK_TRANSFER,                           startBankTransfer),
    takeLatest(Types.CANCEL_INVESTMENT,                             cancelInvestment),
    takeLatest(Types.BUY_TOKENS,                                    buyTokens),
    takeLatest(Types.INITIALIZE_CORG,                               initializeCorg),
    takeLatest(Types.REQUEST_INVESTMENT_PDF,                        requestInvestmentPDF),
    takeLatest(Types.GET_BANK_INFO,                                 sagaAction),
    takeLatest(Types.GET_CURRENT_ALLOCATION,                        getCurrentAllocation),
    takeLatest(Types.GET_ORG_BENEFICIARY,                           sagaAction),
    takeLatest(Types.SEND_KYC_EMAIL_TO_BO,                          sendKYCEmailToBO),
    takeLatest(Types.CHECK_GEO_FENCE,                               checkGeoFence),
    takeLatest(Types.CHECK_IP_COUNTRY,                              checkIPCountry),
  ]);
}
