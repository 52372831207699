import "core-js";
import { Provider } from "react-redux";
import React from "react";
import { createRoot } from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";
import { init } from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import configureStore, { history } from "./redux/configureStore";
import sagas from "./redux/sagas";
import App from "./App";
import "./main.scss";
import "./fomantic/semantic.css";
import config from "./config";

const {
  REACT_APP_DEVELOPMENT,
  REACT_APP_STAGE,
  REACT_APP_ORG
} = process.env;
const REACT_APP_E2E = (REACT_APP_STAGE === "dev" && REACT_APP_ORG === "beem");

if (REACT_APP_DEVELOPMENT !== "true" || REACT_APP_E2E) {
  init({
    environment      : config.REACT_APP_STAGE,
    dsn              : config.sentryDSN,
    integrations     : [new Integrations.BrowserTracing()],
    tracesSampleRate : 1.0,
  });
}

// Initialize store
const store = configureStore();
sagas.forEach(saga => store.runSaga(saga));

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  <Provider store={store}>
    <HelmetProvider>
      <App history={history} />
    </HelmetProvider>
  </Provider>
);
