import config from "src/config";
import { callLambda, SERVICE_1 } from "src/utils/lambda";
import { getChecksumAddress } from "src/utils/validation";
import { Types } from "../actions/auth";

const apis = {};

apis[Types.CHECK_TOKEN] = async (action) => {
  try {
    const { magic } = window;
    console.log("AUTH: Check token");

    if (await magic.user.isLoggedIn()) {
      // Already logged-in
      // Nothing to do
      console.log("AUTH: Already logged-in");
    }
    else {
      throw new Error("Not logged in");
    }

    console.log("AUTH: Get Metadata and Token");
    const [metadata, accessToken] = await Promise.all([
      magic.user.getMetadata(),
      magic.user.getIdToken({ lifespan: config.tokenLifespan }),
    ]);

    console.log("AUTH: Get Invitation code");
    const invitationCode = action.code || undefined;

    return {
      walletAddress : getChecksumAddress(metadata.publicAddress),
      email         : metadata.email,
      accessToken,
      invitationCode
    };
  }
  catch (err) {
    console.log(err);
    throw err;
  }
};

apis[Types.CHECK2_FA_TOKEN] = async (action) => {
  const two_factor_token = window.localStorage.getItem("two_factor_token");
  if (!two_factor_token) {
    throw new Error("No token");
  }
  const response = await callLambda({
    method   : "post",
    service  : SERVICE_1,
    url      : "auth/check2FAToken",
    body     : { two_factor_token },
    unauthed : true
  });
  if (!action.walletAddress && !response.permanent) {
    throw new Error("Invalid token");
  }
  if (action.walletAddress && action.walletAddress !== response.walletAddress) {
    throw new Error("Invalid token");
  }

  return response;
};

apis[Types.CHECK2_FA_CODE] = action => callLambda({
  method  : "post",
  service : SERVICE_1,
  url     : "auth/check2FACode",
  body    : action.data
});

apis[Types.CHECK_EMAIL] = action => callLambda({
  method                : "get",
  service               : SERVICE_1,
  url                   : "auth/checkEmail",
  queryStringParameters : { email: action.email },
  unauthed              : true
});

apis[Types.SIGN_IN] = async (action) => {
  const { magic } = window;
  const { email } = action;

  await magic.auth.loginWithEmailOTP({ email });
};

apis[Types.SIGN_OUT] = async () => {
  await window.magic.user.logout();
  return true;
};

apis[Types.CHECK_INVITATION_CODE] = action => callLambda({
  method   : "post",
  service  : SERVICE_1,
  url      : "checkInvitationCode",
  body     : { code: action.code },
  unauthed : true
});

export default apis;
