/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Menu } from "semantic-ui-react";
import { NavLink, useLocation } from "react-router-dom";

import { SettingIcon, UpArrowIcon } from "src/assets/icons";
import config from "src/config";
import { ROUTE_PATH, APP_STAGE } from "src/constants";

import "./style.less";

const SettingsMenu = () => {
  let menuItems = [
    {
      title : "General",
      to    : ROUTE_PATH.ADMIN_SETTINGS_GENERAL,
    },
    {
      title : "Portal Access",
      to    : ROUTE_PATH.ADMIN_SETTINGS_PORTAL_ACCESS,
    },
    {
      title : "Widget",
      to    : ROUTE_PATH.ADMIN_SETTINGS_WIDGET,
    },
    {
      title : "Integrations",
      to    : ROUTE_PATH.ADMIN_SETTINGS_INTEGRATIONS,
    },
  ];

  const location = useLocation();
  const open = menuItems.map(item => item.to).includes(location.pathname);
  const [expand, setExpand] = useState(open);

  useEffect(() => {
    if (!expand) {
      setExpand(open);
    }
  }, [open]);

  if (config.REACT_APP_STAGE === APP_STAGE.PRODUCTION) {
    menuItems = menuItems.filter(route => !route.devOnly);
  }

  return (
    <div className="settings-menu">
      <Menu.Item className="menu-header" onClick={() => setExpand(!expand)}>
        <div className="flex-wrapper">
          <SettingIcon clickable={false} />
          Settings
        </div>
        <div style={{ transform: !expand && "rotateX(180deg)" }}>
          <UpArrowIcon className="dropdown-icon" />
        </div>
      </Menu.Item>
      {expand && menuItems.map(menu => (
        <Menu.Item key={menu.to} as={NavLink} className="menu-item" to={menu.to}>
          {menu.title}
        </Menu.Item>
      ))}
    </div>
  );
};

export default SettingsMenu;
