/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Menu } from "semantic-ui-react";
import { NavLink, useLocation } from "react-router-dom";

import { DollarIcon, UpArrowIcon } from "src/assets/icons";
import { MENU_COLOR, ROUTE_PATH, USER_PERMISSION } from "src/constants";

import styles from "./style.module.less";

const FundraisingMenu = ({ orgConfig, permissions }) => {
  const location = useLocation();

  const menuItems = [
    {
      title    : "Investments",
      to       : ROUTE_PATH.ADMIN_FUNDRAISING_INVESTMENTS,
      disabled : !(orgConfig.chargebee?.fundraising) || !permissions?.includes(USER_PERMISSION.INVESTMENTS)
    },
    ...orgConfig.communityOffering ? [
      {
        title    : "Compliance",
        to       : ROUTE_PATH.ADMIN_FUNDRAISING_COMPLIANCE,
        disabled : !(orgConfig.chargebee?.fundraising) || !permissions?.includes(USER_PERMISSION.OFFERING)
      },
      {
        title    : "Risk Factors",
        to       : ROUTE_PATH.ADMIN_FUNDRAISING_RISK_FACTORS,
        disabled : !(orgConfig.chargebee?.fundraising) || !permissions?.includes(USER_PERMISSION.OFFERING)
      },
    ] : [
      {
        title    : "Offering",
        to       : ROUTE_PATH.ADMIN_CONTENT_CAFE_OFFERING,
        disabled : !(orgConfig.chargebee?.fundraising) || !permissions?.includes(USER_PERMISSION.OFFERING)
      },
    ],
    {
      title    : "Funding Options",
      to       : ROUTE_PATH.ADMIN_FUNDRAISING_FUNDING_OPTIONS,
      disabled : !(orgConfig.chargebee?.fundraising) || !permissions?.includes(USER_PERMISSION.OFFERING)
    },
  ];

  const open = menuItems.map(item => item.to).includes(location.pathname);
  const [expand, setExpand] = useState(open);

  useEffect(() => {
    if (!expand) {
      setExpand(open);
    }
  }, [open]);

  return (
    <div className={styles.fundraisingMenu}>
      <Menu.Item className={styles.menuHeader} onClick={() => setExpand(!expand)}>
        <div className={styles.flexWrapper}>
          <DollarIcon color={MENU_COLOR.enabled} />
          Fundraising
        </div>
        <div style={{ transform: !expand && "rotateX(180deg)" }}>
          <UpArrowIcon className={styles.dropdownIcon} />
        </div>
      </Menu.Item>
      {expand && menuItems.map(menu => (
        <Menu.Item key={menu.to} as={NavLink} className={styles.menuItem} to={menu.to} disabled={menu.disabled}>
          {menu.title}
        </Menu.Item>
      ))}
    </div>
  );
};

FundraisingMenu.propTypes = {
  orgConfig   : PropTypes.object.isRequired,
  permissions : PropTypes.array,
};

export default FundraisingMenu;
