import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Checkbox } from "@mui/material";

const MuiCheckbox = ({ dispatch, orgConfig, noPadding, fairmintColor, sx, ...props }) => (
  <Checkbox
    {...props}
    sx={{
      color: fairmintColor ? "#4E1EEB" : orgConfig.theme.primary,
      ...(noPadding && {
        padding: 0,
      }),
      "&.Mui-checked": {
        color: fairmintColor ? "#4E1EEB" : orgConfig.theme.primary,
      },
      "&.Mui-disabled": {
        color: "#00000042"
      },
      ...sx
    }}
  />
);

const mapStateToProps = store => ({
  orgConfig: store.global.orgConfig,
});

MuiCheckbox.propTypes = {
  dispatch      : PropTypes.func,
  orgConfig     : PropTypes.object,
  noPadding     : PropTypes.bool,
  fairmintColor : PropTypes.bool,
  sx            : PropTypes.object,
};

export default connect(mapStateToProps)(MuiCheckbox);
