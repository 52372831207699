import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";
import { isMobileOnly, isTablet } from "react-device-detect";
import { Grid, Header, Image, Popup, Icon } from "semantic-ui-react";
import NumberFormat from "react-number-format";
import { withStyles } from "@mui/styles";
import classNames from "classnames";

import { NotificationIcon, HelpIcon, InfoIcon } from "src/assets/icons";
import MobileMenuSvg from "src/assets/images/burger-menu.svg";
import Button from "src/mui-components/Button";
import { ROUTE_PATH, USER_PERMISSION } from "src/constants";
import { isSetupMode } from "src/utility";
import { validateEmail } from "src/utils/validation";

import { Creators as adminNavCreators } from "src/redux/actions/admin-nav";

import InitialAvatar from "../Avatar/InitialAvatar";
import "./style.less";
import themeStyles from "./theme";

const AdminNavTop = ({
  pageName,
  adminNav,
  orgConfig,
  adminContent,
  auth: { permissions },
  user: { contractsData },
  classes,
  updateNavStep,
  setOpenInfo,
  setMobileMenuOpen
}) => {
  const location = useLocation();
  const query = new URLSearchParams(window.location.search).get("q");
  const isEquityPage = location.pathname === ROUTE_PATH.ADMIN_COMMUNITY_EQUITY && validateEmail(query);

  const pageTitle = adminNav.pageName || (
    isEquityPage ? `${pageName} ${query}` : pageName
  );

  const tokenPrice = isSetupMode(orgConfig.status)
    ? adminContent.initialization?.tokenPrice
    : contractsData?.lastTokenPrice.toNumber();

  return (
    <>
      <Grid.Row className={classNames("admin-nav-top", classes.root, classes[orgConfig.id])}>
        <div className="shadow-header">
          {isTablet && (
            <div className="tablet-header">
              <Image className="mobile-icon" src={MobileMenuSvg} onClick={() => setMobileMenuOpen(true)} />
              {orgConfig.logo ? (
                <Image className="logo" src={orgConfig.logo} />
              ) : (
                <div className="name-wrapper">
                  <InitialAvatar name={orgConfig.name} background={orgConfig.theme.primary.slice(1)} />
                  <span>{orgConfig.name.length < 15 ? orgConfig.name : orgConfig.name.slice(0, 15) + "..."}</span>
                </div>
              )}
            </div>
          )}
          <div className={classNames("page-title", { isTablet })}>
            {isEquityPage && (
              <NavLink to={ROUTE_PATH.ADMIN_COMMUNITY}>
                <Icon className="back-icon" name="angle left" />
              </NavLink>
            )}
            {adminNav.step > 0 && (
              <Icon className="back-icon" name="angle left" onClick={() => updateNavStep(adminNav.step - 1)} />
            )}
            <Header as="h2" title={pageTitle}>{pageTitle}</Header>
            {isMobileOnly && (pageName === "Risk factors" || pageName === "Compliance") && (
              <InfoIcon className="info-icon" color={orgConfig.theme.secondary} onClick={() => setOpenInfo(!adminNav.openInfo)} />
            )}
          </div>
          {!isMobileOnly && (
            <div className="right">
              {!isTablet && Boolean(tokenPrice) && (
                <>
                  <div className="badge-wrapper">
                    <div className="badge-outer" />
                    <div className="badge-inner" />
                  </div>
                  <div>
                    <span>{orgConfig.tokenSymbolDisplay}&nbsp;price:&nbsp;</span>
                  </div>
                  <NumberFormat
                    className="price"
                    displayType="text"
                    value={tokenPrice}
                    thousandSeparator
                    fixedDecimalScale
                    decimalScale={2}
                    prefix="$"
                  />
                </>
              )}
              {permissions?.includes(USER_PERMISSION.TRANSACTION) && (
                <Popup
                  content="Notifications"
                  position="left center"
                  trigger={(
                    <Button className="popup-icon" as={NavLink} to={ROUTE_PATH.ADMIN_NOTIFICATION}>
                      <NotificationIcon
                        className="notification-icon"
                        active={location.pathname === ROUTE_PATH.ADMIN_NOTIFICATION}
                        color={orgConfig.theme.primary}
                      />
                    </Button>
                  )}
                />
              )}
              <Popup
                content="Help center"
                position="left center"
                trigger={(
                  <Button
                    className="popup-icon"
                    as={NavLink}
                    to={{ pathname: "https://education.fairmint.com" }}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <HelpIcon className="help-icon" />
                  </Button>
                )}
              />
              {!isTablet && (
                <Button
                  className="investor-view-btn"
                  variant="outlined"
                  onClick={() => window.open(ROUTE_PATH.DASHBOARD, "_blank")}
                >
                  Go to investor view
                </Button>
              )}
            </div>
          )}
        </div>
      </Grid.Row>
    </>
  );
};

const mapStateToProps = store => ({
  orgConfig    : store.global.orgConfig,
  adminContent : store.adminContent,
  adminNav     : store.adminNav,
  auth         : store.auth,
  user         : store.user,
});

const mapDispatchToProps = {
  ...adminNavCreators
};

AdminNavTop.propTypes = {
  pageName          : PropTypes.string,
  orgConfig         : PropTypes.object.isRequired,
  adminContent      : PropTypes.object.isRequired,
  adminNav          : PropTypes.object.isRequired,
  auth              : PropTypes.object.isRequired,
  user              : PropTypes.object.isRequired,
  classes           : PropTypes.object,
  updateNavStep     : PropTypes.func.isRequired,
  setOpenInfo       : PropTypes.func,
  setMobileMenuOpen : PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(themeStyles)(AdminNavTop));
