import { createReducer } from "reduxsauce";
import { Types } from "../actions/admin-offering";
import { success } from "../utils/action";

// Initial State
const initialState = {
  offeringHTML       : "",
  pdfGeneratedBanner : false,
};

const getOfferingHTML = (state, { payload }) => ({ ...state, offeringHTML: payload.data });

const setPdfGeneratedBanner = (state, { show }) => ({ ...state, pdfGeneratedBanner: show });

// map action types to reducer functions
export const handlers = {
  [success(Types.GET_OFFERING_HTML)] : getOfferingHTML,
  [Types.SET_PDF_GENERATED_BANNER]   : setPdfGeneratedBanner,
};

// Export Reducer
export default createReducer(initialState, handlers);
