import { createTheme } from "@mui/material";

const muiTheme = {
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          letterSpacing: 0
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          letterSpacing: 0
        },
      },
    },
  },
  typography: {
    h3: {
      fontSize      : 16,
      lineHeight    : "20px",
      color         : "#202020",
      fontWeight    : 700,
      letterSpacing : 0.5
    },
    body: {
      fontSize      : 14,
      lineHeight    : "18px",
      color         : "#202020",
      fontWeight    : 500,
      letterSpacing : 0
    },
    bodyRegular: {
      fontSize      : 14,
      lineHeight    : "18px",
      color         : "#5F5F5F",
      fontWeight    : 400,
      letterSpacing : 0
    },
    subtitle: {
      fontSize      : 12,
      lineHeight    : "16px",
      color         : "#5F5F5F",
      fontWeight    : 400,
      letterSpacing : 0
    }
  }
};

export default createTheme(muiTheme);
