import { put, call, takeLatest, all } from "redux-saga/effects";
import { NotificationManager } from "react-notifications";

import { requestCreator, successCreator, failureCreator } from "../utils/action";
import { Creators as globalCreators } from "../actions/global";
import { Types } from "../actions/admin-recipient";
import API from "../api/admin-recipient";

function* sagaAction(action) {
  const type = action.type;
  try {
    yield put(requestCreator(type, {}));
    const payload = yield call(API[type], action);
    yield put(successCreator(type, payload));
  }
  catch (err) {
    console.error(err);
    yield put(failureCreator(type, { err }));
  }
}

function* addRecipient(action) {
  const type = action.type;
  try {
    yield put(requestCreator(type, {}));
    const payload = yield call(API[type], action);
    yield put(successCreator(type, payload));

    yield put(globalCreators.setCurrentDialog(null));
  }
  catch (err) {
    console.error(err);
    yield put(failureCreator(type, { err }));
  }
}

function* updateRecipient(action) {
  const type = action.type;
  try {
    yield put(requestCreator(type, {}));
    const payload = yield call(API[type], action);
    yield put(successCreator(type, payload));

    yield put(globalCreators.setCurrentDialog(null));
  }
  catch (err) {
    console.error(err);
    yield put(failureCreator(type, { err }));
  }
}

function* deleteRecipient(action) {
  const type = action.type;
  try {
    yield put(requestCreator(type, {}));
    const payload = yield call(API[type], action);
    yield put(successCreator(type, payload));

    NotificationManager.success(`Successfully deleted!`);
    yield put(globalCreators.setCurrentDialog(null));
  }
  catch (err) {
    console.error(err);
    yield put(failureCreator(type, { err }));
  }
}

export function* adminRecipientSaga() {
  yield all([
    takeLatest(Types.SEARCH_TRANSACTIONS,     sagaAction),
    takeLatest(Types.ADD_RECIPIENT,           addRecipient),
    takeLatest(Types.UPDATE_RECIPIENT,        updateRecipient),
    takeLatest(Types.DELETE_RECIPIENT,        deleteRecipient),
    takeLatest(Types.LIST_RECIPIENTS,         sagaAction),
    takeLatest(Types.SEND_USD_TO_RECIPIENT,   sagaAction),
    takeLatest(Types.TRANSFER_TOKEN,          sagaAction),
  ]);
}
