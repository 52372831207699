import { createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  getAllocations         : ["hideLoading"],
  addAllocation          : ["payload"],
  updateAllocation       : ["payload"],
  updateAllocationStatus : ["payload"],
  resetAllocation        : [],
}, { prefix: "admin_allocation_" });

export { Types, Creators };
