export default {
  root: ({ orgConfig }) => ({
    "&.avatar": {
      "& .avatar-main": {
        background: `${orgConfig.theme.secondary}`
      }
    }
  }),
  fairmint: () => ({
  }),
  erxes: () => ({
  }),
  beem: () => ({
  }),
  endowl: () => ({
  }),
  flux: () => ({
  }),
  gilded: () => ({
  }),
  greenruhm: () => ({
  }),
  hickies: () => ({
    "&.avatar": {
      "& .avatar-main": {
        backgroundColor: "#F5F7FA",

        "& .short-username": {
          "& img": {
            background        : `url("https://static.fairmint.co/images/hickies/Icon-avatar.svg")`,
            display           : "block",
            "-moz-box-sizing" : "border-box",
            boxSizing         : "border-box",
            height            : "24px",
            paddingLeft       : "24px"
          }
        },
      },
    }
  }),
  opengrants: () => ({
  }),
};
