import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Image, Label, Menu, Popup } from "semantic-ui-react";
import { NavLink, useLocation } from "react-router-dom";
import { isMobile } from "react-device-detect";
import NumberFormat from "react-number-format";
import { withStyles } from "@mui/styles";
import classNames from "classnames";

import {
  RocketIcon,
  DashboardIcon,
  MembersIcon,
  PartitionIcon,
  BuildingIcon,
  StatisticsIcon,
  BalanceIcon,
  NotificationIcon,
  HelpIcon
} from "src/assets/icons";
import MobileMenuSvg from "src/assets/images/burger-menu.svg";
import CloseSvg from "src/assets/images/cross-gray.svg";
import ExternalIconSvg from "src/assets/images/external-icon.svg";
import SignoutSvg from "src/assets/images/sign-out.svg";
import { Creators as authCreators } from "src/redux/actions/auth";
import { Creators as glboalCreators } from "src/redux/actions/global";
import { Creators as adminCreators } from "src/redux/actions/admin";
import { Creators as adminContentCreators } from "src/redux/actions/admin-content";
import { Button } from "src/components";
import { DIALOG_NAME, ROUTE_PATH, USER_PERMISSION, ORGANIZATION, MENU_COLOR } from "src/constants";
import { isSetupMode } from "src/utility";

import InitialAvatar from "../Avatar/InitialAvatar";
import ContentMenu from "./ContentMenu";
import EquityGrantMenu from "./EquityGrantMenu";
import FundraisingMenu from "./FundraisingMenu";
import SettingsMenu from "./SettingsMenu";
import ProgressBar from "./ProgressBar";
import "./style.less";
import themeStyles from "./theme";

const AdminMenuItem = ({ to, children, disabled, ...props }) => (
  <Menu.Item
    as={NavLink}
    to={to}
    disabled={disabled}
    activeclassname="active"
    {...props}
  >
    { children }
  </Menu.Item>
);
AdminMenuItem.propTypes = {
  to       : PropTypes.string,
  disabled : PropTypes.bool,
  children : PropTypes.node,
};

const AdminMenu = ({
  location,
  permissions,
  orgConfig,
  actionRequiredWalletsCount,
  inReviewCustomersCount
}) => (
  <>
    <div className="day-to-day-business-section">
      {orgConfig.communityOffering && (
        <AdminMenuItem
          to={ROUTE_PATH.ADMIN_HOME}
          disabled={!(permissions?.includes(USER_PERMISSION.ADMIN))}
        >
          <RocketIcon color={(!permissions?.includes(USER_PERMISSION.ADMIN)) ? MENU_COLOR.disabled : MENU_COLOR.enabled} />
          Home
        </AdminMenuItem>
      )}
      <AdminMenuItem
        to={ROUTE_PATH.ADMIN}
        disabled={!(permissions?.includes(USER_PERMISSION.ADMIN))}
      >
        <DashboardIcon color={(!permissions?.includes(USER_PERMISSION.ADMIN)) ? MENU_COLOR.disabled : MENU_COLOR.enabled} />
        Dashboard
      </AdminMenuItem>
      <AdminMenuItem
        to={ROUTE_PATH.ADMIN_COMMUNITY}
        disabled={!(permissions?.includes(USER_PERMISSION.READ_WALLET))}
        active={location.pathname === ROUTE_PATH.ADMIN_COMMUNITY_EQUITY}
      >
        <MembersIcon color={(!permissions?.includes(USER_PERMISSION.READ_WALLET)) ? MENU_COLOR.disabled : MENU_COLOR.enabled} />
        Community
        {actionRequiredWalletsCount > 0 && <Label circular color="red">{actionRequiredWalletsCount > 9 ? "9+" : actionRequiredWalletsCount}</Label>}
      </AdminMenuItem>
      <FundraisingMenu
        orgConfig={orgConfig}
        permissions={permissions}
      />
      <EquityGrantMenu
        orgConfig={orgConfig}
        permissions={permissions}
      />
      <AdminMenuItem
        to={ROUTE_PATH.ADMIN_FAIRTABLE}
        disabled={!(permissions?.includes(USER_PERMISSION.ADMIN))}
      >
        <PartitionIcon color={(!permissions?.includes(USER_PERMISSION.ADMIN)) ? MENU_COLOR.disabled : MENU_COLOR.enabled} />
        {orgConfig.tokenSymbolDisplay} Table
      </AdminMenuItem>
      {orgConfig.id === ORGANIZATION.FAIRMINT && (
        <AdminMenuItem
          to={ROUTE_PATH.ADMIN_ANALYTICS}
        >
          <StatisticsIcon color={MENU_COLOR.enabled} />
          Analytics
        </AdminMenuItem>
      )}
    </div>
    {orgConfig.id === ORGANIZATION.FAIRMINT && (
      <div className="fairmint-section">
        <AdminMenuItem
          to={ROUTE_PATH.ADMIN_CUSTOMERS}
          disabled={!(permissions?.includes(USER_PERMISSION.ADMIN))}
        >
          <BuildingIcon color={(!permissions?.includes(USER_PERMISSION.ADMIN)) ? MENU_COLOR.disabled : MENU_COLOR.enabled} />
          Customers
          {inReviewCustomersCount > 0 && <Label circular color="red">{inReviewCustomersCount > 9 ? "9+" : inReviewCustomersCount}</Label>}
        </AdminMenuItem>
      </div>
    )}
    <div className="manage-portal-section">
      {orgConfig.communityOffering && (
        <AdminMenuItem
          to={orgConfig.contracts.type ? ROUTE_PATH.ADMIN_ALLOCATIONS : ROUTE_PATH.ADMIN_ALLOCATIONS_1}
          disabled={!permissions?.includes(USER_PERMISSION.OFFERING)}
        >
          <BalanceIcon color={!permissions?.includes(USER_PERMISSION.OFFERING) ? MENU_COLOR.disabled : MENU_COLOR.enabled} />
          Allocations
        </AdminMenuItem>
      )}
      <ContentMenu
        orgConfig={orgConfig}
        disabled={!permissions?.includes(USER_PERMISSION.CONTENT)}
      />
      <SettingsMenu />
    </div>
  </>
);
AdminMenu.propTypes = {
  location                   : PropTypes.object,
  permissions                : PropTypes.array,
  orgConfig                  : PropTypes.any,
  actionRequiredWalletsCount : PropTypes.number,
  inReviewCustomersCount     : PropTypes.number
};

const AdminNavLeft = ({
  auth: { permissions },
  user: { contractsData, userItem, walletItem },
  setCurrentDialog,
  signOut,
  orgConfig,
  adminContent,
  admin: { navLeftStatus },
  getNavLeftStatus,
  goPortalLive,
  classes,
  mobileOpen,
  setMobileMenuOpen
}) => {
  const location = useLocation();
  // const isOrgBeneficiary = walletItem && walletItem.walletAddress === contractsData?.beneficiary;
  const beneficiaryEmail = orgConfig && adminContent
    && (orgConfig.contracts?.beneficiaryEmail || adminContent.initialization?.beneficiaryEmail);
  const isOrgBeneficiary = orgConfig && walletItem && walletItem.email === beneficiaryEmail;
  const tokenPrice = isSetupMode(orgConfig.status)
    ? adminContent.initialization?.tokenPrice
    : contractsData?.lastTokenPrice.toNumber();

  useEffect(() => {
    setMobileMenuOpen(false);
  }, [location, setMobileMenuOpen]);

  useEffect(() => {
    getNavLeftStatus();
  }, [getNavLeftStatus]);

  return (
    <div className={classNames("admin-nav-left", classes.root, classes[orgConfig.id])}>
      {!isMobile ? (
        <>
          <div className="header-wrapper">
            {orgConfig.logo ? (
              <Image className="logo" src={orgConfig.logo} />
            ) : (
              <div className="name-wrapper">
                <InitialAvatar name={orgConfig.name} background={orgConfig.theme.primary.slice(1)} />
                <span>{orgConfig.name.length < 15 ? orgConfig.name : orgConfig.name.slice(0, 15) + "..."}</span>
              </div>
            )}
          </div>
          <div className="scroll-wrapper">
            <Menu borderless vertical>
              <AdminMenu
                location={location}
                permissions={permissions}
                orgConfig={orgConfig}
                actionRequiredWalletsCount={navLeftStatus?.actionRequiredWalletsCount}
                inReviewCustomersCount={navLeftStatus?.inReviewCustomersCount}
              />
            </Menu>
          </div>
          {isSetupMode(orgConfig.status) && <ProgressBar orgConfig={orgConfig} adminContent={adminContent} goPortalLive={goPortalLive} />}
          {userItem && (
            <div className="user-info-wrapper">
              <Popup
                on="hover"
                position="top center"
                content="Diagnostics"
                trigger={(
                  <div className="info-wrapper" onClick={() => setCurrentDialog(DIALOG_NAME.Diagnostics)}>
                    <InitialAvatar name={userItem.firstName + " " + userItem.lastName} background={orgConfig.theme.primary.slice(1)} />
                    <div className="text-wrapper">
                      <p className="email">{userItem.email.length < 20 ? userItem.email : userItem.email.slice(0, 20) + "..."}</p>
                      <p className="role">{isOrgBeneficiary ? "Beneficiary" : "Admin"}</p>
                    </div>
                  </div>
                )}
              />
              <Popup
                on="hover"
                position="top center"
                content="Sign out"
                trigger={<Image className="sign-out" src={SignoutSvg} onClick={() => signOut()} />}
              />
            </div>
          )}
        </>
      ) : (
        <>
          <div className="header-wrapper">
            <div className="left-wrapper">
              {mobileOpen ? (
                <>
                  <Image className="mobile-icon" src={CloseSvg} onClick={() => setMobileMenuOpen(false)} />
                  {Boolean(tokenPrice) && (
                    <div className="token-price">
                      <span>{orgConfig.tokenSymbolDisplay}&nbsp;price:&nbsp;</span>
                      <NumberFormat
                        className="price"
                        displayType="text"
                        value={tokenPrice}
                        thousandSeparator
                        fixedDecimalScale
                        decimalScale={2}
                        prefix="$"
                      />
                    </div>
                  )}
                </>
              ) : (
                <>
                  <Image className="mobile-icon" src={MobileMenuSvg} onClick={() => setMobileMenuOpen(true)} />
                  {orgConfig.logo ? (
                    <Image className="logo" src={orgConfig.logo} />
                  ) : (
                    <div className="name-wrapper">
                      <InitialAvatar name={orgConfig.name} background={orgConfig.theme.primary.slice(1)} />
                      <span>{orgConfig.name.length < 15 ? orgConfig.name : orgConfig.name.slice(0, 15) + "..."}</span>
                    </div>
                  )}
                </>
              )}
            </div>
            <div className="right-wrapper">
              {mobileOpen ? (
                <Button
                  as={NavLink}
                  to={ROUTE_PATH.DASHBOARD}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image src={ExternalIconSvg} />
                </Button>
              ) : (
                <div>
                  {permissions?.includes(USER_PERMISSION.TRANSACTION) && (
                    <Button className="icon" as={NavLink} to={ROUTE_PATH.ADMIN_NOTIFICATION}>
                      <NotificationIcon
                        className="notification-icon"
                        active={location.pathname === ROUTE_PATH.ADMIN_NOTIFICATION}
                        color={orgConfig.theme.primary}
                      />
                    </Button>
                  )}
                  <Button
                    className="icon"
                    as={NavLink}
                    to={{ pathname: "http://help.fairmint.com" }}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <HelpIcon className="help-icon" />
                  </Button>
                </div>
              )}
            </div>
          </div>
          {mobileOpen && (
            <div className="scroll-wrapper">
              <Menu borderless vertical>
                <AdminMenu
                  location={location}
                  permissions={permissions}
                  orgConfig={orgConfig}
                  actionRequiredWalletsCount={navLeftStatus?.actionRequiredWalletsCount}
                  inReviewCustomersCount={navLeftStatus?.inReviewCustomersCount}
                />
              </Menu>
            </div>
          )}
          {mobileOpen && userItem && (
            <>
              {isSetupMode(orgConfig.status)
              && <ProgressBar orgConfig={orgConfig} adminContent={adminContent} goPortalLive={goPortalLive} />}
              <div className="user-info-wrapper">
                <div className="info-wrapper">
                  <div onClick={() => setCurrentDialog(DIALOG_NAME.Diagnostics)}>
                    <InitialAvatar name={userItem.firstName + " " + userItem.lastName} background={orgConfig.theme.primary.slice(1)} />
                  </div>
                  <div className="text-wrapper">
                    <p className="email">{userItem.email.length < 40 ? userItem.email : userItem.email.slice(0, 40) + "..."}</p>
                    <p className="role">{isOrgBeneficiary ? "Beneficiary" : "Admin"}</p>
                    <div className="sign-out-mobile" onClick={() => signOut()}>Log out</div>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

const mapStateToProps = store => ({
  auth         : store.auth,
  admin        : store.admin,
  user         : store.user,
  orgConfig    : store.global.orgConfig,
  adminContent : store.adminContent,
});

const mapDispatchToProps = {
  ...authCreators,
  ...glboalCreators,
  ...adminCreators,
  ...adminContentCreators,
};

AdminNavLeft.propTypes = {
  auth              : PropTypes.object,
  admin             : PropTypes.object,
  user              : PropTypes.object,
  setCurrentDialog  : PropTypes.func,
  signOut           : PropTypes.func,
  orgConfig         : PropTypes.object.isRequired,
  adminContent      : PropTypes.object.isRequired,
  classes           : PropTypes.object,
  mobileOpen        : PropTypes.bool,
  setMobileMenuOpen : PropTypes.func,
  getNavLeftStatus  : PropTypes.func.isRequired,
  goPortalLive      : PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(themeStyles)(AdminNavLeft));
