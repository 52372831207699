import { ROUTE_PATH } from "src/constants";

export default function getPageName({ orgConfig, path }) {
  const pageNames = {
    [ROUTE_PATH.ADMIN_HOME]                        : "Home",
    [ROUTE_PATH.ADMIN]                             : "Dashboard",
    [ROUTE_PATH.ADMIN_CONTENT_LOGIN]               : "Content: Signup and Login Page",
    [ROUTE_PATH.ADMIN_CONTENT_WELCOME]             : "Content: Welcome Flow",
    [ROUTE_PATH.ADMIN_CONTENT_DASHBOARD]           : "Content: Dashboard",
    [ROUTE_PATH.ADMIN_CONTENT_WHYPAGE]             : `Content: Why ${orgConfig.name}`,
    [ROUTE_PATH.ADMIN_CONTENT_PRESENTATION]        : `Content: Why ${orgConfig.name}`,
    [ROUTE_PATH.ADMIN_CONTENT_OFFERING]            : "Content: Offering Page",
    [ROUTE_PATH.ADMIN_CONTENT_GENERAL]             : "Content: General",
    [ROUTE_PATH.ADMIN_FUNDRAISING_INVESTMENTS]     : "Fundraising: Investments",
    [ROUTE_PATH.ADMIN_FUNDRAISING_COMPLIANCE]      : "Fundraising: Compliance",
    [ROUTE_PATH.ADMIN_FUNDRAISING_RISK_FACTORS]    : "Fundraising: Risk Factors",
    [ROUTE_PATH.ADMIN_CONTENT_CAFE_OFFERING]       : "Fundraising: Offering",
    [ROUTE_PATH.ADMIN_FUNDRAISING_FUNDING_OPTIONS] : "Fundraising: Funding Options",
    [ROUTE_PATH.ADMIN_ALLOCATIONS]                 : "Allocations",
    [ROUTE_PATH.ADMIN_ALLOCATIONS_1]               : "Allocations",
    [ROUTE_PATH.ADMIN_CUSTOMERS]                   : "Customers",
    [ROUTE_PATH.ADMIN_FAIRTABLE]                   : `${orgConfig.tokenSymbolDisplay} Table`,
    [ROUTE_PATH.ADMIN_TRANSFER_MONEY]              : "Transfer Money",
    [ROUTE_PATH.ADMIN_ANALYTICS]                   : "Analytics",
    [ROUTE_PATH.ADMIN_EQUITY_GRANT_COMPENSATION]   : "Grants: Equity Grants",
    [ROUTE_PATH.ADMIN_EQUITY_GRANT_POOLS]          : "Grants: Pools",
    [ROUTE_PATH.ADMIN_EQUITY_GRANT_LEGAL]          : "Grants: Legal",
    [ROUTE_PATH.ADMIN_NOTIFICATION]                : "Notifications",
    [ROUTE_PATH.ADMIN_SETTINGS_GENERAL]            : "Settings: General",
    [ROUTE_PATH.ADMIN_SETTINGS_PORTAL_ACCESS]      : "Settings: Portal Access",
    [ROUTE_PATH.ADMIN_SETTINGS_WIDGET]             : "Settings: Widget",
    [ROUTE_PATH.ADMIN_SETTINGS_INTEGRATIONS]       : "Settings: Integrations",
    [ROUTE_PATH.ADMIN_COMMUNITY]                   : "Community",
    [ROUTE_PATH.ADMIN_COMMUNITY_EQUITY]            : "Grant Equity to",
    [ROUTE_PATH.ADMIN_CRAFT_COMPENSATION_PLAN_DOC] : "Craft your compensation plan",
    [ROUTE_PATH.ADMIN_CRAFT_ECA_DOC]               : "Reward Type",
    [ROUTE_PATH.ADMIN_CRAFT_SPC]                   : "Craft your service provider contract",
  };
  return pageNames[path] || "";
}
