import { createReducer } from "reduxsauce";
import { Types } from "../actions/admin-equity";
import { request, success } from "../utils/action";

// Initial State
const initialState = {
  stakeholder : null,
  enrollments : [],
  pools       : null,
  contracts   : [],
};

const initSearchWallet = state => ({
  ...state,
  stakeholder: null,
});

const searchWallet = (state, action) => ({
  ...state,
  stakeholder: action.payload.Items[0],
});

const initUserEnrollments = state => ({
  ...state,
  enrollments: [],
});

const getUserEnrollments = (state, action) => ({
  ...state,
  enrollments: action.payload,
});

const listPools = (state, { payload }) => ({
  ...state,
  pools: payload.pools || [],
});

const getOrgContracts = (state, { payload }) => ({
  ...state,
  contracts: payload.contracts,
});

// map action types to reducer functions
export const handlers = {
  [request(Types.SEARCH_WALLET)]        : initSearchWallet,
  [success(Types.SEARCH_WALLET)]        : searchWallet,
  [request(Types.GET_USER_ENROLLMENTS)] : initUserEnrollments,
  [success(Types.GET_USER_ENROLLMENTS)] : getUserEnrollments,
  [success(Types.GET_ORG_CONTRACTS)]    : getOrgContracts,
  [success(Types.LIST_POOLS)]           : listPools,
};

// Export Reducer
export default createReducer(initialState, handlers);
