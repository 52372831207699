import React from "react";
import PropTypes from "prop-types";
import { IntlProvider as ReactIntlProvider } from "react-intl";

import enMessages from "src/assets/locale/en.json";

const IntlProvider = ({ children }) => (
  <ReactIntlProvider locale="en" messages={enMessages}>
    {children}
  </ReactIntlProvider>
);

IntlProvider.propTypes = {
  children: PropTypes.node
};

export default IntlProvider;
