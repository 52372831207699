import { ORGANIZATION } from "src/constants";

const { REACT_APP_ORG, REACT_APP_STAGE } = process.env;

let domain;
if (REACT_APP_ORG && REACT_APP_ORG !== "NONE") {
  domain = REACT_APP_ORG === ORGANIZATION.FAIRMINT
      ? `dev.fairmint.co`
      : `dev-${REACT_APP_ORG}.fairmint.co`;
}
else if (window.location.hostname.includes("amplifyapp.com")) {
  // PR preview works on dev-fairmint environment
  domain = "dev-rocket.fairmint.co";
}
else {
  domain = window.location.hostname;
}

const config = {
  dev: {
    apiEndpoint : "https://api.dev.fairmint.co",
    moonpay     : {
      baseUrl      : "https://buy-staging.moonpay.io",
      apiKey       : "pk_test_XmyJznqkdDR7hpLrvsHUXL1mrDaNoP9J",
      currencyCode : "usdc",
      fee          : 1.05
    },
    ramp: {
      baseUrl : "https://ri-widget-staging.firebaseapp.com",
      apiKey  : "4e98smwtm64bfgd8w8cq5vt5ab9gb65uyobr9gtp"
    },
    explorer: {
      ethereum : "https://goerli.etherscan.io",
      optimism : "https://goerli-optimism.etherscan.io",
    },
    operatorAddress  : "0xbe1cB4e27cb7946C10D7a909465b56feF9439D80",
    mcAddress        : "0x9878aA70ceEBb77bE08951033286Ea96cE8960A9",
    techSupportEmail : "fairmint.support@yopmail.com",
    simulatorLink    : "https://simulator.fairmint.com",
    gaTrackingId     : "G-Y7C2V55YRB",
    chargebee        : {
      site           : "fairmint-test",
      publishableKey : "test_kJ4zZywjcu0OZJhmIRV0Pd0D8OPsXZvHO"
    },
    entriApplicationId: "fairmint_demo"
  },
  production: {
    apiEndpoint : "https://api.invest.fairmint.co",
    moonpay     : {
      baseUrl      : "https://buy.moonpay.io",
      apiKey       : "pk_live_RFgwGU1DoYMlsbIeNUJ8uj2nR3TJm",
      currencyCode : "usdc",
      fee          : 1.05
    },
    ramp: {
      baseUrl : "https://buy.ramp.network",
      apiKey  : "oxwrf2rogtam67wkewtkjmvwrtbuykwrt8rh2gt3"
    },
    explorer: {
      ethereum : "https://etherscan.io",
      optimism : "https://optimistic.etherscan.io",
    },
    operatorAddress  : "0x4c03718e7dEA0DC91331993C4C26457A687C4bEF",
    mcAddress        : "0xB21ffAB64Ca013B4a6516AAda0f8988409C411cd",
    techSupportEmail : "techsupport@fairmint.co",
    simulatorLink    : "https://simulator.fairmint.com",
    gaTrackingId     : "G-Y7C2V55YRB",
    gtmId            : "GTM-KWRL9X9",
    chargebee        : {
      site           : "fairmint",
      publishableKey : "live_cd9NfHkYYhUpw14oSX684JMQ5MtyPBY5b"
    },
    entriApplicationId: "fairmint"
  },
  common: {
    domain,
    amplify: {
      Auth: {
        identityPoolId      : "us-east-1:26d05b9c-8b31-47ef-9b2c-455ea23f6224",
        region              : "us-east-1",
        userPoolId          : "us-east-1_UxRZ9jehF",
        userPoolWebClientId : "5s6c6erhscsl4db5q79chufk9t"
      }
    },
    IoTProvider: {
      aws_pubsub_region   : "us-east-1",
      aws_pubsub_endpoint : "wss://a3fj5ilcbrf7hb-ats.iot.us-east-1.amazonaws.com/mqtt"
    },
    sentryDSN            : "https://3423460fdddc4022b7e41493c4fe9dda@o320781.ingest.sentry.io/1813639",
    notificationDuration : 1000 * 1000,
    tokenLifespan        : 3 * 24 * 3600,       // 3 days
    recaptchaKey         : "6LfmS8cUAAAAAIzRjchFVZgmJhU9fqZ15IfdAToW",
    verifyInvestor       : "https://verifyinvestor.com",
    accreditedAm         : "https://accredited.am",
    webflowURL           : "https://fairmint.com",
  }
};

const appConfig = {
  ...config.common,
  ...config[REACT_APP_STAGE],
  ...process.env,
};

export default appConfig;
