import { put, call, takeLatest, all, select } from "redux-saga/effects";
import { requestCreator, successCreator, failureCreator } from "../utils/action";
import { Types } from "../actions/admin-investment";
import API from "../api/admin-investment";

function* sagaAction(action) {
  const type = action.type;
  try {
    yield put(requestCreator(type, {}));
    const payload = yield call(API[type], action);
    yield put(successCreator(type, payload));
  }
  catch (err) {
    console.error(err);
    yield put(failureCreator(type, { err }));
  }
}

function* searchInvestments(action) {
  const type = action.type;

  const { lastItemKey, investments } = yield select(state => state.adminInvestment);

  try {
    yield put(requestCreator(type, {}));

    const search = action.searchParams;

    if (lastItemKey) {
      search.ExclusiveStartKey = lastItemKey;
    }

    const payload = yield call(API[type], search);

    if (search.isMore) {
      payload.Items = [...investments, ...payload.Items];
    }

    yield put(successCreator(type, payload));
  }
  catch (err) {
    console.error(err);
    yield put(failureCreator(type, { err }));
  }
}

export function* adminInvestmentSaga() {
  yield all([
    takeLatest(Types.SEARCH_INVESTMENTS, searchInvestments),
    takeLatest(Types.CALL_MANUAL_BUY, sagaAction),
    takeLatest(Types.DELETE_INVESTMENT, sagaAction),
    takeLatest(Types.CANCEL_INVESTMENT, sagaAction),
    takeLatest(Types.MAKE_OFF_CHAIN_INVESTMENT, sagaAction),
  ]);
}
