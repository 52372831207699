import React, { useMemo } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ReactMarkdown from "react-markdown";
import { withStyles } from "@mui/styles";
import { Grid } from "semantic-ui-react";
import classNames from "classnames";

import { isRegSInvestor } from "src/utils/user";
import themeStyles from "./theme";

const Disclaimer = ({ orgConfig, user: { userItem, walletItem }, classes }) => {
  const isRegS = useMemo(() => isRegSInvestor(userItem, walletItem), [userItem, walletItem]);

  return (
    <Grid container className={classNames(classes.root, classes[orgConfig.id])}>
      <Grid.Row>
        <Grid.Column width={16}>
          <p><b>***DISCLAIMERS***</b></p>
          {isRegS && (
            <p>
              <b>
                THE COMPANY IS OFFERING SECURITIES THROUGH THIS PAGE ONLY OUTSIDE OF THE UNITED STATES TO NON-U.S. PERSONS
                WHO ARE NOT ACQUIRING SECURITIES FOR THE ACCOUNT OR BENEFIT OF A U.S. PERSON, PURSUANT TO THE PROVISIONS
                OF REGULATION S OF THE SECURITIES ACT OF 1933, AS AMENDED (THE “SECURITIES ACT”). THE SECURITIES WILL NOT
                BE REGISTERED UNDER THE SECURITIES ACT, AND MAY NOT BE OFFERED OR SOLD IN THE UNITED STATES ABSENT
                REGISTRATION UNDER THE SECURITIES ACT OR AN APPLICABLE EXEMPTION THEREFROM. HEDGING TRANSACTIONS INVOLVING
                THE SECURITIES MAY NOT BE CONDUCTED UNLESS IN COMPLIANCE WITH THE SECURITIES ACT.
              </b>
            </p>
          )}
          <ReactMarkdown source={orgConfig.pages.disclaimer} linkTarget="_blank" />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

Disclaimer.propTypes = {
  orgConfig : PropTypes.object.isRequired,
  user      : PropTypes.object,
  classes   : PropTypes.object,
};

const mapStateToProps = store => ({
  orgConfig : store.global.orgConfig,
  user      : store.user,
});

export default connect(mapStateToProps, null)(withStyles(themeStyles)(Disclaimer));
