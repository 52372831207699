import { defineMessages } from "react-intl";
import mapValues from "lodash/mapValues";

export function createMessages(prefix, messages) {
  const prefixedMessages = mapValues(
    messages,
    (value, key) => ({
      description    : value.description,
      defaultMessage : value.defaultMessage,
      id             : `${prefix}.${key}`,
    })
  );
  return defineMessages(prefixedMessages);
}
