import { createReducer } from "reduxsauce";
import { Types } from "../actions/admin-compensation";
import { request, success } from "../utils/action";

// Initial State
const initialState = {
  compensationHTML  : "",
  ecaDocHTML        : "",
  stakeholderWallet : null,
  compensations     : [],
  enrollments       : [],
  events            : [],
};

const getCompensationHTML = (state, { payload }) => ({ ...state, compensationHTML: payload.data });

const getECADocHTML = (state, { payload }) => ({ ...state, ecaDocHTML: payload.data });

const searchWallets = (state, action) => ({
  ...state,
  stakeholderWallet: action.payload.Items[0],
});

const initUserEnrollments = state => ({
  ...state,
  enrollments: [],
});

const getUserEnrollments = (state, action) => ({
  ...state,
  enrollments: action.payload,
});

const searchCompensations = (state, action) => ({ ...state, compensations: action.payload.Items });

const deleteCompensation = (state, action) => ({
  ...state,
  compensations: state.compensations.filter(({ id }) => id !== action.payload.id)
});

const getCompensationEvents = (state, action) => ({
  ...state,
  events: action.payload.Items,
});

// map action types to reducer functions
export const handlers = {
  [success(Types.GET_COMPENSATION_HTML)]   : getCompensationHTML,
  [success(Types.GET_ECA_DOC_HTML)]        : getECADocHTML,
  [success(Types.SEARCH_WALLET)]           : searchWallets,
  [success(Types.SEARCH_COMPENSATIONS)]    : searchCompensations,
  [success(Types.DELETE_COMPENSATION)]     : deleteCompensation,
  [request(Types.GET_USER_ENROLLMENTS)]    : initUserEnrollments,
  [success(Types.GET_USER_ENROLLMENTS)]    : getUserEnrollments,
  [success(Types.GET_COMPENSATION_EVENTS)] : getCompensationEvents,
};

// Export Reducer
export default createReducer(initialState, handlers);
