import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { DialogTitle, DialogContent, DialogActions, FormControlLabel, IconButton, Typography, List } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import dayjs from "dayjs";

import { Creators as userCreators } from "src/redux/actions/user";
import { Button, Checkbox, Dialog } from "src/mui-components";
import { numberWithCommas } from "src/utility";
import { S_CONTRACT_TYPE } from "src/constants";

const GoLiveConfirmModal = ({ orgConfig, onGoLive, onClose, getCurrentAllocation, adminContent, allocation, open }) => {
  const {
    companyInfo,
    initialization: { valuation: initValuation, tokenPrice: initTokenPrice, equityAllocation, beneficiaryEmail },
  } = adminContent;

  const [invitationOnlyMode, setInvitationOnlyMode] = useState(!companyInfo.addInvestButton);

  const { dollarAmount, averageValuation, startDateString } = useMemo(() => {
    if (orgConfig.communityOffering && allocation) {
      const {
        params: { dollarAmount: _dollarAmount, startPrice, endPrice, startDate },
      } = allocation;
      const averageVal = ((initValuation / initTokenPrice) * (startPrice + endPrice)) / 2;
      const startDateStr = startDate && new Date(startDate).getTime() > Date.now()
          ? `on ${dayjs(startDate).format("MM-DD-YYYY")}`
          : "as soon as your portal is live";
      return { dollarAmount: _dollarAmount, averageValuation: averageVal, startDateString: startDateStr };
    }
    return { dollarAmount: null, averageValuation: null, startDateString: null };
  }, [allocation, initTokenPrice, initValuation, orgConfig.communityOffering]);

  const getValuationLabel = () => {
    if (!orgConfig.communityOffering) return "Valuation floor";
    if (orgConfig.contracts.type === S_CONTRACT_TYPE.COMMUNITY_SAFE) return "Post-money valuation cap";
    return "Valuation";
  };

  useEffect(() => {
    getCurrentAllocation();
  }, [getCurrentAllocation]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm" sx={{ "& .MuiDialog-paper": { padding: "36px" } }}>
      <DialogTitle
        sx={{
          fontSize      : { xs: 18, sm: 26 },
          fontWeight    : 600,
          color         : "#3f3f3f",
          p             : 0,
          textAlign     : "center",
          letterSpacing : 0
        }}
      >
        Are you sure you're ready to go live?
        <IconButton sx={{ position: "absolute", right: 8, top: 8, color: "#5F5F5F" }} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ p: 0, my: 2 }}>
        <Typography sx={{ fontSize: { xs: 14, sm: 16 }, lineHeight: "28px", pb: 2, letterSpacing: 0 }}>
          {orgConfig.communityOffering
            ? "Once you click the button below to go live, we will start your smart contract's deployment process."
            : "Once you click the button below to go live, we will start the deploy process for your smart contract."}
        </Typography>
        <Typography sx={{ fontSize: { xs: 14, sm: 16 }, lineHeight: "28px", letterSpacing: 0 }}>
          {orgConfig.communityOffering && orgConfig.chargebee?.fundraising && `Make sure that you set the right values for your ${orgConfig.contracts.name} offering, as after you go live, you won't be able to change them:`}
          {orgConfig.communityOffering && !orgConfig.chargebee?.fundraising && `Make sure that you set the right values for your portal, as after you go live, you won't be able to change them:`}
          {!orgConfig.communityOffering && "Changing the Rolling SAFE parameters after this will require an extra fee. Make sure they are ok:"}
        </Typography>
        <List component="ul" sx={{ listStyle: "inside", fontSize: { xs: 14, sm: 16 }, pl: 2 }}>
          <li>Token:&nbsp;&nbsp;<b>{orgConfig.tokenSymbol}</b></li>
          <li>
            {getValuationLabel()}:&nbsp;&nbsp;
            <b>${numberWithCommas(initValuation)}</b>
          </li>
          {!orgConfig.communityOffering && (
            <li>
              Initial equity allocation:&nbsp;&nbsp;
              <b>{numberWithCommas(equityAllocation)}%</b>
            </li>
          )}
          {orgConfig.chargebee?.fundraising && (
            <>
              <li>
                Initial {orgConfig.tokenSymbolDisplay} price:&nbsp;&nbsp;
                <b>${numberWithCommas(initTokenPrice)}</b>
              </li>
              <li>
                Beneficiary account:&nbsp;&nbsp;
                <b className="email-text">{beneficiaryEmail}</b>
              </li>
            </>
          )}

        </List>
        {orgConfig.communityOffering && (
          <Typography sx={{ fontSize: { xs: 14, sm: 16 }, lineHeight: "28px", letterSpacing: 0 }}>
            In the first allocation you are set to allocate ${numberWithCommas(dollarAmount)} at an average valuation of $
            {numberWithCommas(averageValuation)}. Your allocation will start {startDateString}.
          </Typography>
        )}
        <FormControlLabel
          control={<Checkbox checked={invitationOnlyMode} onChange={() => setInvitationOnlyMode(prev => !prev)} />}
          label="Keep my portal in invitation-only mode after it's live"
        />
      </DialogContent>
      <DialogActions sx={{ display: { xs: "block", sm: "flex" }, justifyContent: "space-between", p: 0 }}>
        <Button
          size="large"
          onClick={() => onGoLive(invitationOnlyMode)}
          sx={{
            fontWeight : "bold",
            width      : { xs: "100%", sm: "fit-content" },
            margin     : { xs: "0 0 20px", sm: "0" },
            order      : { sm: 1 }
          }}
        >
          Go live
        </Button>
        <Typography
          onClick={onClose}
          sx={{
            fontSize       : 13,
            cursor         : "pointer",
            color          : orgConfig.theme.primary,
            textDecoration : "underline",
            textAlign      : "center",
            letterSpacing  : 0
          }}
        >
          I want to edit the information
        </Typography>
      </DialogActions>
    </Dialog>
  );
};

GoLiveConfirmModal.propTypes = {
  open                 : PropTypes.bool,
  onGoLive             : PropTypes.func.isRequired,
  onClose              : PropTypes.func.isRequired,
  getCurrentAllocation : PropTypes.func,
  orgConfig            : PropTypes.object,
  adminContent         : PropTypes.object,
  allocation           : PropTypes.object,
};

const mapStateToProps = store => ({
  allocation: store.user.allocation,
});

const mapDispatchToProps = {
  ...userCreators,
};

export default connect(mapStateToProps, mapDispatchToProps)(GoLiveConfirmModal);
