import { callLambda, SERVICE_ADMIN } from "src/utils/lambda";
import { Types } from "../actions/admin-dashboard";

const apis = {};

apis[Types.SEARCH_INVESTMENTS] = action => callLambda({
  method  : "post",
  service : SERVICE_ADMIN,
  url     : "searchInvestments",
  body    : {
    type      : action.payload.filterType,
    status    : action.payload.filterStatus,
    pageLimit : action.payload.pageLimit
  }
});

apis[Types.SEARCH_WALLETS] = action => callLambda({
  method  : "post",
  service : SERVICE_ADMIN,
  url     : "searchWallets",
  body    : action.payload
});

apis[Types.GET_EVENTS] = action => callLambda({
  method  : "post",
  service : SERVICE_ADMIN,
  url     : `getAdminEvents`,
  body    : action.payload
});

apis[Types.GET_INVESTMENT_INTENTIONS] = () => callLambda({
  method  : "post",
  service : SERVICE_ADMIN,
  url     : "getInvestmentIntentions",
});

apis[Types.SEARCH_COMPENSATIONS] = ({ payload }) => callLambda({
  method  : "post",
  service : SERVICE_ADMIN,
  url     : "searchCompensations",
  body    : payload
});

export default apis;
