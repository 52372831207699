import React, { lazy, useMemo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Switch, Route, Redirect } from "react-router-dom";

import Navigator from "src/components/Navigator";
import config from "src/config";
import { ROUTE_PATH, AUTH_LEVEL, USER_PERMISSION, APP_STAGE } from "src/constants";
import Layout from "src/layout";
import { isSetupMode } from "src/utility";
import { isRegSInvestor } from "src/utils/user";

const LegalPage = lazy(() => import("src/pages/LegalPage"));

const allRoutes = [
  {
    exact          : true,
    path           : ROUTE_PATH.ROOT,
    componentLevel : AUTH_LEVEL.FREE,
    component      : lazy(() => import("src/pages/Root")),
  },
  {
    exact          : true,
    path           : ROUTE_PATH.SIGN_IN,
    componentLevel : AUTH_LEVEL.SIGN_IN,
    component      : lazy(() => import("src/pages/SignIn")),
  },
  {
    exact          : true,
    path           : ROUTE_PATH.SIGN_IN_DISCOURSE,
    componentLevel : AUTH_LEVEL.SIGN_IN,
    component      : lazy(() => import("src/pages/SignIn")),
  },
  {
    exact          : true,
    path           : ROUTE_PATH.SUBMIT_DOCUMENT,
    componentLevel : AUTH_LEVEL.SIGN_IN,
    component      : lazy(() => import("src/pages/SubmitDocument")),
  },
  {
    exact          : true,
    path           : ROUTE_PATH.TWO_FACTOR,
    componentLevel : AUTH_LEVEL.TWO_FACTOR,
    component      : lazy(() => import("src/pages/2FA")),
  },
  {
    exact          : true,
    path           : ROUTE_PATH.CROWDFUNDING,
    componentLevel : AUTH_LEVEL.FREE,
    component      : lazy(() => import("src/pages/Crowdfunding"))
  },
  {
    exact          : true,
    path           : ROUTE_PATH.PRESENTATION,
    componentLevel : AUTH_LEVEL.FREE,
    component      : lazy(() => import("src/pages/Presentation")),
  },
  {
    exact          : true,
    hasFooter      : true,
    path           : ROUTE_PATH.DASHBOARD,
    componentLevel : AUTH_LEVEL.DASHBOARD,
    component      : lazy(() => import("src/pages/Dashboard")),
  },
  {
    exact          : true,
    hasFooter      : true,
    path           : ROUTE_PATH.OUR_VISION,
    componentLevel : AUTH_LEVEL.DASHBOARD,
    component      : lazy(() => import("src/pages/WhyFairmint")),
  },
  {
    exact          : true,
    hasFooter      : true,
    path           : ROUTE_PATH.OFFERING,
    componentLevel : AUTH_LEVEL.DASHBOARD,
    component      : lazy(() => import("src/pages/OfferingSummaryCafe")),
  },
  {
    exact          : true,
    hasFooter      : false,
    path           : ROUTE_PATH.TRANSACTION_HISTORY,
    componentLevel : AUTH_LEVEL.DASHBOARD,
    component      : lazy(() => import("src/pages/TransactionHistory")),
  },
  {
    exact          : true,
    hasFooter      : false,
    path           : ROUTE_PATH.UPDATE_EMAIL,
    componentLevel : AUTH_LEVEL.DASHBOARD,
    component      : lazy(() => import("src/pages/UpdateEmail")),
  },
  {
    exact          : true,
    hasFooter      : true,
    path           : ROUTE_PATH.KYB,
    componentLevel : AUTH_LEVEL.DASHBOARD,
    component      : lazy(() => import("src/pages/KYBFlow")),
  },
  {
    exact          : true,
    path           : ROUTE_PATH.ADMIN_HOME,
    componentLevel : AUTH_LEVEL.ADMIN,
    permission     : USER_PERMISSION.ADMIN,
    component      : lazy(() => import("src/pages/Admin/Home")),
  },
  {
    exact          : true,
    path           : ROUTE_PATH.ADMIN,
    componentLevel : AUTH_LEVEL.ADMIN,
    permission     : USER_PERMISSION.ADMIN,
    component      : lazy(() => import("src/pages/Admin/Dashboard")),
  },
  {
    exact          : true,
    path           : ROUTE_PATH.ADMIN_CONTENT_LOGIN,
    componentLevel : AUTH_LEVEL.ADMIN,
    permission     : USER_PERMISSION.CONTENT,
    component      : lazy(() => import("src/pages/Admin/Content/SignupAndLoginPage")),
  },
  {
    exact          : true,
    path           : ROUTE_PATH.ADMIN_CONTENT_WELCOME,
    componentLevel : AUTH_LEVEL.ADMIN,
    permission     : USER_PERMISSION.CONTENT,
    component      : lazy(() => import("src/pages/Admin/Content/WelcomeFlow")),
  },
  {
    exact          : true,
    path           : ROUTE_PATH.ADMIN_CONTENT_DASHBOARD,
    componentLevel : AUTH_LEVEL.ADMIN,
    permission     : USER_PERMISSION.CONTENT,
    component      : lazy(() => import("src/pages/Admin/Content/Dashboard")),
  },
  {
    exact          : true,
    path           : ROUTE_PATH.ADMIN_CONTENT_WHYPAGE,
    componentLevel : AUTH_LEVEL.ADMIN,
    permission     : USER_PERMISSION.CONTENT,
    component      : lazy(() => import("src/pages/Admin/Content/WhyPage")),
  },
  {
    exact          : true,
    path           : ROUTE_PATH.ADMIN_CONTENT_PRESENTATION,
    componentLevel : AUTH_LEVEL.ADMIN,
    permission     : USER_PERMISSION.CONTENT,
    component      : lazy(() => import("src/pages/Admin/Content/WhyPage")),
  },
  {
    exact          : true,
    path           : ROUTE_PATH.ADMIN_CONTENT_OFFERING,
    componentLevel : AUTH_LEVEL.ADMIN,
    permission     : USER_PERMISSION.CONTENT,
    component      : lazy(() => import("src/pages/Admin/Content/FinancialData")),
  },
  {
    exact          : true,
    path           : ROUTE_PATH.ADMIN_CONTENT_GENERAL,
    componentLevel : AUTH_LEVEL.ADMIN,
    permission     : USER_PERMISSION.CONTENT,
    component      : lazy(() => import("src/pages/Admin/Content/General")),
  },
  {
    exact          : true,
    path           : ROUTE_PATH.ADMIN_FUNDRAISING_INVESTMENTS,
    componentLevel : AUTH_LEVEL.ADMIN,
    permission     : USER_PERMISSION.INVESTMENTS,
    component      : lazy(() => import("src/pages/Admin/Investments")),
  },
  {
    exact          : true,
    path           : ROUTE_PATH.ADMIN_FUNDRAISING_COMPLIANCE,
    componentLevel : AUTH_LEVEL.ADMIN,
    permission     : USER_PERMISSION.OFFERING,
    component      : lazy(() => import("src/pages/Admin/Offering/Compliance")),
  },
  {
    exact          : true,
    path           : ROUTE_PATH.ADMIN_FUNDRAISING_RISK_FACTORS,
    componentLevel : AUTH_LEVEL.ADMIN,
    permission     : USER_PERMISSION.OFFERING,
    component      : lazy(() => import("src/pages/Admin/Offering/RiskFactors")),
  },
  {
    exact          : true,
    path           : ROUTE_PATH.ADMIN_CONTENT_CAFE_OFFERING,
    componentLevel : AUTH_LEVEL.ADMIN,
    permission     : USER_PERMISSION.OFFERING,
    component      : lazy(() => import("src/pages/Admin/CafeOfferingContent")),
  },
  {
    exact          : true,
    path           : ROUTE_PATH.ADMIN_FUNDRAISING_FUNDING_OPTIONS,
    componentLevel : AUTH_LEVEL.ADMIN,
    permission     : USER_PERMISSION.ADMIN,
    component      : lazy(() => import("src/pages/Admin/Settings/FundingOptions")),
  },
  {
    exact          : true,
    path           : ROUTE_PATH.ADMIN_ALLOCATIONS,
    componentLevel : AUTH_LEVEL.ADMIN,
    permission     : USER_PERMISSION.OFFERING,
    component      : lazy(() => import("src/pages/Admin/Offering/Allocations")),
  },
  {
    exact          : true,
    path           : ROUTE_PATH.ADMIN_ALLOCATIONS_1,
    componentLevel : AUTH_LEVEL.ADMIN,
    permission     : USER_PERMISSION.OFFERING,
    component      : lazy(() => import("src/pages/Admin/Offering/FirstAllocations")),
  },
  {
    exact          : true,
    path           : ROUTE_PATH.ADMIN_CUSTOMERS,
    componentLevel : AUTH_LEVEL.ADMIN,
    permission     : USER_PERMISSION.ADMIN,
    component      : lazy(() => import("src/pages/Admin/Customers")),
  },
  {
    exact          : true,
    path           : ROUTE_PATH.ADMIN_FAIRTABLE,
    componentLevel : AUTH_LEVEL.ADMIN,
    permission     : USER_PERMISSION.ADMIN,
    component      : lazy(() => import("src/pages/Admin/Fairtable")),
  },
  {
    exact          : true,
    path           : ROUTE_PATH.ADMIN_TRANSFER_MONEY,
    componentLevel : AUTH_LEVEL.ADMIN,
    permission     : USER_PERMISSION.BENEFICIARY,
    component      : lazy(() => import("src/pages/Admin/Recipients")),
  },
  {
    exact          : true,
    path           : ROUTE_PATH.ADMIN_ANALYTICS,
    componentLevel : AUTH_LEVEL.ADMIN,
    permission     : USER_PERMISSION.ADMIN,
    component      : lazy(() => import("src/pages/Admin/Analytics")),
  },
  {
    exact          : true,
    path           : ROUTE_PATH.ADMIN_EQUITY_GRANT_COMPENSATION,
    componentLevel : AUTH_LEVEL.ADMIN,
    permission     : USER_PERMISSION.STAKEHOLDERS_COMPENSATION,
    component      : lazy(() => import("src/pages/Admin/StakeholdersCompensation")),
  },
  {
    exact          : true,
    path           : ROUTE_PATH.ADMIN_EQUITY_GRANT_POOLS,
    componentLevel : AUTH_LEVEL.ADMIN,
    permission     : USER_PERMISSION.STAKEHOLDERS_COMPENSATION,
    component      : lazy(() => import("src/pages/Admin/GrantsPools")),
  },
  {
    exact          : true,
    path           : ROUTE_PATH.ADMIN_NOTIFICATION,
    componentLevel : AUTH_LEVEL.ADMIN,
    permission     : USER_PERMISSION.TRANSACTION,
    component      : lazy(() => import("src/pages/Admin/Notifications")),
  },
  {
    exact          : true,
    path           : ROUTE_PATH.ADMIN_SETTINGS_GENERAL,
    componentLevel : AUTH_LEVEL.ADMIN,
    permission     : USER_PERMISSION.ADMIN,
    component      : lazy(() => import("src/pages/Admin/Settings/General")),
  },
  {
    exact          : true,
    path           : ROUTE_PATH.ADMIN_SETTINGS_PORTAL_ACCESS,
    componentLevel : AUTH_LEVEL.ADMIN,
    permission     : USER_PERMISSION.ADMIN,
    component      : lazy(() => import("src/pages/Admin/Settings/PortalAccess")),
  },
  {
    exact          : true,
    path           : ROUTE_PATH.ADMIN_SETTINGS_WIDGET,
    componentLevel : AUTH_LEVEL.ADMIN,
    permission     : USER_PERMISSION.ADMIN,
    component      : lazy(() => import("src/pages/Admin/Widget")),
  },
  {
    exact          : true,
    path           : ROUTE_PATH.ADMIN_SETTINGS_INTEGRATIONS,
    componentLevel : AUTH_LEVEL.ADMIN,
    permission     : USER_PERMISSION.ADMIN,
    component      : lazy(() => import("src/pages/Admin/Settings/Integrations")),
  },
  {
    exact          : true,
    path           : ROUTE_PATH.ADMIN_COMMUNITY,
    componentLevel : AUTH_LEVEL.ADMIN,
    permission     : USER_PERMISSION.READ_WALLET,
    component      : lazy(() => import("src/pages/Admin/Stakeholders")),
  },
  {
    exact          : true,
    path           : ROUTE_PATH.ADMIN_COMMUNITY_EQUITY,
    componentLevel : AUTH_LEVEL.ADMIN,
    permission     : USER_PERMISSION.ADMIN,
    component      : lazy(() => import("src/pages/Admin/GrantEquity")),
  },
  {
    exact          : true,
    path           : ROUTE_PATH.ADMIN_EQUITY_GRANT_LEGAL,
    componentLevel : AUTH_LEVEL.ADMIN,
    permission     : USER_PERMISSION.ADMIN,
    component      : lazy(() => import("src/pages/Admin/GrantsLegal")),
  },
  {
    exact          : true,
    path           : ROUTE_PATH.ADMIN_CRAFT_COMPENSATION_PLAN_DOC,
    componentLevel : AUTH_LEVEL.ADMIN,
    permission     : USER_PERMISSION.ADMIN,
    component      : lazy(() => import("src/pages/Admin/CraftCompensation/CraftCompPlanDoc")),
  },
  {
    exact          : true,
    path           : ROUTE_PATH.ADMIN_CRAFT_ECA_DOC,
    componentLevel : AUTH_LEVEL.ADMIN,
    permission     : USER_PERMISSION.ADMIN,
    component      : lazy(() => import("src/pages/Admin/CraftCompensation/CraftECADoc")),
  },
  {
    exact          : true,
    path           : ROUTE_PATH.ADMIN_CRAFT_SPC,
    componentLevel : AUTH_LEVEL.ADMIN,
    permission     : USER_PERMISSION.ADMIN,
    component      : lazy(() => import("src/pages/Admin/CraftCompensation/CraftSPC")),
  },
  {
    exact          : true,
    hasFooter      : false,
    path           : ROUTE_PATH.TOKEN_TERMS,
    componentLevel : AUTH_LEVEL.DASHBOARD,
    component      : lazy(() => import("src/pages/ExternalLink")),
  },
  {
    exact          : true,
    hasFooter      : false,
    path           : ROUTE_PATH.COMPENSATION_PLAN,
    componentLevel : AUTH_LEVEL.DASHBOARD,
    component      : lazy(() => import("src/pages/ExternalLink")),
  },
  {
    exact          : true,
    hasFooter      : false,
    path           : ROUTE_PATH.ECA,
    componentLevel : AUTH_LEVEL.DASHBOARD,
    component      : lazy(() => import("src/pages/ExternalLink")),
  },
  {
    exact          : true,
    path           : ROUTE_PATH.RISKS,
    componentLevel : AUTH_LEVEL.FREE,
    component      : () => <LegalPage type="risks" />,
  },
  {
    exact          : true,
    path           : ROUTE_PATH.COOKIE_POLICY,
    componentLevel : AUTH_LEVEL.FREE,
    component      : () => <LegalPage type="cookie-policy" />,
  },
  {
    exact          : true,
    path           : ROUTE_PATH.DISCLAIMER,
    componentLevel : AUTH_LEVEL.FREE,
    component      : () => <LegalPage type="disclosures" />,
  },
  {
    exact          : true,
    path           : ROUTE_PATH.PRIVACY_POLICY,
    componentLevel : AUTH_LEVEL.FREE,
    component      : () => <LegalPage type="privacy-policy" />,
  },
  {
    exact          : true,
    path           : ROUTE_PATH.TERMS_OF_SERVICE,
    componentLevel : AUTH_LEVEL.FREE,
    component      : () => <LegalPage type="terms-of-service" />,
  },
];

const routeViews = config.REACT_APP_STAGE !== APP_STAGE.PRODUCTION
  ? allRoutes
  : allRoutes.filter(route => !route.devOnly);

const Routes = ({ orgConfig, user: { userItem, walletItem } }) => {
  const isRegS = useMemo(() => isRegSInvestor(userItem, walletItem), [userItem, walletItem]);

  return (
    <main>
      <Layout routeViews={routeViews}>
        <Switch>
          {routeViews.map(route => (
            <Route
              key={route.path}
              path={(route.componentLevel === AUTH_LEVEL.DASHBOARD && isRegS) ? `/international${route.path}` : route.path}
              exact={route.exact}
              componentLevel={route.componentLevel}
              render={props => (
                <Navigator
                  componentLevel={route.componentLevel}
                  permission={route.permission}
                  isSetupMode={orgConfig.communityOffering && isSetupMode(orgConfig.status)}
                >
                  <route.component {...props} />
                </Navigator>
              )}
            />
          ))}
          {isRegS
            ? routeViews
              .filter(route => route.componentLevel === AUTH_LEVEL.DASHBOARD)
              .map(route => <Redirect key={route.path} path={route.path} to={`/international${route.path}`} />)
            : routeViews
              .filter(route => route.componentLevel === AUTH_LEVEL.DASHBOARD)
              .map(route => <Redirect key={route.path} path={`/international${route.path}`} to={route.path} />)}
          <Redirect path="/admin-stakeholders" to={ROUTE_PATH.ADMIN_COMMUNITY} />
          <Redirect path="/admin-stakeholders-compensation" to={ROUTE_PATH.ADMIN_EQUITY_GRANT_COMPENSATION} />
          <Redirect path="/admin-compensation" to={ROUTE_PATH.ADMIN_EQUITY_GRANT_COMPENSATION} />
          <Redirect path="/admin-content-financial-data" to={ROUTE_PATH.ADMIN_CONTENT_OFFERING} />
          <Redirect path="/admin-beneficiaries" to={ROUTE_PATH.ADMIN_TRANSFER_MONEY} />
          <Redirect path="/admin-transactions" to={ROUTE_PATH.ADMIN_NOTIFICATION} />
          <Redirect path="/admin-content-emails" to={ROUTE_PATH.ADMIN_SETTINGS_GENERAL} />
          <Redirect path="/admin-widget" to={ROUTE_PATH.ADMIN_SETTINGS_WIDGET} />
          <Redirect path="/admin-offering-allocations" to={ROUTE_PATH.ADMIN_ALLOCATIONS} />
          <Redirect path="/admin-offering-allocations-1" to={ROUTE_PATH.ADMIN_ALLOCATIONS_1} />
          <Redirect path="/admin-investments" to={ROUTE_PATH.ADMIN_FUNDRAISING_INVESTMENTS} />
          <Redirect path="/admin-offering-compliance" to={ROUTE_PATH.ADMIN_FUNDRAISING_COMPLIANCE} />
          <Redirect path="/admin-offering-risk-factors" to={ROUTE_PATH.ADMIN_FUNDRAISING_RISK_FACTORS} />
          <Redirect path="/admin-settings-funding-options" to={ROUTE_PATH.ADMIN_FUNDRAISING_FUNDING_OPTIONS} />
          <Redirect path="/admin-completion" to={ROUTE_PATH.ADMIN_HOME} />
          <Redirect path={ROUTE_PATH.ADMIN_CONTENT_PRESENTATION} to={ROUTE_PATH.ADMIN_CONTENT_WHYPAGE} />
          <Redirect path="*" to={ROUTE_PATH.SIGN_IN} />
        </Switch>
      </Layout>
    </main>
  );
};

const mapStateToProps = store => ({
  orgConfig : store.global.orgConfig,
  user      : store.user,
});

Routes.propTypes = {
  orgConfig : PropTypes.object.isRequired,
  user      : PropTypes.object,
};

export default connect(mapStateToProps)(Routes);
