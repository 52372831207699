import { TX_STATUS, TX_TYPE } from "src/constants";

// Is mined
export const isMined = txStatus => [
  TX_STATUS.CONFIRMED,
  TX_STATUS.FAILED,
].includes(txStatus);

// Is confirmed (obviously mined)
export const isConfirmed = txStatus => [
  TX_STATUS.CONFIRMED,
].includes(txStatus);

// Is failed (this is the end of tx - possibly not mined)
export const isFailed = txStatus => [
  TX_STATUS.SPEED_UP,
  TX_STATUS.CANCEL,
  TX_STATUS.FAILED,
  TX_STATUS.DROPPED,
].includes(txStatus);

// Is still pending
export const isPending = txStatus => !(
  isConfirmed(txStatus) || isFailed(txStatus)
);

// Is stuck
export const isStuck = txStatus => [
  TX_STATUS.STUCK,
].includes(txStatus);

// User transctions
export const filterUserTxs = txs => (txs || []).filter(
  tx => [
    TX_TYPE.BUY,
    TX_TYPE.FUND,
    TX_TYPE.WITHDRAW,
    TX_TYPE.WITHDRAW_FEE,
    TX_TYPE.USER_TRANSFER_TOKEN,
    TX_TYPE.INCOMING_TRANSFER_TOKEN,
  ].includes(tx.type)
);

export const getTxColor = (item) => {
  const status = item?.status;

  if (status === TX_STATUS.QUEUED) return "orange";
  if (isConfirmed(status)) return "green";
  if (isFailed(status)) return "red";

  // Pending
  return "#939300";
};
