import { put, call, takeLatest, all } from "redux-saga/effects";
import { NotificationManager } from "react-notifications";

import { requestCreator, successCreator, failureCreator } from "../utils/action";
import { Types } from "../actions/admin-risk-factor";
import API from "../api/admin-risk-factor";

function* sagaAction(action) {
  const type = action.type;
  try {
    yield put(requestCreator(type, {}));
    const payload = yield call(API[type], action);
    yield put(successCreator(type, payload));

    if (type === Types.ADD_CATEGORY) {
      NotificationManager.success("The category was added");
    }
    else if (type === Types.UPDATE_CATEGORY) {
      NotificationManager.success("The changes were saved");
    }
    else if (type === Types.DELETE_CATEGORY) {
      NotificationManager.success("The category was deleted");
    }
    else if (type === Types.UPDATE_HEADER) {
      NotificationManager.success("The changes were saved");
    }
  }
  catch (err) {
    console.error(err);
    yield put(failureCreator(type, { err }));
  }
}

export function* adminRiskFactorSaga() {
  yield all([
    takeLatest(Types.GET_RISK_FACTORS, sagaAction),
    takeLatest(Types.ADD_CATEGORY, sagaAction),
    takeLatest(Types.UPDATE_CATEGORY, sagaAction),
    takeLatest(Types.DELETE_CATEGORY, sagaAction),
    takeLatest(Types.UPDATE_HEADER, sagaAction),
  ]);
}
