import { createReducer } from "reduxsauce";
import { Types } from "../actions/accreditation-link";
import { success } from "../utils/action";

// Initial State
const initialState = {
  user: null
};

const getUserByLinkId = (state, action) => ({
  ...state,
  user: action.payload.user
});

// map action types to reducer functions
export const handlers = {
  [success(Types.GET_USER_BY_LINK_ID)]: getUserByLinkId,
};

// Export Reducer
export default createReducer(initialState, handlers);
