import { put, call, select, takeLatest, all } from "redux-saga/effects";
import { NotificationManager } from "react-notifications";

import { validateEmail } from "src/utils/validation";
import { SEARCH_BY_OPTION } from "src/pages/Admin/Customers/SearchSidePanel/constant";
import { requestCreator, successCreator, failureCreator } from "../utils/action";
import { Types } from "../actions/admin-customer";
import { Creators as globalCreators } from "../actions/global";
import { Creators as adminCreators } from "../actions/admin";
import API from "../api/admin-customer";

function* sagaAction(action) {
  const type = action.type;
  try {
    yield put(requestCreator(type, {}));
    const payload = yield call(API[type], action);
    yield put(successCreator(type, payload));
  }
  catch (err) {
    console.error(err);
    yield put(failureCreator(type, { err }));
  }
}

function* searchWallets(action) {
  const { type, payload } = action;

  try {
    yield put(requestCreator(type, payload.isFetchMore));

    const { searchedWallets: { wallets, lastItemKey } } = yield select(state => state.adminCustomer);

    const search = { pageLimit: payload.pageLimit || 100, customerOrgId: payload.customerOrgId };
    if (payload.isFetchMore) {
      search.ExclusiveStartKey = lastItemKey;
    }

    if (payload.searchKeyword) {
      if (validateEmail(payload.searchKeyword)) {
        search[SEARCH_BY_OPTION.EMAIL] = payload.searchKeyword;
      }
      else if (payload.searchKeyword.startsWith("0x")) {
        search[SEARCH_BY_OPTION.WALLET] = payload.searchKeyword;
      }
      else {
        search[SEARCH_BY_OPTION.NAME] = payload.searchKeyword;
      }
    }

    const { Items, LastEvaluatedKey } = yield call(API[type], search);

    const newWallets = {
      wallets     : payload.isFetchMore ? [...wallets, ...Items] : Items,
      lastItemKey : LastEvaluatedKey
    };

    yield put(successCreator(type, newWallets));
  }
  catch (err) {
    console.error(err);
    yield put(failureCreator(type, { err }));
  }
}

function* approvePortalLiveAction(action) {
  const type = action.type;

  try {
    yield put(requestCreator(type, {}));
    const payload = yield call(API[type], action);
    yield put(successCreator(type, payload));
    yield put(adminCreators.getNavLeftStatus());

    NotificationManager.success(`The portal is now live!`);
  }
  catch (err) {
    console.error(err);
    yield put(failureCreator(type, { err }));
  }

  yield put(globalCreators.setCurrentDialog(null));
}

function* rejectPortalAction(action) {
  const type = action.type;

  try {
    yield put(requestCreator(type, {}));
    const payload = yield call(API[type], action);
    yield put(successCreator(type, payload));

    NotificationManager.success(`The portal was rejected`);
  }
  catch (err) {
    console.error(err);
    yield put(failureCreator(type, { err }));
  }
}

function* deletePortalAction(action) {
  const type = action.type;

  try {
    yield put(requestCreator(type, {}));
    const payload = yield call(API[type], action);
    yield put(successCreator(type, payload));

    NotificationManager.success(`The portal was permanently deleted`);
  }
  catch (err) {
    console.error(err);
    yield put(failureCreator(type, { err }));
  }
}

export function* adminCustomerSaga() {
  yield all([
    takeLatest(Types.GET_CUSTOMERS,       sagaAction),
    takeLatest(Types.GET_EVENTS,          sagaAction),
    takeLatest(Types.GET_ALLOCATIONS,     sagaAction),
    takeLatest(Types.APPROVE_PORTAL_LIVE, approvePortalLiveAction),
    takeLatest(Types.REJECT_PORTAL,       rejectPortalAction),
    takeLatest(Types.DELETE_PORTAL,       deletePortalAction),
    takeLatest(Types.SEARCH_WALLETS,      searchWallets),
  ]);
}
