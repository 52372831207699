import { createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  getBerbixStatus        : [],
  verificationFinished   : [],
  setWelcomed            : ["payload"],
  updateUser             : ["payload"],
  updateWallet           : ["payload"],
  generate2FASecret      : [],
  getUserAndWallet       : ["code"],
  refreshUserAndWallet   : [],
  getMessengerToken      : [],
  getTransactions        : [],
  updateTransactions     : ["payload"],
  initializeCorg         : ["walletAddress"],
  refreshOrgInfo         : [],
  refreshAccountInfo     : ["walletAddress"],
  setTransactionRead     : ["txId"],
  setInvestItemRead      : ["investItemSK", "msgData"],
  sendConfirmationCode   : ["payload"],
  signAndBuy             : ["amount", "tokenEstimate", "embedded", "priceProtection"],
  startBankTransfer      : ["method"],
  cancelInvestment       : [],
  removePriceProtection  : [],
  buyTokens              : ["investItem", "amount"],
  sellTokens             : ["amount", "tokenEstimate"],
  withdrawCurrency       : ["toAddress", "amount", "pinCode"],
  userTransferToken      : ["payload"],
  activate2FA            : ["pinCode", "two_factor_secret"],
  deactivate2FA          : ["pinCode"],
  getOwnLegalItems       : [],
  getOwnCompDocUrl       : ["item"],
  requestInvestmentPDF   : ["investItemSK"],
  getCompensations       : [],
  setCompensationRead    : ["id"],
  setEnrollmentRead      : ["id"],
  setInvitedEnrollAction : ["id", "action"],
  setCompensationSigned  : ["id", "hellosign"],
  setEnrollmentSigned    : ["id", "hellosign"],
  getOwnInvestmentDocUrl : ["investItemSK", "fileName"],
  updateInvestItemStatus : [],
  getBankInfo            : [],
  getCurrentAllocation   : ["hideLoading"],
  getOrgBeneficiary      : [],
  updateCompanyInfo      : ["payload"],
  sendKYCEmailToBO       : ["email"],
  checkGeoFence          : [],
  checkIPCountry         : [],
}, { prefix: "user_" });

export { Types, Creators };
