import { populateOrgConfig, populatePageContent } from "src/utility";
import { callLambda, SERVICE_ADMIN } from "src/utils/lambda";
import { Types } from "../actions/admin-offering";

const apis = {};

apis[Types.GET_OFFERING_HTML] = ({ payload }) => callLambda({
  method  : "post",
  service : SERVICE_ADMIN,
  url     : "getSendgridHTML",
  body    : { type: "offering", value: payload.type }
});

apis[Types.GENERATE_OFFERING_PDF] = async (action) => {
  const { orgConfig, adminContent } = await callLambda({
    method  : "post",
    service : SERVICE_ADMIN,
    url     : "generateOfferingPDF",
    body    : action.payload
  });
  return {
    orgConfig    : await populateOrgConfig(orgConfig),
    adminContent : await populatePageContent(adminContent)
  };
};

export default apis;
