import { createReducer } from "reduxsauce";
import { Types } from "../actions/auth";
import { Types as userTypes } from "../actions/user";
import { success, failure } from "../utils/action";

// Initial State
const initialState = {
  user                    : null,
  isInvitationCodeChecked : false,
};

/* Handlers */
const signOut = () => ({
  user                    : null,
  isInvitationCodeChecked : false,
  two_factor_address      : "null"
});

const checkToken = (state, action) => ({
  ...state,
  user: action.payload
});

const getUserAndWallet = (state, action) => ({
  ...state,
  two_factor_enabled : action.payload.walletItem.two_factor_enabled,
  permissions        : action.payload.walletItem.permissions,
  welcomed           : action.payload.walletItem.welcomed,
});

const deactivate2FA = (state) => {
  window.localStorage.removeItem("two_factor_token");
  return {
    ...state,
    two_factor_enabled: false
  };
};

const check2FAToken = (state, action) => ({
  ...state,
  two_factor_address   : action.payload.walletAddress,
  two_factor_permanent : action.payload.permanent
});

const check2FATokenFailure = (state) => {
  window.localStorage.removeItem("two_factor_token");
  return {
    ...state,
    two_factor_address: "null"
  };
};

const check2FACode = (state, action) => {
  window.localStorage.setItem("two_factor_token", action.payload.two_factor_token);
  return {
    ...state,
    two_factor_address   : action.payload.walletAddress,
    two_factor_permanent : action.payload.permanent
  };
};

const activate2FA = (state, action) => {
  window.localStorage.setItem("two_factor_token", action.payload.two_factor_token);
  return {
    ...state,
    two_factor_enabled   : true,
    two_factor_address   : action.payload.walletAddress,
    two_factor_permanent : false
  };
};

const checkEmail = (state, action) => {
  if (!action.payload.exists) {
    return { ...state, check_email: Math.random() };
  }
  return state;
};

const checkInvitationCode = (state, action) => ({
  ...state,
  isInvitationCodeChecked: action.payload.confirmed,
});

// map action types to reducer functions
export const handlers = {
  [success(Types.CHECK_EMAIL)]             : checkEmail,
  [success(Types.CHECK_TOKEN)]             : checkToken,
  [success(userTypes.GET_USER_AND_WALLET)] : getUserAndWallet,
  [success(Types.CHECK2_FA_TOKEN)]         : check2FAToken,
  [failure(Types.CHECK2_FA_TOKEN)]         : check2FATokenFailure,
  [success(Types.CHECK2_FA_CODE)]          : check2FACode,
  [failure(Types.CHECK2_FA_CODE)]          : check2FATokenFailure,
  [success(userTypes.DEACTIVATE2_FA)]      : deactivate2FA,
  [success(userTypes.ACTIVATE2_FA)]        : activate2FA,
  [Types.SIGN_OUT]                         : signOut,
  [success(Types.CHECK_INVITATION_CODE)]   : checkInvitationCode,
};

// Export Reducer
export default createReducer(initialState, handlers);
