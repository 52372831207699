import { createReducer } from "reduxsauce";
import { Types } from "../actions/admin-content";
import { Types as adminOfferingTypes } from "../actions/admin-offering";
import { Types as adminPaymentTypes } from "../actions/admin-payment";
import { success } from "../utils/action";

// Initial State
const initialState = {
  emailPreviewData    : null,
  invitationCodes     : null,
  slackWebhook        : null,
  pages               : null,
  emails              : null,
  bankInfo            : null,
  companyInfo         : null,
  dnsStatus           : null,
  riskFactors         : null,
  activatedLiveDomain : false,
};

const getEmailPreviewData = (state, action) => ({
  ...state,
  emailPreviewData: action.payload.data
});

const getAdminContents = (state, { payload }) => ({
  ...state,
  ...payload.adminContent,
});

const getActivatedLiveDomain = (state, action) => ({
  ...state,
  activatedLiveDomain: action.payload
});

const getDNSStatus = (state, action) => ({
  ...state,
  dnsStatus: action.payload
});

const getOrgRiskFactors = (state, action) => ({
  ...state,
  riskFactors: action.payload
});

// map action types to reducer functions
export const handlers = {
  [success(Types.GET_EMAIL_PREVIEW_DATA)]             : getEmailPreviewData,
  [success(Types.UPDATE_PAGE_CONTENTS)]               : getAdminContents,
  [success(Types.UPDATE_ORG_INFO)]                    : getAdminContents,
  [success(Types.UPDATE_JURISDICTION_INFO)]           : getAdminContents,
  [success(Types.GET_ADMIN_CONTENTS)]                 : getAdminContents,
  [success(Types.ACTIVATE_LIVE_DOMAIN)]               : getActivatedLiveDomain,
  [success(Types.UPDATE_LIVE_DOMAIN)]                 : getAdminContents,
  [success(Types.GET_DNS_STATUS)]                     : getDNSStatus,
  [success(Types.GET_ORG_RISK_FACTORS)]               : getOrgRiskFactors,
  [success(Types.UPDATE_ORG_ONBOARDING)]              : getAdminContents,
  [success(adminOfferingTypes.GENERATE_OFFERING_PDF)] : getAdminContents,
  [success(adminPaymentTypes.PROCESS_PAYMENT)]        : getAdminContents,
};

// Export Reducer
export default createReducer(initialState, handlers);
