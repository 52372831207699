import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Button, styled } from "@mui/material";

const ThemeButton = styled(Button)(
  ({ "data-primary": primaryColor }) => `
  &.MuiButton-root {
    text-transform: none;
  }

  &.MuiButton-containedPrimary {
    background-color: ${primaryColor};
    &.Mui-disabled {
      background-color: rgba(0, 0, 0, 0.12);
    }
  }

  &.MuiButton-textPrimary {
    color: ${primaryColor};
    background-color: #F7F7F7;
    border-color: #F7F7F7;    
    &.Mui-disabled {
      filter: grayscale(70%);
      opacity: 0.5
    }
  }

  &.MuiButton-outlinedPrimary {
    color: ${primaryColor};
    border-color: ${primaryColor};
    &.Mui-disabled {
      color: rgba(0, 0, 0, 0.44);
      border: 1px solid rgba(0, 0, 0, 0.32);
    }
  }
`
);

const MuiButton = ({ dispatch, orgConfig, variant = "contained", children, ...props }) => (
  <ThemeButton data-primary={orgConfig.theme.primary} variant={variant} {...props}>
    {children}
  </ThemeButton>
);

const mapStateToProps = store => ({
  orgConfig: store.global.orgConfig,
});

MuiButton.propTypes = {
  dispatch  : PropTypes.func,
  orgConfig : PropTypes.object,
  sx        : PropTypes.object,
  variant   : PropTypes.string,
  children  : PropTypes.node,
  color     : PropTypes.string,
};

export default connect(mapStateToProps)(MuiButton);
