import React from "react";

import Disclaimer from "../Disclaimer";

const Footer = () => (
  <>
    <Disclaimer />
  </>
);

export default Footer;
