import { put, call, takeLatest, all } from "redux-saga/effects";
import { DIALOG_NAME } from "src/constants";
import { requestCreator, successCreator, failureCreator } from "../utils/action";
import { Creators as globalCreators } from "../actions/global";
import { Types } from "../actions/admin-equity";
import API from "../api/admin-equity";

function* sagaAction(action) {
  const type = action.type;
  try {
    yield put(requestCreator(type, {}));
    const payload = yield call(API[type], action);
    yield put(successCreator(type, payload));
  }
  catch (err) {
    console.error(err);
    yield put(failureCreator(type, { err }));
  }
}

function* grantPool(action) {
  const type = action.type;
  try {
    yield put(requestCreator(type, {}));
    const payload = yield call(API[type], action);
    yield put(successCreator(type, payload));

    yield put(globalCreators.setCurrentDialog(DIALOG_NAME.GrantEquitySuccess, payload.email));
  }
  catch (err) {
    console.error(err);
    yield put(failureCreator(type, { err }));
  }
}

export function* adminEquitySaga() {
  yield all([
    takeLatest(Types.SEARCH_WALLET,          sagaAction),
    takeLatest(Types.GET_USER_ENROLLMENTS,    sagaAction),
    takeLatest(Types.LIST_POOLS,              sagaAction),
    takeLatest(Types.GET_ORG_CONTRACTS,       sagaAction),
    takeLatest(Types.GRANT_POOL,              grantPool),
  ]);
}
