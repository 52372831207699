import { createMessages } from "../../lib/createMessages";

const prefix = "components.UserTopBanner";

export const messages = createMessages(prefix, {
  bannerText: {
    defaultMessage : "You are viewing this page as an investor",
    description    : "Info text - index.js",
  },
  goToAdminView: {
    defaultMessage : "Go to admin view",
    description    : "Info text - index.js",
  }
});
